<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="d-flex justify-content-between mb-3">
          <h3>Career Matches for {{ startup ? startup.name : 'Startup' }}</h3>
          <div>
            <button class="btn btn-primary me-2" @click="downloadCSV">
              <i class="uil uil-download-alt me-1"></i>Download
            </button>
            <button class="btn btn-secondary" @click="goBack">
              <i class="uil uil-arrow-left me-1"></i>Back
            </button>
          </div>
        </div>

        <div v-if="loading" class="text-center py-4">
          <loader></loader>
        </div>
        
        <div v-else-if="career_matches.length === 0" class="alert alert-info">
          No career matches found for this startup.
        </div>
        
        <div v-else>
          <div class="table-responsive">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th>Contact</th>
                  <th>Email</th>
                  <th>Company</th>
                  <th>Company Website</th>
                  <th>Same Year Match</th>
                  <th>Career One-liner</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="match in paginatedMatches" :key="match.id">
                  <td>{{ match.contact.first_name }} {{ match.contact.last_name }}</td>
                  <td>{{ match.contact.email }}</td>
                  <td>{{ match.contact.company_name }}</td>
                  <td>
                    <a v-if="match.contact.company_website" :href="match.contact.company_website" target="_blank">
                      {{ match.contact.company_website }}
                    </a>
                    <span v-else>-</span>
                  </td>
                  <td>
                    <span v-if="match.is_same_year_match" class="badge bg-success">Yes</span>
                    <span v-else class="badge bg-secondary">No</span>
                  </td>
                  <td>
                    <div class="email-content">{{ match.content }}</div>
                  </td>
                  <td>
                    <a :href="`/contacts/${match.contact.id}`" class="btn btn-sm btn-outline-primary me-1">
                      <i class="uil uil-user"></i> View Contact
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          
          <!-- Pagination -->
          <div class="d-flex justify-content-between align-items-center mt-3">
            <div>
              Showing {{ paginatedMatches.length }} of {{ career_matches.length }} matches
            </div>
            <div class="d-flex align-items-center">
              <div class="me-3">
                <label class="me-2">Items per page:</label>
                <select class="form-select form-select-sm" v-model="itemsPerPage" @change="handlePageSizeChange">
                  <option v-for="size in pageSizeOptions" :key="size" :value="size">{{ size }}</option>
                </select>
              </div>
              <nav aria-label="Page navigation">
                <ul class="pagination">
                  <li class="page-item" :class="{ 'disabled': currentPage === 1 }">
                    <a class="page-link" href="#" @click.prevent="currentPage--" aria-label="Previous">
                      <span aria-hidden="true">&laquo;</span>
                    </a>
                  </li>
                  <li v-for="page in totalPages" :key="page" class="page-item" :class="{ 'active': currentPage === page }">
                    <a class="page-link" href="#" @click.prevent="currentPage = page">{{ page }}</a>
                  </li>
                  <li class="page-item" :class="{ 'disabled': currentPage === totalPages }">
                    <a class="page-link" href="#" @click.prevent="currentPage++" aria-label="Next">
                      <span aria-hidden="true">&raquo;</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'CareerMatches',
  
  data() {
    return {
      startup: null,
      career_matches: [],
      loading: true,
      currentPage: 1,
      itemsPerPage: 100,
      pageSizeOptions: [100, 500, 1000]
    }
  },
  
  computed: {
    ...mapState({
      user: state => state.auth.user
    }),
    
    paginatedMatches() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.career_matches.slice(start, end);
    },
    
    totalPages() {
      return Math.ceil(this.career_matches.length / this.itemsPerPage);
    }
  },
  
  mounted() {
    const startupId = this.$route.params.id;
    if (startupId) {
      this.fetchStartup(startupId);
      this.fetchCareerMatches(startupId);
    } else {
      this.$router.push({ name: 'startups_selector' });
    }
  },
  
  methods: {
    fetchStartup(startupId) {
      this.$store.dispatch('LOAD_STARTUP', startupId)
        .then(response => {
          this.startup = response.data;
        })
        .catch(error => {
          console.error('Error loading startup:', error);
          this.$toast.error('Failed to load startup information');
        });
    },
    
    fetchCareerMatches(startupId) {
      this.loading = true;
      this.$store.dispatch('LOAD_CAREER_MATCHES', startupId)
        .then(response => {
          this.career_matches = response.data;
          this.loading = false;
        })
        .catch(error => {
          console.error('Error loading career matches:', error);
          this.$toast.error('Failed to load career matches');
          this.loading = false;
        });
    },
    
    goBack() {
      this.$router.go(-1);
    },
    
    handlePageSizeChange() {
      // Reset to first page when changing page size to avoid being on a non-existent page
      this.currentPage = 1;
    },
    
    downloadCSV() {
      if (!this.career_matches.length) {
        this.$toast.info('No career matches to download');
        return;
      }
      
      // Create CSV content
      const headers = [
        'startup_name',
        'first_name',
        'last_name',
        'email',
        'contact_id',
        'career_oneliner',
        'company_name',
        'company_website',
        'same_year_match'
      ].join(',');
      
      const rows = this.career_matches.map(match => {
        return [
          this.escapeCSV(match.startup_name || (this.startup ? this.startup.name : '')),
          this.escapeCSV(match.contact.first_name || ''),
          this.escapeCSV(match.contact.last_name || ''),
          this.escapeCSV(match.contact.email || ''),
          match.contact.id || '',
          this.escapeCSV(match.content || ''),
          this.escapeCSV(match.contact.company_name || ''),
          this.escapeCSV(match.contact.company_website || ''),
          match.is_same_year_match ? 'Yes' : 'No'
        ].join(',');
      });
      
      const csvContent = [headers, ...rows].join('\n');
      
      // Create download link
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      
      link.setAttribute('href', url);
      link.setAttribute('download', `career_matches_${this.startup ? this.startup.name.replace(/\s+/g, '_') : 'startup'}_${new Date().toISOString().split('T')[0]}.csv`);
      link.style.visibility = 'hidden';
      
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    
    escapeCSV(value) {
      if (!value) return '';
      // Escape quotes and wrap in quotes if needed
      const needsQuotes = value.includes(',') || value.includes('"') || value.includes('\n');
      const escaped = value.replace(/"/g, '""');
      return needsQuotes ? `"${escaped}"` : escaped;
    }
  }
}
</script>

<style scoped>
.email-content {
  max-height: 100px;
  overflow-y: auto;
  white-space: pre-wrap;
  max-width: 300px;
}

.table-responsive {
  max-height: calc(100vh - 250px);
  overflow-y: auto;
}
</style> 