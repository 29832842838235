<template>
  <div class="container mt-4">
    <div class="card">
      <div class="card-header d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <router-link to="/pitch-deck/reports" class="back-link me-2">
            <i class="uil uil-angle-left"></i>
          </router-link>
          <h2 class="header-title">Report Summary for {{ pitchDeck?.name }}</h2>
        </div>
        <button class="btn btn-primary" @click="printReport">
          <i class="uil uil-print me-1"></i>Print Summary
        </button>
      </div>
      <div class="card-body pt-0">
        <div v-if="pitchDeck?.report_summary !== null && pitchDeck?.report_summary !== '' && pitchDeck?.report_summary !== undefined" class="analysis-report">
          <div class="alert alert-info my-4">
            <p class="mb-0">
              This is a condensed summary of the full analysis report. For complete details and comprehensive analysis,
              please view the <router-link :to="`/pitch-deck/reports/${$route.params.id}`">full report</router-link>.
            </p>
          </div>
          <div v-html="renderMarkdown(pitchDeck?.report_summary)"></div>
          <div class="alert alert-info my-4">
            <p class="mb-0">
              Based on the report generated by our AI agent, we have identified over 100 investors that are a match for you, given your current funding stage.
              We also identified over 10 investors with whom you have mutual connections and can meet in person. To get access to the investors please contact us at 
              <a href="mailto:info@astelventures.com">info@astelventures.com</a>
            </p>
          </div>
        </div>
        <div v-else>
          <div class="alert alert-info">
            <p class="mb-0">No summary report found for this pitch deck.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import MarkdownIt from 'markdown-it'
import MarkdownTexMath from '@/lib/texmath'
import katex from 'katex'

export default {
  name: 'DealAnalysisSummary',
  computed: {
    ...mapState({
      pitchDeck: state => state.dealAnalyser.deal,
      siteCompany: state => state.auth.siteCompany,
    }),
  },
  mounted() {
    this.fetchReport()
  },
  methods: {
    cleanMarkdown(text) {
      // remove all ```markdown ...``` 
      text = text.replace(/```markdown/g, '')
      text = text.replace(/```/g, '')
      // replace all '\\' with '\'
      text = text.replace(/\\\\/g, '\\')
      // replace all \. with .
      text = text.replace(/\\[.]/g, '.')
      // remove all citations of the form [1] or [2]
      text = text.replace(/\[\d+\]/g, '')
      return text
    },
    renderMarkdown(text) {
      const md = new MarkdownIt({ html: true })
      md.use(MarkdownTexMath, {
        engine: katex,
        delimiters: ['brackets', 'dollars', 'doxygen', 'gitlab', 'julia', 'kramdown'],
      })
      return md.render(this.cleanMarkdown(text))
    },
    fetchReport() {
      this.$store.dispatch('FETCH_DEAL_ANALYSIS', this.$route.params.id)
    },
    printReport() {
      const content = this.pitchDeck?.report_summary || '';
      const printWindow = window.open('', '_blank');
      printWindow.document.write(`
        <html>
          <head>
            <title>${this.pitchDeck?.name} - Pitch Deck Analysis Summary</title>
            <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/github-markdown-css">
            <style>
              @page {
                size: A4;
                margin: 20mm 20mm 40mm 20mm;
              }
              
              body { 
                padding: 20px;
                margin: 0;
              }
              
              .markdown-body { 
                max-width: 800px; 
                margin: 0 auto;
                --bgColor-default: #fff;
                --fgColor-default: #000;
                --bgColor-muted: #eee;
              }

              .footer {
                padding-top: 20px;
                position: running(footer);
                text-align: center;
                font-size: 12px;
                color: #666;
              }
              
              .katex {
                font-size: 1.2em !important;  /* align KaTeX font-size to surrounding text */
                math {
                  display: flex;
                }
              }

              eq { display: inline-block; }
              eqn { display: block; padding: 0.5rem 0; }
              section {
                display: inline-block;
              }
              section.eqno {
                  display: flex;
                  flex-direction: row;
                  align-content: space-between;
                  align-items: center;
              }
              section.eqno > eqn {
                  width: 100%;
                  margin-left: 3em;
              }
              section.eqno > span {
                  width:3em;
                  text-align:right;
              }

              .katex-html {
                display: none;
              }

              @page {
                @bottom-center {
                  content: element(footer);
                }
              }
            </style>
          </head>
          <body>
            <div class="markdown-body">
              <img src="${this.siteCompany?.company_logo || '/static/images/astel_logo.png'}" alt="" height="40">
              <hr>
              <div class="alert alert-info my-4">
                <p class="mb-0">
                  This is a condensed summary of the full analysis report. For complete details and comprehensive analysis,
                  please refer to the full report.
                </p>
              </div>
              <hr>
              ${this.renderMarkdown(content)}
              <hr>
              <div class="alert alert-info my-4">
                <p class="mb-0">
                  Based on the report generated by our AI agent, we have identified over 100 investors that are a match for you, given your current funding stage.
                  We also identified over 10 investors with whom you have mutual connections and can meet in person. To get access to the investors please contact us at 
                  <a href="mailto:info@astelventures.com">info@astelventures.com</a>
                </p>
              </div>
            </div>
            <div class="footer">
              <hr>
              Astel Ventures: Pitch Deck Analysis Summary for ${this.pitchDeck?.name}
            </div>
          </body>
        </html>
      `);
      printWindow.document.close();
      printWindow.onload = () => {
        printWindow.print();
        printWindow.onafterprint = () => {
          printWindow.close();
        };
      };
    }
  },
  watch: {
    '$route.params.id': {
      handler(newId) {
        if (newId) {
          this.fetchReport()
        }
      },
      immediate: true
    },
  }
}
</script>

<style scoped>
.back-link {
  font-size: 1.5rem;
  color: #6c757d;
  text-decoration: none;
}

.back-link:hover {
  color: #343a40;
}

.header-title {
  margin-bottom: 0;
  font-size: 1.5rem;
}

@media print {
  .back-link,
  .btn {
    display: none;
  }
  
  .card {
    border: none;
    box-shadow: none;
  }
  
  .card-header {
    border-bottom: 1px solid #dee2e6;
    background-color: transparent;
  }
  
  .alert {
    display: none;
  }
}

.analysis-report :deep(h1) {
  font-size: 1.8rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

.analysis-report :deep(h2) {
  font-size: 1.5rem;
  margin-top: 1.25rem;
  margin-bottom: 0.75rem;
}

.analysis-report :deep(h3) {
  font-size: 1.25rem;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.analysis-report :deep(table) {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  border-collapse: collapse;
}

.analysis-report :deep(table th),
.analysis-report :deep(table td) {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.analysis-report :deep(table thead th) {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.analysis-report :deep(table tbody + tbody) {
  border-top: 2px solid #dee2e6;
}

.analysis-report :deep(blockquote) {
  padding: 0.5rem 1rem;
  margin: 0 0 1rem;
  border-left: 0.25rem solid #e9ecef;
  color: #6c757d;
}

.analysis-report :deep(ul),
.analysis-report :deep(ol) {
  margin-bottom: 1rem;
  padding-left: 2rem;
}

.analysis-report :deep(pre) {
  display: block;
  padding: 1rem;
  overflow: auto;
  font-size: 0.875rem;
  color: #212529;
  background-color: #f8f9fa;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
}

.analysis-report :deep(code) {
  font-size: 0.875em;
  color: #e83e8c;
  word-wrap: break-word;
}

.analysis-report :deep(a) {
  color: #007bff;
  text-decoration: none;
}

.analysis-report :deep(a:hover) {
  color: #0056b3;
  text-decoration: underline;
}
</style> 