<template>
    <div class="container mt-4">
      <div class="card">
        <div class="card-header d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center">
            <router-link to="/pitch-deck/reports" class="back-link me-2">
              <i class="uil uil-angle-left"></i>
            </router-link>
            <h2 class="header-title">Pitch Deck Analysis for {{ pitchDeck?.name }}</h2>
          </div>
          <button class="btn btn-primary" @click="printReport">
            <i class="uil uil-print me-1"></i>Print Report
          </button>
        </div>
        <div class="card-body pt-0">
          <div v-if="pitchDeck?.report !== null && pitchDeck?.report !== '' && pitchDeck?.report !== undefined" class="analysis-report">
            <div class="alert alert-info my-4">
              <p class="mb-0">
                Based on the report generated by our AI agent, we have identified over 100 investors that are a match for you, given your current funding stage.
                We also identified over 10 investors with whom you have mutual connections and can meet in person. To get access to the investors please contact us at 
                <a href="mailto:info@astelventures.com">info@astelventures.com</a>
              </p>
            </div>
            <div v-html="renderMarkdown(pitchDeck?.report)"></div>
            <div class="alert alert-info my-4">
              <p class="mb-0">
                Based on the report generated by our AI agent, we have identified over 100 investors that are a match for you, given your current funding stage.
                We also identified over 10 investors with whom you have mutual connections and can meet in person. To get access to the investors please contact us at 
                <a href="mailto:info@astelventures.com">info@astelventures.com</a>
              </p>
            </div>
          </div>
          <div v-else>
            <div class="alert alert-info">
              <p class="mb-0">No report found for this pitch deck.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapState } from 'vuex'
  import MarkdownIt from 'markdown-it'
  import MarkdownTexMath from '@/lib/texmath'
  import katex from 'katex'

  export default {
    name: 'DealAnalysisDetail',
    computed: {
      ...mapState({
        pitchDeck: state => state.dealAnalyser.deal,
        siteCompany: state => state.auth.siteCompany,
      }),
    },
    mounted() {
      // this.fetchReport()
    },
    methods: {
      cleanMarkdown(text) {
        // remove all ```markdown ...``` 
        text = text.replace(/```markdown/g, '')
        text = text.replace(/```/g, '')
        // replace all '\\' with '\'
        text = text.replace(/\\\\/g, '\\')
        // replace all \. with .
        text = text.replace(/\\[.]/g, '.')
        // remove all citations of the form [1] or [2]
        text = text.replace(/\[\d+\]/g, '')
        return text
      },
      renderMarkdown(text) {
        const md = new MarkdownIt({ html: true })
        md.use(MarkdownTexMath, {
          engine: katex,
          delimiters: ['brackets', 'dollars', 'doxygen', 'gitlab', 'julia', 'kramdown'],
          // katexOptions: { macros: {"\\RR": "\\mathbb{R}"} }
        })
        return md.render(this.cleanMarkdown(text))
      },
      fetchReport() {
        this.$store.dispatch('FETCH_DEAL_ANALYSIS', this.$route.params.id)
      },
      printReport() {
        const content = this.pitchDeck?.report || '';
        const printWindow = window.open('', '_blank');
        printWindow.document.write(`
          <html>
            <head>
              <title>${this.pitchDeck?.name} - Pitch Deck Analysis</title>
              <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/github-markdown-css">
              <style>
                @page {
                  size: A4;
                  margin: 20mm 20mm 40mm 20mm;
                }
                
                body { 
                  padding: 20px;
                  margin: 0;
                }
                
                .markdown-body { 
                  max-width: 800px; 
                  margin: 0 auto;
                  --bgColor-default: #fff;
                  --fgColor-default: #000;
                  --bgColor-muted: #eee;
                }

                .footer {
                  padding-top: 20px;
                  position: running(footer);
                  text-align: center;
                  font-size: 12px;
                  color: #666;
                }
                
                .katex {
                  font-size: 1.2em !important;  /* align KaTeX font-size to surrounding text */
                  math {
                    display: flex;
                  }
                }

                eq { display: inline-block; }
                eqn { display: block; padding: 0.5rem 0; }
                section {
                  display: inline-block;
                }
                section.eqno {
                    display: flex;
                    flex-direction: row;
                    align-content: space-between;
                    align-items: center;
                }
                section.eqno > eqn {
                    width: 100%;
                    margin-left: 3em;
                }
                section.eqno > span {
                    width:3em;
                    text-align:right;
                }

                .katex-html {
                  display: none;
                }

                @page {
                  @bottom-center {
                    content: element(footer);
                  }
                }
              </style>
            </head>
            <body>
              <div class="markdown-body">
                <img src="${this.siteCompany?.company_logo || '/static/images/astel_logo.png'}" alt="" height="40">
                <hr>
                <div class="alert alert-info my-4">
                  <p class="mb-0">
                    Based on the report generated by our AI agent, we have identified over 100 investors that are a match for you, given your current funding stage.
                    We also identified over 10 investors with whom you have mutual connections and can meet in person. To get access to the investors please contact us at 
                    <a href="mailto:info@astelventures.com">info@astelventures.com</a>
                  </p>
                </div>
                <hr>
                ${this.renderMarkdown(content)}
                <hr>
                <div class="alert alert-info my-4">
                  <p class="mb-0">
                    Based on the report generated by our AI agent, we have identified over 100 investors that are a match for you, given your current funding stage.
                    We also identified over 10 investors with whom you have mutual connections and can meet in person. To get access to the investors please contact us at 
                    <a href="mailto:info@astelventures.com">info@astelventures.com</a>
                  </p>
                </div>
              </div>
              <div class="footer">
                <hr>
                Astel Ventures: Pitch Deck Analysis for ${this.pitchDeck?.name}
              </div>
            </body>
          </html>
        `);
        printWindow.document.close();
        printWindow.onload = () => {
          printWindow.print();
          printWindow.onafterprint = () => {
            printWindow.close();
          };
        };
      }
    },
    watch: {
      '$route.params.id': {
        handler(newId) {
          if (newId) {
            this.fetchReport()
          }
        },
        immediate: true
      },
    }
  }
  </script>
  
  <style scoped>
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .back-link {
    color: #6c757d;
    text-decoration: none;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
  }
  
  .back-link:hover {
    color: #495057;
  }
  
  .header-title {
    font-size: 1.25rem;
    color: #6c757d;
    margin: 0;
    font-weight: normal;
  }
  </style>
  