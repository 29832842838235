import axios from 'axios'
import SparkMD5 from 'spark-md5'

const state = {
  companies: null,
  contacts: null,
  searchHash: '',
  companiesHash: '',
  companiesLoading: false,
  companiesLoaded: false,
  contactsHash: '',
  contactsLoading: false,
  contactsLoaded: false,
  searches: null,
  currentSearch: null,
  investorTypes: [],
  investmentStages: [],
  companyNames: [],
  oneLiner: [],
  cities: [],
  countries: [],
  titles: [],
  regions: [],
  contactTypes: [],
  searchFields: [],
  searchForm: [],
  searchOptions: {
    default_exclude: true,
    hide_ring_fence: true,
    hide_undeliverable_contact: true
  },
  breakdownOptions: {
    field_name: 'investment_countries',
    default: true,
    custom_groups: []
  },
  matchFields: [],
  matchGroup: '',
  jobs: [],
  currentSearchCancelToken: null,
  activeSearchCount: 0,
  currentJobNotification: null,
  showNextNotification: true,
  notifications: [],
  isCareerMatchesSearch: false,
  isBestMatchesSearch: false,
  isSameYearCareerMatchesSearch: false,
  breakdownField: {},
  breakdownTier: null,
  creditHistoryUsage: [],
}

const actions = {
  LOAD_COMPANIES ({ commit, dispatch, state }, params) {
    dispatch('LOADER_START')
    return new Promise((resolve, reject) => {
      axios.get(
        `${import.meta.env.VITE_API_URL}investors/search/`,
        {
          params,
          paramsSerializer: { 
            indexes: null
          }
        }
      )
        .then((response) => {
          commit('SET_COMPANIES', response.data)
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
        .finally(() => {
          dispatch('LOADER_STOP')
        })
    })
  },

  SEARCH_COMPANIES ({ commit, dispatch, state }, { form, params, startupId }) {
    return new Promise((resolve, reject) => {
      const hash = SparkMD5.hash(JSON.stringify(form) + JSON.stringify(params) + startupId)
      commit('SET_COMPANIES_HASH', hash)
      commit('SET_COMPANIES_LOADING', true)
      commit('SET_COMPANIES_LOADED', false)
      axios.post(
        `${import.meta.env.VITE_API_URL}search_investors/${startupId}/`,
        form,
        {
          params,
          paramsSerializer: { 
            indexes: null
          }
        }
      )
        .then((response) => {
          if (hash === state.companiesHash) {
            commit('SET_COMPANIES', response.data)
            commit('SET_COMPANIES_LOADING', false)
            commit('SET_COMPANIES_LOADED', true)
          }
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },

  LOAD_COMPANY ({ commit, dispatch, state }, companyId) {
    dispatch('LOADER_START')
    return new Promise((resolve, reject) => {
      axios.get(`${import.meta.env.VITE_API_URL}investors/${companyId}/`)
        .then((response) => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
        .finally(() => {
          dispatch('LOADER_STOP')
        })
    })
  },

  LOAD_COMPANY_MATCH_SCORE ({ commit, dispatch, state }, { startupId, companyId }) {
    return new Promise((resolve, reject) => {
      axios.get(`${import.meta.env.VITE_API_URL}startups/${startupId}/match_score/${companyId}/`)
        .then((response) => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
      })
  },

  UPDATE_COMPANY ({ commit, dispatch, state }, payload) {
    dispatch('LOADER_START')
    return new Promise((resolve, reject) => {
      axios.patch(`${import.meta.env.VITE_API_URL}investors/${payload.id}/`, payload)
        .then((response) => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
        .finally(() => {
          dispatch('LOADER_STOP')
        })
    })
  },

  UPDATE_COMPANY_INSIGHTS ({ commit, dispatch, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${import.meta.env.VITE_API_URL}investors/${payload.id}/insights/`, {})
        .then((response) => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },

  ONLINE_PORTFOLIO({ commit, dispatch, state }, company) {
    return new Promise((resolve, reject) => {
      axios.get(`${import.meta.env.VITE_API_URL}company/online_portfolio/${company.id}/`)
        .then((response) => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },
  CREATE_COMPANY ({ commit, dispatch, state }, payload) {
    dispatch('LOADER_START')
    return new Promise((resolve, reject) => {
      axios.post(`${import.meta.env.VITE_API_URL}investors/`, payload)
        .then((response) => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
        .finally(() => {
          dispatch('LOADER_STOP')
        })
    })
  },

  LOAD_COMPANY_CONTACTS ({ commit, dispatch, state }, { companyId, params }) {
    dispatch('LOADER_START')
    return new Promise((resolve, reject) => {
      axios.get(
        `${import.meta.env.VITE_API_URL}investors/${companyId}/contacts/`,
        {
          params,
          paramsSerializer: { 
            indexes: null
          }
        }
      )
        .then((response) => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
        .finally(() => {
          dispatch('LOADER_STOP')
        })
    })
  },

  SAVE_SEARCH ({ commit, dispatch, state }, { form, params, startupId }) {
    dispatch('LOADER_START')
    return new Promise((resolve, reject) => {
      axios.post(
        `${import.meta.env.VITE_API_URL}startups/${startupId}/searches/`,
        form,
        {
          params,
          paramsSerializer: { 
            indexes: null
          }
        }
      )
        .then((response) => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
        .finally(() => {
          dispatch('LOADER_STOP')
        })
    })
  },

  LOAD_CONTACTS ({ commit, dispatch, state }, params) {
    dispatch('LOADER_START')
    return new Promise((resolve, reject) => {
      axios.get(`${import.meta.env.VITE_API_URL}contacts/`, { params })
        .then((response) => {
          commit('SET_CONTACTS', response.data)
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
        .finally(() => {
          dispatch('LOADER_STOP')
        })
    })
  },

  SEARCH_CONTACTS ({ commit, dispatch, state }, { form, params, startupId }) {
    const hash = SparkMD5.hash(JSON.stringify(form) + JSON.stringify(params) + startupId)
    commit('SET_CONTACTS_HASH', hash)
    commit('SET_CONTACTS_LOADING', true)
    commit('SET_CONTACTS_LOADED', false)
    return new Promise((resolve, reject) => {
      axios.post(
        `${import.meta.env.VITE_API_URL}contacts/startup/${startupId}/search/`,
        form,
        {
          params,
          paramsSerializer: { 
            indexes: null
          }
        }
      )
        .then((response) => {
          if (hash === state.contactsHash) {
            commit('SET_CONTACTS', response.data)
            commit('SET_CONTACTS_LOADING', false)
            commit('SET_CONTACTS_LOADED', true)
          }
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },

  SEARCH ({ commit, dispatch, state }, { form, params, startupId }) {
    // Cancel the previous request if it exists
    if (state.currentSearchCancelToken && typeof state.currentSearchCancelToken.cancel === 'function') {
      state.currentSearchCancelToken.cancel('Operation canceled due to new request.')
    }

    // Create a new CancelToken
    const cancelToken = axios.CancelToken.source()
    commit('SET_CURRENT_SEARCH_CANCEL_TOKEN', cancelToken)

    const hash = SparkMD5.hash(JSON.stringify(form) + JSON.stringify(params) + startupId)
    commit('SET_SEARCH_HASH', hash)
    
    // Increment active search count and set loading states
    commit('INCREMENT_ACTIVE_SEARCH_COUNT')
    commit('SET_SEARCH_HASH', hash)
    commit('SET_CONTACTS_LOADING', true)
    commit('SET_CONTACTS_LOADED', false)
    commit('SET_COMPANIES_LOADING', true)
    commit('SET_COMPANIES_LOADED', false)
    return new Promise((resolve, reject) => {
      axios.post(
        `${import.meta.env.VITE_API_URL}search/${startupId}/`,
        form,
        {
          params,
          paramsSerializer: { 
            indexes: null
          },
          cancelToken: cancelToken.token
        }
      )
        .then((response) => {
          if (hash === state.searchHash) {
            commit('SET_CONTACTS', response.data.contacts)
            commit('SET_COMPANIES', response.data.companies)
          }
          resolve(response)
        })
        .catch(error => {
          if (axios.isCancel(error)) {
            console.log('Request canceled:', error.message)
          } else {
            console.log(error)
            reject(error)
          }
        })
        .finally(() => {
          commit('DECREMENT_ACTIVE_SEARCH_COUNT')
          
          if (state.activeSearchCount === 0) {
            commit('SET_CONTACTS_LOADING', false)
            commit('SET_CONTACTS_LOADED', true)
            commit('SET_COMPANIES_LOADING', false)
            commit('SET_COMPANIES_LOADED', true)
          }

          if (hash === state.searchHash) {
            commit('SET_CURRENT_SEARCH_CANCEL_TOKEN', null)
          }
        })
    })
  },

  LOAD_CONTACT ({ commit, dispatch, state }, { contactId, params }) {
    dispatch('LOADER_START')
    return new Promise((resolve, reject) => {
      axios.get(`${import.meta.env.VITE_API_URL}contacts/${contactId}/`, { params })
        .then((response) => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
        .finally(() => {
          dispatch('LOADER_STOP')
        })
    })
  },

  UPDATE_CONTACT ({ commit, dispatch, state }, payload) {
    dispatch('LOADER_START')
    return new Promise((resolve, reject) => {
      axios.patch(`${import.meta.env.VITE_API_URL}contacts/${payload.id}/`, payload)
        .then((response) => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
        .finally(() => {
          dispatch('LOADER_STOP')
        })
    })
  },
  
  UPDATE_CONTACT_INSIGHTS ({ commit, dispatch, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${import.meta.env.VITE_API_URL}contacts/${payload.id}/insights/`, {})
        .then((response) => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },
  
  UPDATE_CONTACT_CAREER ({ commit, dispatch, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${import.meta.env.VITE_API_URL}contacts/${payload.id}/career/`, {})
        .then((response) => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },

  LOAD_INSIGHTS_FEED ({ commit, dispatch, state }) {
    return new Promise((resolve, reject) => {
      axios.get(`${import.meta.env.VITE_API_URL}insights/`)
        .then((response) => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
      })
  },

  CREATE_CONTACT ({ commit, dispatch, state }, payload) {
    dispatch('LOADER_START')
    return new Promise((resolve, reject) => {
      axios.post(`${import.meta.env.VITE_API_URL}contacts/`, payload)
        .then((response) => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
        .finally(() => {
          dispatch('LOADER_STOP')
        })
    })
  },

  LOAD_CONTACT_NOTES ({ commit, dispatch, state }, contactId) {
    dispatch('LOADER_START')
    return new Promise((resolve, reject) => {
      axios.get(`${import.meta.env.VITE_API_URL}contacts/${contactId}/notes/`)
        .then((response) => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
        .finally(() => {
          dispatch('LOADER_STOP')
        })
    })
  },

  CREATE_CONTACT_NOTE ({ commit, dispatch, state }, payload) {
    dispatch('LOADER_START')
    return new Promise((resolve, reject) => {
      axios.post(`${import.meta.env.VITE_API_URL}contacts/${payload.contact}/notes/`, payload)
        .then((response) => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
        .finally(() => {
          dispatch('LOADER_STOP')
        })
    })
  },

  UPDATE_CONTACT_NOTE ({ commit, dispatch, state }, payload) {
    dispatch('LOADER_START')
    return new Promise((resolve, reject) => {
      axios.patch(`${import.meta.env.VITE_API_URL}contacts/${payload.contact}/notes/${payload.id}/`, payload)
        .then((response) => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
        .finally(() => {
          dispatch('LOADER_STOP')
        })
    })
  },

  DELETE_CONTACT_NOTE ({ commit, dispatch, state }, payload) {
    dispatch('LOADER_START')
    return new Promise((resolve, reject) => {
      axios.delete(`${import.meta.env.VITE_API_URL}contacts/${payload.contact}/notes/${payload.id}/`)
        .then((response) => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
        .finally(() => {
          dispatch('LOADER_STOP')
        })
    })
  },

  LOAD_SEARCHES ({ commit, dispatch, state }, { startupId, params }) {
    dispatch('LOADER_START')
    return new Promise((resolve, reject) => {
      axios.get(`${import.meta.env.VITE_API_URL}startups/${startupId}/searches/`, { params })
        .then((response) => {
          commit('SET_SEARCHES', response.data)
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
        .finally(() => {
          dispatch('LOADER_STOP')
        })
    })
  },

  LOAD_SEARCH ({ commit, dispatch, state }, { startupId, searchId, params }) {
    return new Promise((resolve, reject) => {
      axios.get(`${import.meta.env.VITE_API_URL}startups/${startupId}/searches/${searchId}/`, { params })
        .then((response) => {
          commit('SET_CURRENT_SEARCH', response.data)
          commit('SET_BREAKDOWN_FIELD', response.data.breakdown)
          commit('SET_MATCH_GROUP', response.data.selected_match_group)
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },

  UPDATE_SEARCH_CONNECTION_ANGLES_POPUP_FLAG ({ commit, dispatch, state }, { startupId, searchId }) {
    return new Promise((resolve, reject) => {
      axios.patch(`${import.meta.env.VITE_API_URL}startups/${startupId}/searches/${searchId}/`, {
        showed_popup_for_connection_angles: new Date().toISOString()
      })
        .then((response) => {
          // Update the current search in the state
          if (state.currentSearch && state.currentSearch.id === searchId) {
            commit('SET_CURRENT_SEARCH', { ...state.currentSearch, showed_popup_for_connection_angles: response.data.showed_popup_for_connection_angles })
          }
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },

  LOAD_CURRENT_SEARCH_AND_FETCH_FIELDS({ commit, dispatch }, { startupId, search }) {
    return new Promise((resolve, reject) => {
      commit('SET_CURRENT_SEARCH', search);
      commit('SET_BREAKDOWN_FIELD', search.breakdown);
      commit('SET_IS_BEST_MATCHES_SEARCH', search.is_best_match);
      commit('SET_IS_CAREER_MATCHES_SEARCH', search.is_career_match);
      commit('SET_IS_SAME_YEAR_CAREER_SEARCH', search.is_same_year_career_match);

      let breakdown = null;

      if(search.breakdown){
        breakdown = {
          custom_groups: [],
          default: true,
          field_name: search.breakdown?.id
        };
      }
      dispatch('LOAD_MATCH_FIELDS', { startupId, breakdown })
        .then(() => {
          commit('SET_MATCH_GROUP', search.selected_match_group);
          resolve();
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  
  DELETE_SEARCH ({ commit, dispatch, state }, { startupId, searchId, params }) {
    return new Promise((resolve, reject) => {
      axios.delete(`${import.meta.env.VITE_API_URL}startups/${startupId}/searches/${searchId}/`, { params })
        .then((response) => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },

  DELETE_CONTACT ({ commit, dispatch, state }, contactId) {
    return new Promise((resolve, reject) => {
      axios.delete(`${import.meta.env.VITE_API_URL}contacts/${contactId}/`)
        .then((response) => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },

  GENERATE_SEARCH_CSV ({ commit, dispatch, state }, { startupId, searchId, params }) {
    return new Promise((resolve, reject) => {
      axios.get(`${import.meta.env.VITE_API_URL}startups/${startupId}/searches/${searchId}/generate_csv/`, { params })
        .then((response) => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },

  GENERATE_OLD_SEARCH_CSV ({ commit, dispatch, state }, { startupId, searchId, params }) {
    return new Promise((resolve, reject) => {
      axios.get(`${import.meta.env.VITE_API_URL}startups/${startupId}/searches/${searchId}/generate_old_csv/`, { params })
        .then((response) => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },

  LOAD_SEARCH_FIELDS ({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      axios.get(`${import.meta.env.VITE_API_URL}options/search_fields/`)
        .then((response) => {
          commit('SET_SEARCH_FIELDS', response.data)
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },

  SEARCH_COMPANY_NAMES ({ commit, dispatch, state }, payload) {
    let query, pageSize

    if (typeof payload === 'undefined') {
      query = ''
      pageSize = 10 // Default value
    } else if (typeof payload === 'string') {
      query = payload
      pageSize = 10
    } else {
      ({ query, pageSize = 10 } = payload)
    }

    return new Promise((resolve, reject) => {
      axios.get(`${import.meta.env.VITE_API_URL}company_names/`, {
        params: {
          query,
          page_size: pageSize
        } })
        .then((response) => {
          commit('SET_COMPANY_NAMES', response.data)
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },

  LOAD_MATCH_FIELDS ({ commit, state, dispatch }, { startupId,  breakdown = null }) {
    return new Promise((resolve, reject) => {
      if (!breakdown) {
        axios.post(`${import.meta.env.VITE_API_URL}startups/${startupId}/match_fields/`)
        .then((response) => {
          commit('SET_MATCH_FIELDS', response.data);
          commit('SET_MATCH_GROUP', response?.data[0]?.name || '');
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
      } else {
        // breakdown = state.breakdownOptions
        commit('SET_BREAKDOWN_OPTIONS', breakdown)
        const data = {
          breakdownby: JSON.parse(JSON.stringify(breakdown))
        }
        data.breakdownby.custom_groups = data.breakdownby.custom_groups.map((g) => {
          g[breakdown.field_name] = g[breakdown.field_name].map((v) => v.id ? v.id : v)
          return g
        })
        axios.post(`${import.meta.env.VITE_API_URL}startups/${startupId}/match_fields/`, data)
        .then((response) => {
          commit('SET_MATCH_FIELDS', response.data)
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
      }
    })
  },

  LOAD_SEARCH_OPTIONS ({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      Promise.all([
        axios.get(`${import.meta.env.VITE_API_URL}options/investortypes/`).then((response) => {
          commit('SET_INVESTOR_TYPES', response.data)
        }),
        axios.get(`${import.meta.env.VITE_API_URL}options/investmentstages/`).then((response) => {
          commit('SET_INVESTMENT_STAGES', response.data)
        }),
        axios.get(`${import.meta.env.VITE_API_URL}options/countries/`).then((response) => {
          commit('SET_COUNTRIES', response.data)
        }),
        axios.get(`${import.meta.env.VITE_API_URL}options/titles/`).then((response) => {
          commit('SET_TITLES', response.data)
        }),
        axios.get(`${import.meta.env.VITE_API_URL}regions/`).then((response) => {
          commit('SET_REGIONS', response.data)
        }),
        axios.get(`${import.meta.env.VITE_API_URL}options/oneliner/`).then((response) => {
          commit('SET_ONELINER', response.data)
        }),
      ])
        .then(resolve)
        .catch(reject)
    })
  },

  LOAD_CONTACT_TYPES ({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      axios.get(`${import.meta.env.VITE_API_URL}options/contact-types/`)
        .then((response) => {
          commit('SET_CONTACT_TYPES', response.data)
          resolve(response)
        })
        .catch(reject)
    })
  },

  SEARCH_CITIES ({ commit, dispatch, state }, params) {
    return new Promise((resolve, reject) => {
      axios.get(`${import.meta.env.VITE_API_URL}options/cities/`, { params })
        .then((response) => {
          commit('SET_CITIES', response.data)
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },

  LOAD_BACKGROUND_JOBS ({ commit, dispatch, state }, params) {
    return new Promise((resolve, reject) => {
      axios.get(`${import.meta.env.VITE_API_URL}jobs/`, { params })
        .then((response) => {
          commit('SET_BACKGROUND_JOBS', response.data.results)
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },

  LOAD_NOTIFICATIONS ({ commit, dispatch, state }, params) {
    return new Promise((resolve, reject) => {
      axios.get(`${import.meta.env.VITE_API_URL}jobs/`, { params })
        .then((response) => {
          commit('SET_NOTIFICATIONS', response.data)
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },

  LOAD_CREDIT_HISTORY ({ commit, dispatch, state }, params) {
    return new Promise((resolve, reject) => {
      axios.get(`${import.meta.env.VITE_API_URL}credit_history/`, { params })
        .then((response) => {
          commit('SET_CREDIT_USAGE_HISTORY', response.data)
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },

  CANCEL_BACKGROUND_JOB ({ commit, dispatch, state }, jobId) {
    return new Promise((resolve, reject) => {
      axios.delete(`${import.meta.env.VITE_API_URL}jobs/${jobId}/`)
        .then((response) => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },

  RETRIEVE_JOB ({ commit, dispatch, state }, jobId) {
    return new Promise((resolve, reject) => {
      axios.get(`${import.meta.env.VITE_API_URL}jobs/${jobId}/`)
        .then((response) => {
          resolve(response)
        })
    })
  },

  MARK_JOB_NOTIFICATION_AS_READ ({ commit, dispatch, state }, jobId) {
    console.log("Marking the job notification as read");
    return new Promise((resolve, reject) => {
      axios.patch(`${import.meta.env.VITE_API_URL}jobs/${jobId}/`, {status: "READ"})
        .then((response) => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },

  LOAD_ANALYTICS ({ commit, dispatch, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${import.meta.env.VITE_API_URL}analytics/`, payload)
        .then((response) => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },

  LOAD_USAGE ({ commit, dispatch, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${import.meta.env.VITE_API_URL}usage/`, payload)
        .then((response) => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },

  UPLOAD_INVESTOR_DATA ({ commit, dispatch, state }, formData) {
    return new Promise((resolve, reject) => {
      axios.post(`${import.meta.env.VITE_API_URL}upload_data/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((response) => {
        resolve(response)
      })
      .catch(error => {
        console.log(error)
        reject(error)
      }
    )
    })
  },

  ASK_QUERY ({ commit, dispatch, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${import.meta.env.VITE_API_URL}ask/`, payload)
        .then((response) => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },

}

const getters = {}

const mutations = {
  SET_COMPANY_NAMES(state, companyNames) {
    state.companyNames = companyNames
  },
  SET_COMPANIES (state, payload) {
    state.companies = payload
  },
  SET_CONTACTS (state, payload) {
    state.contacts = payload
  },
  SET_SEARCH_HASH (state, payload) {
    state.searchHash = payload
  },
  SET_COMPANIES_HASH (state, payload) {
    state.companiesHash = payload
  },
  SET_COMPANIES_LOADING (state, payload) {
    state.companiesLoading = payload
  },
  SET_COMPANIES_LOADED (state, payload) {
    state.companiesLoaded = payload
  },
  SET_CONTACTS_HASH (state, payload) {
    state.contactsHash = payload
  },
  SET_CONTACTS_LOADING (state, payload) {
    state.contactsLoading = payload
  },
  SET_CONTACTS_LOADED (state, payload) {
    state.contactsLoaded = payload
  },
  SET_SEARCHES (state, payload) {
    state.searches = payload
  },
  SET_CURRENT_SEARCH (state, payload) {
    state.currentSearch = payload
  },
  SET_INVESTOR_TYPES (state, payload) {
    state.investorTypes = payload
  },
  SET_INVESTMENT_STAGES (state, payload) {
    state.investmentStages = payload
  },
  SET_ONELINER(state, payload) {
    state.oneLiner = payload
  },
  SET_CITIES (state, payload) {
    state.cities = payload
  },
  SET_COUNTRIES (state, payload) {
    state.countries = payload
  },
  SET_TITLES (state, payload) {
    state.titles = payload
  },
  SET_REGIONS (state, payload) {
    state.regions = payload
  },
  SET_CONTACT_TYPES (state, payload) {
    state.contactTypes = payload
  },
  SET_SEARCH_FIELDS (state, payload) {
    state.searchFields = payload
  },
  SET_SEARCH_FORM (state, payload) {
    state.searchForm = payload
  },
  SET_SEARCH_OPTIONS (state, payload) {
    state.searchOptions = payload
  },
  SET_BREAKDOWN_OPTIONS (state, payload) {
    state.breakdownOptions = payload
  },
  RESET_BREAKDOWN_OPTIONS (state) {
    state.breakdownOptions = {
      field_name: 'investment_countries',
      default: true,
      custom_groups: []
    }
  },
  SET_MATCH_FIELDS (state, payload) {
    state.matchFields = payload
  },
  SET_MATCH_GROUP (state, payload) {
    state.matchGroup = payload
  },
  SET_BACKGROUND_JOBS (state, payload) {
    state.jobs = payload
  },
  SET_NOTIFICATION (state, payload) {
    state.currentJobNotification = payload
  },
  SET_SHOW_NEXT_NOTIFICATION (state, payload) {
    state.showNextNotification = payload
  },
  SET_NOTIFICATIONS(state, payload) {
    state.notifications = payload
  },
  SET_CREDIT_USAGE_HISTORY(state, payload) {
    state.creditHistoryUsage = payload
  },
  SET_IS_CAREER_MATCHES_SEARCH (state, payload) {
    state.isCareerMatchesSearch = payload
  },
  SET_IS_SAME_YEAR_CAREER_SEARCH (state, payload) {
    state.isSameYearCareerMatchesSearch = payload
  },
  SET_IS_BEST_MATCHES_SEARCH (state, payload) {
    state.isBestMatchesSearch = payload
  },
  CLEAR_INVESTORS (state) {
    state.companies = null
    state.contacts = null
    state.searches = null
    state.investorTypes = []
    state.investmentStages = []
    state.countries = []
    state.contactTypes = []
    state.searchForm = []
    state.searchOptions = []
    state.breakdownOptions = {
      field_name: 'investment_countries',
      default: true,
      custom_groups: []
    }
    state.matchFields = []
    state.matchGroup = ''
    state.jobs = []
    state.currentJobNotification = null
  },
  SET_BREAKDOWN_FIELD (state, payload) {
    state.breakdownField = payload
  },
  SET_CURRENT_SEARCH_CANCEL_TOKEN(state, payload) {
    state.currentSearchCancelToken = payload
  },
  INCREMENT_ACTIVE_SEARCH_COUNT(state) {
    state.activeSearchCount++
  },
  DECREMENT_ACTIVE_SEARCH_COUNT(state) {
    state.activeSearchCount = Math.max(0, state.activeSearchCount - 1)
  },
  RESET_ACTIVE_SEARCH_COUNT(state){
    state.activeSearchCount = 0
  },
  SET_BREAKDOWN_TIER (state, payload){
    state.breakdownTier = payload
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
