import { createRouter, createWebHistory } from 'vue-router'

import Login from '@/components/Auth/Login.vue'
import Logout from '@/components/Auth/Logout.vue'
import Register from '@/components/Auth/Register.vue'
import Account from '@/components/Auth/Account.vue'

import Invitations from '@/components/Auth/Invitations.vue'

import EmailAccounts from '@/components/Auth/EmailAccounts.vue'
import ReplyIoKey from '@/components/Auth/ReplyIoKey.vue'
import GPTPrompts from '@/components/Auth/GPTPrompts.vue'
import EngagementCall from '@/components/Auth/EngagementCall.vue'
import EngagementCallView from '@/components/Auth/EngagementCallView.vue'

import StartupProfile from '@/components/Startups/StartupProfile.vue'

import Dashboard from '@/components/Dashboard.vue'
import Companies from '@/components/Investors/Companies.vue'
import CompanyContacts from '@/components/Investors/CompanyContacts.vue'
import CompanyView from '@/components/Investors/CompanyView.vue'
import CompanyEdit from '@/components/Investors/CompanyEdit.vue'
import CompanyDelete from '@/components/Investors/CompanyDelete.vue'
import ContactDelete from '@/components/Investors/ContactDelete.vue'
import Contacts from '@/components/Investors/Contacts.vue'
import ContactView from '@/components/Investors/ContactView.vue'
import ContactEdit from '@/components/Investors/ContactEdit.vue'

import Upload from '@/components/Investors/Upload.vue'
import Searches from '@/components/Investors/Searches.vue'
import SearchRequest from '@/components/Investors/SearchRequest.vue'
import Personalise from '@/components/Investors/Personalise.vue'
import Outreach from '@/components/Investors/Outreach.vue'
import ReplyIOCampaignStats from '@/components/Investors/ReplyIOCampaignStats.vue'
import Analytics from '@/components/Investors/Analytics.vue'
import Usage from '@/components/Investors/Usage.vue'

import Startups from '@/components/Startups/Startups.vue'
import StartupSelector from '@/components/Startups/Selector.vue'
import StartupView from '@/components/Startups/StartupView.vue'
import StartupEdit from '@/components/Startups/StartupEdit.vue'
import StartupDelete from '@/components/Startups/StartupDelete.vue'
import StartupRingfence from '@/components/Startups/StartupRingfence.vue'
import StartupEmailIntro from '@/components/Startups/StartupEmailIntro.vue'
import CareerMatches from '@/components/Startups/CareerMatches.vue'
import CoinvestorMatches from '@/components/Startups/CoinvestorMatches.vue'

import Astel from "@/components/Investors/Astel.vue";

// Temp
import Feed from '@/components/Investors/Feed.vue'
import Jobs from '@/components/Investors/Jobs.vue'

import Error404 from '@/components/Pages/404.vue'

import store from '@/store'
import axios from 'axios'
import Credits from '@/components/Investors/Credits.vue'
import PitchDeckLayout from '@/components/PitchDeck/Layout.vue'
import PitchDeck from '@/components/PitchDeck/Upload.vue'
import PitchDeckReports from '@/components/PitchDeck/Reports.vue'
import PitchDeckReportView from '@/components/PitchDeck/Detail.vue'
import PitchDeckReportSummary from '@/components/PitchDeck/Summary.vue'

import KeyLayout from '@/components/Keys/Layout.vue'
import ConfigureKey from '@/components/Keys/Page.vue'


const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    // Auth
    {
      name: 'login',
      path: '/me/login',
      component: Login
    },
    {
      name: 'login_token',
      path: '/me/login/:token',
      component: Login
    },
    {
      name: 'logout',
      path: '/me/logout',
      component: Logout
    },
    {
      name: 'register',
      path: '/me/join/:invite_key?',
      component: Register
    },
    {
      name: 'account',
      path: '/me/account',
      component: Account,
      meta: {
        requiresAuth: true
      },
    },
    {
      name: 'invitations',
      path: '/me/invitations',
      component: Invitations,
      meta: {
        requiresAuth: true
      },
    },
    {
      name: 'me_startup',
      path: '/me/startup',
      component: StartupProfile,
      meta: {
        requiresAuth: true
      },
    },
    {
      name: 'email_accounts',
      path: '/me/emails',
      component: EmailAccounts,
      meta: {
        requiresAuth: true
      },
    },
    {
      name: 'feed',
      path: '/me/feed',
      component: Feed,
      meta: {
        requiresAuth: true
      },
    },
    {
      name: 'reply-io-key',
      path: '/me/reply-io-key',
      component: ReplyIoKey,
      meta: {
        requiresAuth: true
      },
    },
    {
      name: 'gpt-prompts',
      path: '/me/gpt-prompts',
      component: GPTPrompts,
      meta: {
        requiresAuth: true
      },
    },
    {
      name: 'engagement-call-info',
      path: '/engagement-call-info',
      component: EngagementCall,
      meta: {
        requiresAuth: true
      },
    },
    {
      name: 'engagement-call-info-detail',
      path: '/engagementcallinfo/:id',
      component: EngagementCallView,
      meta: {
        requiresAuth: true
      },
    },
    {
      name: 'startups_selector',
      path: '/startups/selector',
      component: StartupSelector,
      meta: {
        requiresAuth: true
      },
    },
    {
      name: 'upload',
      path: '/me/upload',
      component: Upload,
    },
    {
      name: 'jobs',
      path: '/jobs',
      component: Jobs,
      meta: {
        requiresAuth: true
      },
    },
    {
      name: 'credits',
      path: '/credits',
      component: Credits,
      meta: {
        requiresAuth: true
      },
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      redirect: '/startups/selector'
    },
    {
      name: 'home',
      path: '/',
      component: Dashboard,
      meta: {
        requiresAuth: true
      },
      children: [
        {
          name: 'startup_profile',
          path: 'startup/profile',
          component: StartupView,
        },
        {
          name: 'startup_profile_edit',
          path: 'startups/profile/edit',
          component: StartupEdit,
        },
        {
          name: 'startup_profile_add',
          path: 'startups/profile/add',
          component: StartupEdit,
        },
        {
          name: 'investors_companies',
          path: 'investors/companies',
          component: Companies,
        },
        {
          name: 'investors_company_detail',
          path: 'investors/companies/:id',
          component: CompanyView,
        },
        {
          name: 'investors_company_edit',
          path: 'investors/companies/:id/edit',
          component: CompanyEdit,
        },
        {
          name: 'investors_company_contacts',
          path: 'investors/companies/:id/contacts',
          component: CompanyContacts,
        },
        {
          name: 'investors_company_delete',
          path: 'investors/companies/:id/delete',
          component: CompanyDelete,
        },
        {
          name: 'contact_delete',
          path: 'contacts/:id/delete',
          component: ContactDelete,
        },
        {
          name: 'investors_contacts',
          path: 'investors/contacts',
          component: Contacts,
        },
        {
          name: 'astel_campaigns',
          path: 'investors/astel',
          component: Astel,
        },
        {
          name: 'contact_detail',
          path: 'contacts/:id',
          component: ContactView,
        },
        {
          name: 'contact_new_startup',
          path: 'contacts/new/startup/:startup_id',
          component: ContactEdit,
        },
        {
          name: 'contact_edit',
          path: 'contacts/:id/edit',
          component: ContactEdit,
        },
        {
          name: 'investors_searches',
          path: 'investors/searches',
          component: Searches,
        },
        {
          name: 'submit_search_request',
          path: 'investors/astel/submit',
          component: SearchRequest,
        },
        {
          name: 'investors_personalise',
          path: 'investors/personalise',
          component: Personalise,
        },
        {
          name: 'investors_outreach',
          path: 'investors/outreach',
          component: Outreach,
        },
        {
          name: 'replyio_campaign_stats',
          path: 'investors/outreach/campaign/:campaignId',
          component: ReplyIOCampaignStats,
        },
        {
          name: 'investors_analytics',
          path: 'investors/analytics',
          component: Analytics,
        },
        {
          name: 'usage',
          path: 'me/usage',
          component: Usage,
        },
        {
          name: 'startups',
          path: 'startups',
          component: Startups,
        },
        {
          name: 'startups_detail',
          path: 'startups/:id',
          component: StartupView,
        },
        {
          name: 'startups_edit',
          path: 'startups/:id/edit',
          component: StartupEdit,
        },
        {
          name: 'startups_delete',
          path: 'startups/:id/delete',
          component: StartupDelete,
        },
        {
          name: 'startups_ringfence',
          path: 'startups/:id/ringfence',
          component: StartupRingfence,
        },
        {
          name: 'startups_emailintro',
          path: 'startups/:id/emailintro',
          component: StartupEmailIntro,
        },
        {
          name: 'startups_career_matches',
          path: 'startups/:id/career-matches',
          component: CareerMatches,
        },
        {
          name: 'startups_warm_introductions',
          path: 'startups/:id/coinvestor-matches',
          component: CoinvestorMatches,
        },
        {
          path: '/pitch-deck',
          component: PitchDeckLayout,
          meta: {
            requiresAuth: true,
          },
          children: [
            {
              name: 'pitch_deck_upload',
              path: '',
              component: PitchDeck,
            },
            {
              name: 'pitch_deck_reports',
              path: 'reports',
              component: PitchDeckReports,
            },
            {
              name: 'pitch_deck_report_detail',
              path: 'reports/:id',
              component: PitchDeckReportView,
            },
            {
              name: 'pitch_deck_report_summary',
              path: 'reports/:id/summary',
              component: PitchDeckReportSummary,
            }
          ]
        },
        {
          name: 'keys',
          path: 'keys',
          component: KeyLayout,
          meta: {
            requiresAuth: true,
          },
          children: [
            {
              name: 'configure_keys',
              path: '',
              component: ConfigureKey,
            }
          ]
        }
      ],
    },
    {
      name: 'not-found',
      path: '/:catchAll(.*)*',
      component: Error404
    }
  ]
})

const waitForStorageToBeReady = async (to, from, next) => {
  await store.restored
  if (store.state.auth.token) {
    axios.defaults.headers.common['Authorization'] = `Token ${store.state.auth.token}`
    try {
      await store.dispatch('LOAD_SITE_COMPANY')
    } catch (e) {
      // delete header
      delete axios.defaults.headers.common['Authorization']
    }
  }
  next()
}
router.beforeEach(waitForStorageToBeReady)

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth) && !store.state.auth.isLoggedIn) {
    next({ path: '/me/login' })
  } else {
    next()
  }
})

export default router
