<template>
  <div id="app">
    <!-- <app-header></app-header> -->
    <router-view></router-view>
    <!-- <app-footer></app-footer> -->
  </div>
</template>

<script>
import { mapState } from 'vuex'

import Header from './components/Layout/Header.vue'
import Footer from './components/Layout/Footer.vue'

export default {
  name: 'app',

  components: {
    'app-header': Header,
    'app-footer': Footer
  },

  computed: {
    ...mapState({
      user: state => state.auth.user,
      siteCompany: state => state.auth.siteCompany,
    })
  },

  data () {
    return {
      FStimer: null
    }
  },

  watch: {
    '$route' () {
      this.onRouteChange()
    },

    'user' () {
      this.onUserChange()
    },

    'siteCompany' () {
      document.title = this.siteCompany ? this.siteCompany.title : '...'
    }
  },

  methods: {
    onRouteChange () {},

    deferIdentifyFS () {
      this.FStimer = setTimeout(() => {
        const FSloaded = typeof window.FS !== 'undefined'
        if (FSloaded) {
          var name = this.user.email
          if (this.user.first_name && this.user.last_name) {
            name = this.user.first_name + ' ' + this.user.last_name
          }
          window.FS('setIdentity', {
            uid: this.user.id,
            properties: {
              displayName: name,
              email: this.user.email,
            }
          })
        } else {
          this.deferIdentifyFS()
        }
      }, 1000)
    },

    onUserChange () {
      const FSloaded = typeof window.FS !== 'undefined'
      const FSloadDefined = typeof loadFullStory !== 'undefined'
      if (this.user && this.user.id && !this.user.is_staff && !FSloaded && FSloadDefined) {
        loadFullStory()
        this.deferIdentifyFS()
      }
    }
  }
}
</script>

<style lang="scss"></style>
