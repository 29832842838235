<template>
  <div>
    <div v-if="contact">
      <div class="row">
        <div class="col pe-0">
          <div class="card">
            <div class="card-body">

              <div v-if="isStartupContactPage" class="mb-4">
                <h3 class="mb-3">Strengthen Your Network Through Your Team</h3>
                <p class="text-muted">
                  We recommend adding a minimum of <b>five core team members</b> to maximize your mutual connection matches.
                  Add key team members, investors, and advisors that have the strongest LinkedIn profiles in terms of
                  Education and Career History in order for our platform to identify mutual career or educational
                  history with investors. You are 3x more likely to find mutual connections and warm introductions.
                </p>
                <p class="text-muted">
                  We recommend adding at least <b>five team members</b> to maximize your matches - startups that do so are 3x
                  more likely to find relevant investors. Based on your team's career paths and expertise, we'll match
                  you with investors who have a track record of investing in teams like yours. Take a moment to showcase
                  the talented people behind your success – it only takes a few minutes to unlock personalized investor
                  recommendations.
                </p>
                <hr class="my-4">
              </div>

              <div class="d-flex justify-content-between align-items-start mb-4" v-if="!isStartupContactPage">
                <h4 class="header-title m-0">CONTACT PROFILE</h4>
                <div class="d-flex gap-2">
                  <a href="#" class="btn btn-outline-primary" @click.prevent="goView">
                    <i class="uil uil-eye me-1"></i>
                    <span>View</span>
                  </a>
                  <a href="#" class="btn btn-outline-danger" @click.prevent="goDelete">
                    <i class="uil uil-trash me-1"></i>
                    <span>Delete</span>
                  </a>
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-md-3">
                  <label for="id_title" class="form-label">Title:</label>
                  <v-select name="id_title" v-model="contact.title" :options="optionsTitles" :reduce="x => x.id"
                    label="name" multiple>
                  </v-select>
                </div>
                <div class="col-md-4">
                  <label for="id_first_name" class="form-label">First name:</label>
                  <input id="id_first_name" type="text" name="first_name" class="form-control" required
                    v-model="contact.first_name">
                  <p v-if="errors.first_name && errors.first_name.length">
                    <span v-for="error in errors.first_name" :key="error" class="text-danger">{{ error }}</span>
                  </p>
                </div>
                <div class="col-md-5">
                  <label for="id_last_name" class="form-label">Last name:</label>
                  <input id="id_last_name" type="text" name="last_name" class="form-control" required
                    v-model="contact.last_name">
                  <p v-if="errors.last_name && errors.last_name.length">
                    <span v-for="error in errors.last_name" :key="error" class="text-danger">{{ error }}</span>
                  </p>
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-md-6">
                  <label for="id_email" class="form-label">Email:</label>
                  <input id="id_email" type="email" name="email" class="form-control" required v-model="contact.email">
                  <p v-if="errors.email && errors.email.length">
                    <span v-for="error in errors.email" :key="error" class="text-danger">{{ error }}</span>
                  </p>
                </div>
                <div class="col-md-6">
                  <label for="id_phone_number" class="form-label">Phone Number:</label>
                  <input id="id_phone_number" type="tel" name="phone_number" class="form-control" required
                    v-model="contact.phone_number">
                  <p v-if="errors.phone_number && errors.phone_number.length">
                    <span v-for="error in errors.phone_number" :key="error" class="text-danger">{{ error }}</span>
                  </p>
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-md-6">
                  <label for="id_linkedIn_URL" class="form-label">LinkedIn:</label>
                  <input id="id_linkedIn_URL" type="text" name="linkedIn_URL" class="form-control" required
                    v-model="contact.linkedIn_URL" @input="validateLinkedIn">
                  <p v-if="errors.linkedIn_URL && errors.linkedIn_URL.length">
                    <span v-for="error in errors.linkedIn_URL" :key="error" class="text-danger">{{ error }}</span>
                  </p>
                  <small class="text-muted">URL must start with https://www.linkedin.com/in/</small>
                </div>
                <div class="col-md-6">
                  <div class="d-flex flex-wrap gap-3 align-items-center h-100 pt-4">
                    <div v-if="isStartupContactPage || contact.startup" class="form-check">
                      <input id="id_outreach_contact" type="checkbox" class="form-check-input"
                        v-model="contact.is_startup_outreach_contact">
                      <label for="id_outreach_contact" class="form-check-label">Outreach Contact?</label>
                    </div>
                    <div class="form-check">
                      <input id="id_deliverable" type="checkbox" class="form-check-input" required
                        v-model="contact.deliverable">
                      <label for="id_deliverable" class="form-check-label">Deliverable</label>
                    </div>
                    <div class="form-check">
                      <input id="id_unsubscribe" type="checkbox" class="form-check-input" required
                        v-model="contact.unsubscribe">
                      <label for="id_unsubscribe" class="form-check-label">Unsubscribe</label>
                    </div>
                    <div class="form-check">
                      <input id="id_is_deal_dashboard" type="checkbox" class="form-check-input" required
                        v-model="contact.is_deal_dashboard">
                      <label for="id_is_deal_dashboard" class="form-check-label">Deal Dashboard</label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label for="id_linkedIn_about" class="form-label">About:</label>
                <textarea id="id_linkedIn_about" name="linkedIn_about" class="form-control" required
                  v-model="contact.linkedIn_about" rows="4"></textarea>
                <p v-if="errors.linkedIn_about && errors.linkedIn_about.length">
                  <span v-for="error in errors.linkedIn_about" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="text-center">
      <loader></loader>
    </div>

    <div class="action-footer">
      <div class="container-fluid">
        <div class="card mb-0">
          <div class="card-body">
            <div class="float-end">
              <div v-if="hasError" class="text-danger me-2">Error. Please check the form.</div>
              <button v-if="!contactId" class="btn btn-secondary me-2" :class="{ disabled: isSubmitting }" @click.prevent="saveAndAddMore">
                Save and add more
              </button>
              <button class="btn btn-primary" :class="{ disabled: isSubmitting }" @click.prevent="submit">
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Swal from 'sweetalert2';

export default {
  name: 'contact',

  data() {
    return {
      contact: null,
      errors: {},
      isSubmitting: false
    }
  },

  computed: {
    ...mapState({
      optionsTitles: state => state.investors.titles,
    }),
    contactId() {
      return this.$route.params.id || (this.contact ? this.contact.id : null)
    },
    hasError() {
      return Object.keys(this.errors).length > 0
    },
    isStartupContactPage() {
      return !!this.$route.params.startup_id
    },
  },

  mounted() {
    window.scrollTo(0, 0)

    if (this.contactId) {
      Promise.all([
        this.$store.dispatch('LOAD_SEARCH_OPTIONS'),
        this.$store.dispatch('LOAD_CONTACT', { contactId: this.contactId })
          .then((response) => {
            this.contact = response.data
          })
      ]).then(() => {
        this.contact.title = this.contact.title.map(x => x.id)
      })
    } else {
      this.contact = {}
      this.$store.dispatch('LOAD_SEARCH_OPTIONS')
    }
  },

  methods: {
    goView() {
      this.$router.push(`/contacts/${this.contact.id}`)
    },
    goEdit() {
      this.$router.push(`/contacts/${this.contact.id}/edit`)
    },
    goDelete() {
      this.$router.push(`/contacts/${this.contact.id}/delete`)
    },
    submit() {
      this.isSubmitting = true
      this.errors = {}

      if (this.contact.company && this.contact.company.id) {
        this.contact.company = this.contact.company.id
      }

      if (this.$route.params.startup_id) {
        this.contact.startup = this.$route.params.startup_id
      }

      if (this.contactId) {
        this.$store.dispatch('UPDATE_CONTACT', this.contact)
          .then(() => {
            this.isSubmitting = false
            this.$router.go(-1)
          })
          .catch((error) => {
            this.isSubmitting = false
            this.errors = error.response.data
          })
      } else {
        this.contact.created_date = new Date()

        this.$store.dispatch('CREATE_CONTACT', this.contact)
          .then((response) => {
            this.isSubmitting = false
            this.$router.go(-1)
          })
          .catch((error) => {
            this.isSubmitting = false
            this.errors = error.response.data
          })
      }
    },
    saveAndAddMore() {
      this.isSubmitting = true
      this.errors = {}

      if (this.contact.company && this.contact.company.id) {
        this.contact.company = this.contact.company.id
      }

      if (this.$route.params.startup_id) {
        this.contact.startup = this.$route.params.startup_id
      }

      this.contact.created_date = new Date()

      this.$store.dispatch('CREATE_CONTACT', this.contact)
        .then(() => {
          this.isSubmitting = false
          // Reset form for a new contact
          this.contact = {
            title: [],
            deliverable: true,
            unsubscribe: false,
            is_deal_dashboard: false
          }
          
          if (this.$route.params.startup_id) {
            this.contact.startup = this.$route.params.startup_id
            this.contact.is_startup_outreach_contact = false
          }
          
          // Show success message with SWAL
          Swal.fire({
            title: 'Success!',
            text: 'Contact created successfully. You can add another contact now.',
            icon: 'success',
            confirmButtonText: 'Continue',
            timer: 3000
          })
        })
        .catch((error) => {
          this.isSubmitting = false
          this.errors = error.response.data
        })
    },
    validateLinkedIn() {
      const linkedInPrefix = 'https://www.linkedin.com/in/';
      if (this.contact.linkedIn_URL && !this.contact.linkedIn_URL.startsWith(linkedInPrefix)) {
        this.errors.linkedIn_URL = ['LinkedIn URL must start with https://www.linkedin.com/in/'];
      } else {
        // Clear the LinkedIn URL error if it exists
        if (this.errors.linkedIn_URL) {
          delete this.errors.linkedIn_URL;
        }
      }
    },
  }
}
</script>
