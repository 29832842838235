<template>
  <div class="container-fluid">
    <div class="row mb-4">
      <div class="col">
        <h4 class="mb-0">Warm Introduction Opportunities</h4>
        <p class="text-muted mb-0">Find potential warm introductions through your cap table investors</p>
      </div>
      <div class="col-auto">
        <button 
          class="btn btn-primary me-2" 
          @click="handleRefresh" 
          :disabled="isMatchingInProgress || isFetchingInvestorMatches || isMatchingFailed"
        >
          <span v-if="isFetchingInvestorMatches">Loading...</span>
          <span v-else-if="isMatchingInProgress">Matching in progress...</span>
          <span v-else-if="isMatchingFailed">Retry matching</span>
          <span v-else><i class="uil uil-sync me-1"></i>Refresh</span>
        </button>
        <button 
          class="btn btn-primary me-2" 
          @click="handleFindMatches"
          :disabled="isMatchingInProgress || isFetchingInvestorMatches"
        >
          <i class="uil uil-search me-1"></i>Re Calculate Alumni Connection with Investors
        </button>
        <button class="btn btn-secondary" @click="$router.go(-1)">
          <i class="uil uil-arrow-left me-1"></i>Back
        </button>
      </div>
    </div>

    <!-- Connection Type Tabs -->
    <div class="row mb-4">
      <div class="col">
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a class="nav-link" :class="{ active: connectionType === 'coinvestors' }" href="#" @click.prevent="connectionType = 'coinvestors'">
              <i class="uil uil-users-alt me-1"></i>Cap Table to Coinvestors
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" :class="{ active: connectionType === 'all_investors' }" href="#" @click.prevent="connectionType = 'all_investors'">
              <i class="uil uil-globe me-1"></i>Cap Table to All Investors
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div v-if="isMatchingCompleted && investorMatches.length > 0" class="alert alert-success">
      <i class="uil uil-check-circle me-1"></i>
      Matching process completed successfully. Found {{ investorMatches.length }} potential connections.
    </div>

    <div v-if="isMatchingCompleted && investorMatches.length === 0" class="alert alert-info">
      <i class="uil uil-info-circle me-1"></i>
      Matching process completed, but no connections were found. Try adding more investors to increase potential matches.
    </div>

    <div v-if="isMatchingFailed" class="alert alert-danger">
      <i class="uil uil-exclamation-triangle me-1"></i>
      The matching process failed. Please try again.
    </div>

    <div v-if="isMatchingCancelled" class="alert alert-warning">
      <i class="uil uil-exclamation-circle me-1"></i>
      The matching process was cancelled.
    </div>

    <!-- Description based on selected tab -->
    <div class="row mb-3" v-if="!isFetchingInvestorMatches">
      <div class="col">
        <div v-if="connectionType === 'coinvestors'" class="alert alert-info border">
          <i class="uil uil-info-circle me-1"></i>
          This view shows potential warm introductions from your cap table investors to coinvestors in other companies.
        </div>
        <div v-else class="alert alert-info border">
          <i class="uil uil-info-circle me-1"></i>
          This view shows potential warm introductions from your cap table investors to any investor in our database.
        </div>
      </div>
    </div>

    <div v-if="isFetchingInvestorMatches" class="text-center py-4">
      <loader></loader>
      <p class="mt-2">Processing... Please wait while we find warm introduction opportunities.</p>
    </div>
    
    <!-- Show when no data is available -->
    <div v-else-if="!currentMatches || currentMatches.length === 0" class="alert alert-info">
      <p v-if="connectionType === 'coinvestors'">No warm introduction opportunities found to coinvestors.</p>
      <p v-else>No warm introduction opportunities found to investors in our database.</p>
      <button v-if="connectionType === 'coinvestors'" class="btn btn-primary mt-2" @click="handleFindMatches">
        <i class="uil uil-search me-1"></i>Find Coinvestor Connections
      </button>
      <button v-else class="btn btn-primary mt-2" @click="handleFindAllInvestorsMatches">
        <i class="uil uil-search me-1"></i>Find All Investor Connections
      </button>
    </div>

    <div v-else>
      <div class="row">
        <div class="col-md-3">
          <div class="card">
            <div class="card-body">
              <h6 class="card-title mb-3">Companies</h6>
              <div class="list-group">
                <a 
                  v-for="(match, index) in paginatedMatches" 
                  :key="index"
                  href="#"
                  class="list-group-item list-group-item-action"
                  :class="{ active: activeCompanyIndex === index }"
                  @click.prevent="activeCompanyIndex = index"
                >
                  {{ match.company }}
                  <span v-if="match.warm_introductions" class="badge bg-info ms-1">
                    {{ match.warm_introductions.length }}
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-9">
          <div class="card">
            <div class="card-body">
              <div v-if="paginatedMatches.length > 0 && paginatedMatches[activeCompanyIndex] && paginatedMatches[activeCompanyIndex].warm_introductions">
                <h6 class="card-title mb-3">
                  Connection Opportunities from {{ paginatedMatches[activeCompanyIndex].company }}
                </h6>
                <div class="match-list">
                  <div v-for="intro in paginatedMatches[activeCompanyIndex].warm_introductions" 
                       :key="intro.introducing_investor.id"
                       class="match-item">
                    <div class="connection-card">
                      <div class="connection-header">
                        <div class="connection-path">
                          <div class="investor-pill">
                            <div class="avatar">{{ intro.introduced_investor.name.charAt(0) }}</div>
                            <div class="investor-info">
                              <a :href="`/contacts/${intro.introduced_investor.id}/`" 
                                 target="_blank" 
                                 class="investor-name-link"
                                 :title="`View ${intro.introduced_investor.name}'s profile`">
                                {{ intro.introduced_investor.name }}
                                <i class="uil uil-external-link-alt"></i>
                              </a>
                              <span class="company">{{ intro.introduced_investor.company }}</span>
                            </div>
                          </div>

                          <div class="connection-context">
                            <div class="context-line">
                              <i class="uil uil-arrow-right"></i>
                              <span class="action-text">can introduce you to</span>
                              <i class="uil uil-arrow-right"></i>
                            </div>
                          </div>

                          <div class="investor-pill target">
                            <div class="avatar">{{ intro.introducing_investor.name.charAt(0) }}</div>
                            <div class="investor-info">
                              <a :href="`/contacts/${intro.introducing_investor.id}/`" 
                                 target="_blank" 
                                 class="investor-name-link"
                                 :title="`View ${intro.introducing_investor.name}'s profile`">
                                {{ intro.introducing_investor.name }}
                                <i class="uil uil-external-link-alt"></i>
                              </a>
                              <span class="company">{{ intro.introducing_investor.company }}</span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="connection-details">
                        <div class="connection-basis">
                          <div :class="['basis-tag', intro.match_type === 'EDUCATION' ? 'education' : 'work']">
                            <i :class="['uil', intro.match_type === 'EDUCATION' ? 'uil-graduation-cap' : 'uil-briefcase']"></i>
                            <span>{{ intro.match_type === 'EDUCATION' ? 'Studied together at' : 'Worked together at' }}</span>
                            <strong>{{ intro.connection_at }}</strong>
                          </div>
                          
                          <div class="timing-info">
                            <div v-if="intro.same_year_match" class="timing-tag same-year">
                              <i class="uil uil-calendar-alt"></i>
                              <span>Same Year Match</span>
                            </div>
                            <div v-if="intro.overlap_period" class="timing-tag overlap">
                              <i class="uil uil-clock"></i>
                              <span>{{ intro.overlap_period }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col">
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <label class="me-2">Items per page:</label>
              <select class="form-select form-select-sm" v-model="itemsPerPage" @change="handleItemsPerPageChange">
                <option v-for="size in pageSizeOptions" :key="size" :value="size">{{ size }}</option>
              </select>
            </div>
            <div>
              <button 
                class="btn btn-sm btn-outline-secondary me-2" 
                :disabled="currentPage === 1"
                @click="handlePageChange(currentPage - 1)"
              >
                Previous
              </button>
              <button 
                class="btn btn-sm btn-outline-secondary"
                :disabled="currentPage * itemsPerPage >= currentFilteredMatches.length"
                @click="handlePageChange(currentPage + 1)"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Loader from '@/components/Includes/Loader.vue'
import Swal from 'sweetalert2'

export default {
  name: 'CoinvestorMatches',
  
  components: {
    Loader
  },
  
  data() {
    return {
      startup: null,
      startupId: null,
      sameYearOnly: false,
      currentPage: 1,
      itemsPerPage: 10,
      pageSizeOptions: [5, 10, 25, 50],
      activeTab: 'all',
      activeCompanyIndex: 0,
      connectionType: 'coinvestors', // Default tab is 'coinvestors'
      allInvestorsMatches: [], // Store matches for all investors tab
      hasAllInvestorsData: false // Flag to track if all investors data has been loaded
    }
  },
  
  computed: {
    ...mapState({
      user: state => state.auth.user,
      investorMatches: state => state.startups.investorMatches,
      isFetchingInvestorMatches: state => state.startups.isFetchingInvestorMatches,
      current: state => state.startups.current
    }),
    
    // Determine which set of matches to use based on selected tab
    currentMatches() {
      return this.connectionType === 'coinvestors' ? this.investorMatches : this.allInvestorsMatches;
    },

    // Use currentMatches as the base for filtering
    currentFilteredMatches() {
      if (!this.currentMatches || !this.currentMatches.length) {
        return [];
      }
      
      // Create a safe copy of the data that handles missing properties
      const safeMatches = this.currentMatches.map(match => {
        if (!match) {
          console.warn('Found null/undefined match item in matches array');
          return { company: 'Unknown', warm_introductions: [] };
        }
        
        // Check if match has the expected properties
        if (!match.company) {
          console.warn('Match missing company property:', match);
        }
        
        if (!Array.isArray(match.warm_introductions)) {
          console.warn('Match has invalid or missing warm_introductions array:', match);
        }
        
        return {
          ...match,
          company: match.company || 'Unknown Company',
          warm_introductions: Array.isArray(match.warm_introductions) ? match.warm_introductions : []
        };
      });
      
      // Return all matches sorted by connection count
      return [...safeMatches]
        .sort((a, b) => b.warm_introductions.length - a.warm_introductions.length);
    },
    
    // Use currentFilteredMatches for pagination
    paginatedMatches() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.currentFilteredMatches.slice(start, end);
    },
    
    isMatchingInProgress() {
      return this.startup?.coinvestor_matching_status === 'IN_PROGRESS'
    },

    isMatchingFailed() {
      return this.startup?.coinvestor_matching_status === 'FAILED'
    },

    isMatchingCancelled() {
      return this.startup?.coinvestor_matching_status === 'CANCELLED'
    },

    isMatchingCompleted() {
      return this.startup?.coinvestor_matching_status === 'COMPLETED'
    },

    lastMatchingTimestamp() {
      return this.startup?.coinvestor_matching_updated_at
    },

    lastMatchingStatus() {
      const status = this.startup?.coinvestor_matching_status
      switch(status) {
        case 'COMPLETED':
          return 'completed'
        case 'FAILED':
          return 'failed'
        case 'CANCELLED':
          return 'cancelled'
        case 'IN_PROGRESS':
          return 'started'
        default:
          return 'unknown'
      }
    },
    
    totalPages() {
      return Math.ceil(this.currentFilteredMatches.length / this.itemsPerPage) || 1;
    },
    
    hasCrossCompanyMatches() {
      const matches = this.currentMatches;
      if (!Array.isArray(matches)) return false;
      if (matches.length === 0) return false;
      
      // Check if any match has connections
      return matches.some(match => 
        match && match.warm_introductions && this.getTotalUniqueConnectionCount(match.warm_introductions) > 0
      );
    },
    
    hasConnections() {
      return this.currentMatches && this.currentMatches.length > 0
    }
  },
  
  watch: {
    paginatedMatches: {
      handler(newMatches) {
        // Reset activeCompanyIndex if it's out of bounds
        if (newMatches.length === 0 || this.activeCompanyIndex >= newMatches.length) {
          this.activeCompanyIndex = 0
        }
      },
      immediate: true
    },
    
    // Reset page when changing tabs
    connectionType() {
      this.currentPage = 1;
      this.activeCompanyIndex = 0;
      
      // If switching to all investors tab and no data, show info message
      if (this.connectionType === 'all_investors' && !this.hasAllInvestorsData) {
        // You could automatically trigger the search here if desired
        // this.handleFindAllInvestorsMatches();
      }
    }
  },
  
  mounted() {
    window.scrollTo(0, 0)
    this.startupId = this.$route.params.id || (this.current && this.current.id)
    if (this.startupId) {
      this.$store.dispatch('LOAD_STARTUP', this.startupId)
        .then((response) => {
          this.startup = response.data
          // Load matches after startup data is loaded
          this.handleRefresh()
        })
        .catch((error) => {
          console.error('Error loading startup:', error)
          this.$store.commit('SET_CURRENT_STARTUP', null)
          this.$router.push({ name: 'startups_selector' })
        })
    } else {
      this.$router.push({ name: 'startups_selector' })
    }
  },
  
  methods: {
    ...mapActions({
      findAlumniConnections: 'FETCH_INVESTOR_MATCHES'
    }),
    
    async handleRefresh() {
      if (!this.startupId) return;
      
      // Directly make API call based on the selected tab without checking status
      if (this.connectionType === 'coinvestors') {
        await this.findAlumniConnections(this.startupId);
      } else {
        await this.fetchAllInvestorMatches();
      }
    },
    
    async handleFindMatches() {
      if (!this.startupId) return;
      
      try {
        // Trigger the matching process for coinvestors
        const response = await this.$store.dispatch('TRIGGER_COINVESTOR_MATCHING', this.startupId);
        
        if (response.status === 'success') {
          // Show success message
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Coinvestor matching process started. This may take a few minutes.',
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true
          });
          
          // Start polling for results
          this.startPolling();
        }
      } catch (error) {
        console.error('Error triggering coinvestor matching:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error?.message || 'Failed to start coinvestor matching process',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true
        });
      }
    },
    
    async handleFindAllInvestorsMatches() {
      if (!this.startupId) return;
      
      try {
        // Show loading state
        this.$store.commit('SET_FETCHING_INVESTOR_MATCHES', true);
        
        // Call the API to find connections to all investors
        // Use the new action we created in the store
        const response = await this.$store.dispatch('TRIGGER_ALL_INVESTOR_MATCHING', this.startupId);
        
        if (response.status === 'success') {
          // Show success message
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Finding connections to all investors started. This may take several minutes.',
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true
          });
          
          // Start polling for results similar to coinvestors
          this.pollAllInvestorsMatching();
        }
      } catch (error) {
        console.error('Error finding all investor matches:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error?.message || 'Failed to find connections to all investors',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true
        });
      } finally {
        // Hide loading state
        this.$store.commit('SET_FETCHING_INVESTOR_MATCHES', false);
      }
    },
    
    pollAllInvestorsMatching() {
      // Poll every 5 seconds until the matching is complete
      this.pollAllInvestorsInterval = setInterval(async () => {
        if (this.startup?.all_investor_matching_status === 'COMPLETED' || 
            this.startup?.all_investor_matching_status === 'FAILED' || 
            this.startup?.all_investor_matching_status === 'CANCELLED') {
          clearInterval(this.pollAllInvestorsInterval);
          
          // Fetch the final results if completed successfully
          if (this.startup?.all_investor_matching_status === 'COMPLETED') {
            await this.fetchAllInvestorMatches();
          }
          return;
        }
        
        // Refresh the startup data to get updated status
        await this.$store.dispatch('LOAD_STARTUP', this.startupId)
          .then((response) => {
            this.startup = response.data;
          });
      }, 5000);
    },
    
    async fetchAllInvestorMatches() {
      if (!this.startupId) return;
      
      try {
        const response = await this.$store.dispatch('FETCH_ALL_INVESTOR_MATCHES', this.startupId);
        this.allInvestorsMatches = response.data || [];
        this.hasAllInvestorsData = true;
      } catch (error) {
        console.error('Error fetching all investor matches:', error);
        this.allInvestorsMatches = [];
      }
    },
    
    startPolling() {
      // Poll every 5 seconds until the matching is complete
      this.pollInterval = setInterval(async () => {
        if (this.isMatchingCompleted || this.isMatchingFailed || this.isMatchingCancelled) {
          clearInterval(this.pollInterval)
          // Fetch the final results
          await this.findAlumniConnections(this.startupId)
          return
        }
        
        // Refresh the startup data to get updated status
        await this.$store.dispatch('LOAD_STARTUP', this.startupId)
          .then((response) => {
            this.startup = response.data
          })
      }, 5000)
    },
    
    beforeUnmount() {
      if (this.pollInterval) {
        clearInterval(this.pollInterval)
      }
      if (this.pollAllInvestorsInterval) {
        clearInterval(this.pollAllInvestorsInterval)
      }
    },
    
    handlePageChange(page) {
      this.currentPage = page
    },
    
    handleItemsPerPageChange() {
      this.currentPage = 1
    },
    
    formatDate(timestamp) {
      if (!timestamp) return '';
      const date = new Date(timestamp);
      return date.toLocaleString();
    },
    
    getFilteredInvestorConnectionGroups(matchDetails) {
      if (!matchDetails || !matchDetails.education_matches || !matchDetails.experience_matches) return [];
      
      let educationMatches = matchDetails.education_matches || [];
      let experienceMatches = matchDetails.experience_matches || [];
      
      if (this.sameYearOnly) {
        educationMatches = this.getSameYearEducationMatches(matchDetails) || [];
        experienceMatches = this.getSameYearExperienceMatches(matchDetails) || [];
      }

      if (this.activeTab === 'education') {
        experienceMatches = [];
      } else if (this.activeTab === 'work') {
        educationMatches = [];
      }
      
      const groupMap = new Map();
      
      educationMatches.forEach(edu => {
        const key = `${edu.investor_id}-${edu.other_investor_id}`;
        if (!groupMap.has(key)) {
          groupMap.set(key, {
            investor_id: edu.investor_id,
            investor_name: edu.investor_name,
            other_investor_id: edu.other_investor_id,
            other_investor_name: edu.other_investor_name,
            education_matches: [],
            work_matches: []
          });
        }
        groupMap.get(key).education_matches.push(edu);
      });
      
      experienceMatches.forEach(exp => {
        const key = `${exp.investor_id}-${exp.other_investor_id}`;
        if (!groupMap.has(key)) {
          groupMap.set(key, {
            investor_id: exp.investor_id,
            investor_name: exp.investor_name,
            other_investor_id: exp.other_investor_id,
            other_investor_name: exp.other_investor_name,
            education_matches: [],
            work_matches: []
          });
        }
        groupMap.get(key).work_matches.push(exp);
      });
      
      return Array.from(groupMap.values()).filter(group => 
        (group.education_matches.length > 0 && (this.activeTab === 'all' || this.activeTab === 'education')) ||
        (group.work_matches.length > 0 && (this.activeTab === 'all' || this.activeTab === 'work'))
      );
    },
    
    getTotalUniqueConnectionCount(matchDetails) {
      if (!matchDetails) return 0;
      
      const groups = this.getFilteredInvestorConnectionGroups(matchDetails);
      return Array.isArray(groups) ? groups.length : 0;
    },
    
    getTotalWarmIntroductions(matches = null) {
      const matchesToUse = matches || this.currentFilteredMatches;
      let total = 0;
      
      if (matchesToUse && matchesToUse.length > 0) {
        matchesToUse.forEach(match => {
          if (match.warm_introductions && Array.isArray(match.warm_introductions)) {
            total += match.warm_introductions.length;
          }
        });
      }
      
      return total;
    },
    
    hasAnySameYearMatch(group) {
      const hasSameYearEducation = group.education_matches.some(m => m.is_same_year);
      const hasSameYearWork = group.work_matches.some(m => m.is_same_year);
      return hasSameYearEducation || hasSameYearWork;
    }
  }
}
</script>

<style scoped>
/* Intro opportunity styles */
.intro-opportunities {
  margin-top: 15px;
}

.intro-opportunity {
  border: 1px solid #eee;
  border-radius: 8px;
  padding: 15px;
  background-color: #f9f9f9;
  transition: all 0.2s ease;
}

.intro-opportunity:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  border-color: #dee2e6;
}

.match-group {
  background-color: white;
  border-radius: 6px;
  padding: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.match-item {
  padding: 8px;
  margin-bottom: 8px;
  border-bottom: 1px solid #f0f0f0;
}

.match-item:last-child {
  border-bottom: none;
  margin-bottom: 0;
}

/* Investor connection styles */
.investor-connection {
  position: relative;
}

.referring-investor {
  border-left: 3px solid #0366d6;
  padding-left: 10px;
}

.connection-details {
  background: #f8faff;
  border-radius: 6px;
  padding: 16px;
  margin-top: 16px;
}

.connection-basis {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.basis-tag {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 6px;
  font-size: 13px;
  max-width: 600px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.basis-tag.education {
  background: #eef2ff;
  color: #556ee6;
}

.basis-tag.work {
  background: #e8f8f3;
  color: #38d997;
}

.basis-tag strong {
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.basis-tag span {
  white-space: nowrap;
}

.timing-info {
  display: flex;
  gap: 12px;
}

.timing-tag {
  display: inline-flex;
  align-items: center;
  gap: 6px;
  padding: 6px 12px;
  border-radius: 6px;
  font-size: 12px;
}

.timing-tag.same-year {
  background: #e8f8f3;
  color: #38d997;
}

.timing-tag.overlap {
  background: #f8f9fa;
  color: #6c757d;
}

.investor-name-link {
  color: #2c3e50;
  text-decoration: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.2;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  transition: all 0.2s ease;
}

.investor-name-link:hover {
  color: #556ee6;
  text-decoration: none;
}

.investor-name-link i {
  font-size: 12px;
  opacity: 0;
  transform: translateX(-4px);
  transition: all 0.2s ease;
}

.investor-name-link:hover i {
  opacity: 1;
  transform: translateX(0);
}

.nav-pills {
  gap: 0.5rem;
  flex-wrap: wrap;
}

.nav-pills .nav-link {
  padding: 0.5rem 1rem;
  border-radius: 20px;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  color: #495057;
  transition: all 0.2s ease;
}

.nav-pills .nav-link:hover {
  background-color: #e9ecef;
  border-color: #ced4da;
}

.nav-pills .nav-link.active {
  background-color: #556ee6;
  border-color: #556ee6;
  color: white;
}

.nav-pills .nav-link .badge {
  font-size: 0.75rem;
  padding: 0.25em 0.5em;
}

.nav-pills .nav-link.active .badge {
  background-color: white !important;
  color: #556ee6;
}

.alert i {
  font-size: 1.1em;
  vertical-align: middle;
}

.alert-danger i {
  color: #dc3545;
}

.alert-warning i {
  color: #ffc107;
}

.connection-card {
  background: #ffffff;
  border: 1px solid #e5e9f2;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 16px;
  transition: all 0.2s ease;
}

.connection-card:hover {
  border-color: #556ee6;
  box-shadow: 0 2px 8px rgba(85, 110, 230, 0.08);
}

.connection-header {
  margin-bottom: 20px;
}

.connection-path {
  display: flex;
  align-items: center;
  gap: 16px;
}

.investor-pill {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  background: #f8faff;
  border: 1px solid #edf2f9;
  border-radius: 100px;
  min-width: 200px;
  transition: all 0.2s ease;
}

.investor-pill:hover {
  border-color: #556ee6;
  background: #f0f3ff;
}

.investor-pill.target {
  background: #f0fff4;
  border-color: #d1f2d9;
}

.investor-pill.target:hover {
  border-color: #38d997;
  background: #e8fcf2;
}

.investor-pill .avatar {
  width: 32px;
  height: 32px;
  background: linear-gradient(135deg, #556ee6 0%, #7087f4 100%);
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 14px;
  margin-right: 12px;
}

.investor-pill.target .avatar {
  background: linear-gradient(135deg, #38d997 0%, #5ce5af 100%);
}

.investor-info {
  display: flex;
  flex-direction: column;
}

.investor-info .company {
  font-size: 12px;
  color: #6c757d;
  margin-top: 2px;
}

.connection-context {
  flex: 1;
  min-width: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.context-line {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  color: #556ee6;
  font-size: 14px;
}

.action-text {
  font-weight: 500;
  color: #6c757d;
}

.connection-details {
  background: #f8faff;
  border-radius: 8px;
  padding: 16px;
  margin-top: 16px;
  border: 1px solid #edf2f9;
}

.connection-basis {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
}

.basis-tag {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 6px;
  font-size: 13px;
  background: #eef2ff;
  color: #556ee6;
  border: 1px solid rgba(85, 110, 230, 0.1);
}

.basis-tag.work {
  background: #e8f8f3;
  color: #38d997;
  border-color: rgba(56, 217, 151, 0.1);
}

.timing-info {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
}

.timing-tag {
  display: inline-flex;
  align-items: center;
  gap: 6px;
  padding: 6px 12px;
  border-radius: 6px;
  font-size: 12px;
  border: 1px solid;
}

.timing-tag.same-year {
  background: #e8f8f3;
  color: #38d997;
  border-color: rgba(56, 217, 151, 0.1);
}

.timing-tag.overlap {
  background: #f8f9fa;
  color: #6c757d;
  border-color: #e9ecef;
}

.investor-name-link {
  color: #2c3e50;
  text-decoration: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.2;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  transition: all 0.2s ease;
}

.investor-name-link:hover {
  color: #556ee6;
  text-decoration: none;
}

.investor-name-link i {
  font-size: 12px;
  opacity: 0;
  transform: translateX(-4px);
  transition: all 0.2s ease;
}

.investor-name-link:hover i {
  opacity: 1;
  transform: translateX(0);
}

/* Additional styles for tabs */
.nav-tabs {
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 20px;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  padding: 0.75rem 1.25rem;
  color: #6c757d;
  transition: all 0.2s ease;
}

.nav-tabs .nav-link:hover {
  border-color: #e9ecef #e9ecef #dee2e6;
  color: #495057;
}

.nav-tabs .nav-link.active {
  color: #556ee6;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
  font-weight: 500;
}

.cursor-pointer {
  cursor: pointer;
}

.alert-info {
  background-color: #cff4fc;
  border-color: #b6effb;
  color: #055160;
}
</style> 