<template>
  <div class="startup-view">
    <div v-if="isLoading" class="text-center py-5">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div v-else-if="instance">
      <!-- Header Section -->
      <div class="header-section mb-4">
        <div class="row g-4">
          <!-- Company Info -->
          <div class="col-lg-8">
            <div class="d-flex flex-column h-100">
              <div class="d-flex justify-content-between align-items-center mb-3">
                <h2 class="mb-0">{{ instance.name || 'Unnamed Company' }}</h2>
                <div class="d-flex gap-2">
                  <a href="#" class="btn btn-soft-primary" @click.prevent="goEdit">
                    <i class="uil uil-pen me-1"></i>Edit
                  </a>
                  <a href="#" class="btn btn-soft-danger" @click.prevent="goDelete" v-if="user?.is_staff">
                    <i class="uil uil-trash me-1"></i>Delete
                  </a>
                  <div class="dropdown">
                    <button class="btn btn-soft-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown">
                      <i class="uil uil-setting me-1"></i>Actions
                    </button>
                    <ul class="dropdown-menu dropdown-menu-end">
                      <li v-if="user">
                        <a class="dropdown-item" href="#" @click.prevent="goRingfence">
                          <i class="uil uil-bag-slash me-1"></i>Manage Ringfence
                        </a>
                      </li>
                      <li v-if="user">
                        <a class="dropdown-item" href="#" @click.prevent="goCareerMatches">
                          <i class="uil uil-users-alt me-1"></i>Career Matches
                        </a>
                      </li>
                      <li v-if="user">
                        <a class="dropdown-item" href="#" @click.prevent="goWarmIntroductions">
                          <i class="uil uil-link me-1"></i>Warm Introductions
                        </a>
                      </li>
                      <li v-if="user">
                        <a class="dropdown-item" href="#" @click.prevent="goCoinvestorMatches">
                          <i class="uil uil-users-alt me-1"></i>Coinvestor Matches
                        </a>
                      </li>
                      <li v-if="user?.can_generate_openers">
                        <a class="dropdown-item" href="#" @click.prevent="goEmailIntro">
                          <i class="uil uil-envelope me-1"></i>Generate Email Intro
                        </a>
                      </li>
                      <li v-if="user">
                        <a class="dropdown-item" href="#" @click.prevent="goSwitch">
                          <i class="uil uil-exchange me-1"></i>Switch Company
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="text-muted mb-3">
                <a v-if="instance.website" :href="instance.website" target="_blank" class="text-primary">
                  <i class="uil uil-globe me-1"></i>{{ instance.website }}
                </a>
              </div>
              <div class="d-flex flex-column gap-3">
                <div>
                  <label class="text-muted small mb-1">Description</label>
                  <div class="fw-medium">
                    <template v-if="instance.description">
                      {{ instance.description }}
                    </template>
                    <span v-else>—</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <label class="text-muted small mb-1">Current Investment Stage</label>
                    <div class="d-flex flex-wrap gap-2">
                      <span v-for="stage in instance.current_investment_stage" :key="stage.id" 
                            class="badge bg-soft-primary text-primary">
                        {{ stage.name }}
                      </span>
                      <span v-if="!instance.current_investment_stage?.length">—</span>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label class="text-muted small mb-1">Target Raise</label>
                    <div class="fw-medium">{{ instance.target_raise ? '$' + $filters.money(instance.target_raise) : '—' }}</div>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-md-6">
                    <label class="text-muted small mb-1">Looking For</label>
                    <div class="d-flex flex-wrap gap-2">
                      <span v-for="type in instance.preferred_investor_type" :key="type.id" 
                            class="badge bg-soft-info text-info">
                        {{ type.name }}
                      </span>
                      <span v-if="!instance.preferred_investor_type?.length">—</span>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label class="text-muted small mb-1">Current MRR</label>
                    <div class="fw-medium">{{ instance.current_MRR ? '$' + $filters.money(instance.current_MRR) : '—' }}</div>
                  </div>
                </div>
                <div>
                  <label class="text-muted small mb-1">Company Location</label>
                  <div class="fw-medium">
                    <template v-if="instance.company_location_city?.length">
                      <div v-for="item in instance.company_location_city" :key="item">
                        {{ item.name }}
                      </div>
                    </template>
                    <span v-else>—</span>
                  </div>
                </div>
                <div>
                  <label class="text-muted small mb-1">Industry & Sectors</label>
                  <div class="d-flex flex-wrap gap-2">
                    <span v-for="tag in instance.industry_sector_tags" :key="tag" 
                          class="badge bg-soft-primary text-primary">
                      {{ tag }}
                    </span>
                    <span v-if="!instance.industry_sector_tags?.length">—</span>
                  </div>
                </div>
                <div>
                  <label class="text-muted small mb-1">Expansion Focus</label>
                  <div class="d-flex flex-wrap gap-2">
                    <span v-for="country in instance.expansion_countries" :key="country" 
                          class="badge bg-soft-success text-success d-flex align-items-center country-badge">
                      <country-flag :country='country.code' size='small' />
                      {{ country.name }}
                    </span>
                    <span v-if="!instance.expansion_countries?.length">—</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Quick Actions Card -->
          <div class="col-lg-4 mt-4">
            <div class="card h-100">
              <div class="card-body">
                <h5 class="card-title mb-3">Quick Actions</h5>
                <div class="d-flex flex-column gap-2">
                  <button v-if="user.can_search" class="btn btn-primary w-100" @click.prevent="goMatch">
                    <i class="uil uil-adjust-circle me-1"></i>Match Investors
                  </button>
                  <button v-if="user.can_generate_openers" class="btn btn-info w-100" @click.prevent="generateCareerOpenerOneLiner">
                    <i class="uil uil-comment-alt-info me-1"></i>
                    <span v-if="siteCompany.is_whitelabel">Identify Career Matches</span>
                    <span v-else>Create Career Opener One Liner</span>
                  </button>
                  <button v-if="user.can_generate_match_score" class="btn btn-secondary w-100" @click.prevent="goGenerateMatchScore(false)">
                    <i class="uil uil-abacus me-1"></i>Calculate Match Score
                  </button>
                  <button v-if="user" class="btn btn-info w-100" @click.prevent="goCoinvestorMatches">
                    <i class="uil uil-users-alt me-1"></i>Alumni Connection with Investors
                  </button>
                  <button v-if="user && user.can_generate_openers" class="btn btn-primary w-100" @click.prevent="goEmailIntro">
                    <i class="uil uil-envelope me-1"></i>Generate Email Intro
                  </button>
                  <hr class="my-2">
                  <div class="d-flex flex-column gap-2">
                    <a v-if="instance.current_cap_table" :href="instance.current_cap_table" target="_blank" class="btn btn-soft-info w-100">
                      <i class="uil uil-file-alt me-1"></i>Cap Table
                    </a>
                    <a v-if="instance.pitch_deck_url || instance.pitch_deck_file" 
                       :href="instance.pitch_deck_url || instance.pitch_deck_file" 
                       target="_blank" 
                       class="btn btn-soft-info w-100">
                      <i class="uil uil-presentation-play me-1"></i>Pitch Deck
                    </a>
                    <a v-if="instance.executive_summary_url || instance.executive_summary_file" 
                       :href="instance.executive_summary_url || instance.executive_summary_file" 
                       target="_blank" 
                       class="btn btn-soft-info w-100">
                      <i class="uil uil-document-layout-center me-1"></i>Executive Summary
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-12">
          <!-- Company Overview and Tabs -->
          <div class="card mb-4 mt-4">
            <div class="card-body p-0">

              <!-- Tabs Navigation -->
              <ul class="nav nav-tabs nav-bordered px-4 pt-2">
                <li class="nav-item">
                  <a class="nav-link active" data-bs-toggle="tab" href="#overview-tab">
                    <i class="uil uil-chart-bar me-1"></i>Overview
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" data-bs-toggle="tab" href="#additional-info-tab">
                    <i class="uil uil-info-circle me-1"></i>Additional Info
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" data-bs-toggle="tab" href="#team-history-tab">
                    <i class="uil uil-history me-1"></i>Team History
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" data-bs-toggle="tab" href="#market-clients-tab">
                    <i class="uil uil-store me-1"></i>Market & Clients
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" data-bs-toggle="tab" href="#funding-tab">
                    <i class="uil uil-money-stack me-1"></i>Funding
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" data-bs-toggle="tab" href="#team-tab">
                    <i class="uil uil-users-alt me-1"></i>Team
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" data-bs-toggle="tab" href="#coinvestors-tab">
                    <i class="uil uil-users-alt me-1"></i>Coinvestors
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" data-bs-toggle="tab" href="#cap-table-investors-tab">
                    <i class="uil uil-chart-pie me-1"></i>Cap Table Investors
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" data-bs-toggle="tab" href="#product-tab">
                    <i class="uil uil-cube me-1"></i>Product
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" data-bs-toggle="tab" href="#metrics-tab">
                    <i class="uil uil-chart-growth me-1"></i>Metrics
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" data-bs-toggle="tab" href="#expansion-tab">
                    <i class="uil uil-globe me-1"></i>Expansion
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" data-bs-toggle="tab" href="#country-oneliners-tab">
                    <i class="uil uil-comment-alt-notes me-1"></i>Country One-Liners
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" data-bs-toggle="tab" href="#email-drafts-tab">
                    <i class="uil uil-envelope me-1"></i>Email Drafts
                  </a>
                </li>
              </ul>

              <!-- Tab Content -->
              <div class="tab-content p-4">
                <!-- Overview Tab -->
                <div class="tab-pane fade show active" id="overview-tab">
                  <div class="row g-4">
                    <div class="col-md-6">
                      <div class="border rounded p-3 h-100">
                        <h6 class="card-subtitle mb-3">Key Metrics</h6>
                        <div class="d-flex flex-column gap-3">
                          <div>
                            <label class="text-muted small">Current MRR</label>
                            <h5 class="mb-0">{{ instance.current_MRR ? '$' + $filters.money(instance.current_MRR) : '—' }}</h5>
                          </div>
                          <div>
                            <label class="text-muted small">Active Users</label>
                            <h5 class="mb-0">{{ instance.active_users || '—' }}</h5>
                          </div>
                          <div>
                            <label class="text-muted small">Employee Count</label>
                            <h5 class="mb-0">{{ instance.employee_number || '—' }}</h5>
                          </div>
                          <div>
                            <label class="text-muted small">Current ARR</label>
                            <h5 class="mb-0">{{ instance.current_ARR ? '$' + $filters.money(instance.current_ARR) : '—' }}</h5>
                          </div>
                          <div>
                            <label class="text-muted small">EBITDA</label>
                            <h5 class="mb-0">{{ instance.ebitda ? '$' + $filters.money(instance.ebitda) : '—' }}</h5>
                          </div>
                          <div>
                            <label class="text-muted small">Projected Revenue</label>
                            <h5 class="mb-0">{{ instance.projected_revenue ? '$' + $filters.money(instance.projected_revenue) : '—' }}</h5>
                          </div>
                          <div>
                            <label class="text-muted small">Lifetime Revenue</label>
                            <h5 class="mb-0">{{ instance.lifetime_revenue ? '$' + $filters.money(instance.lifetime_revenue) : '—' }}</h5>
                          </div>
                          <div>
                            <label class="text-muted small">Letters of Intent</label>
                            <h5 class="mb-0">{{ instance.letters_of_intent_number || '—' }}</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="border rounded p-3 h-100">
                        <h6 class="card-subtitle mb-3">Current Round</h6>
                        <div class="d-flex flex-column gap-3">
                          <div>
                            <label class="text-muted small">Target Raise</label>
                            <h5 class="mb-0">{{ instance.target_raise ? '$' + $filters.money(instance.target_raise) : '—' }}</h5>
                          </div>
                          <div>
                            <label class="text-muted small">Current Valuation</label>
                            <h5 class="mb-0">{{ instance.current_valuation ? '$' + $filters.money(instance.current_valuation) : '—' }}</h5>
                          </div>
                          <div>
                            <label class="text-muted small">Raised So Far</label>
                            <h5 class="mb-0">{{ instance.raised_so_far ? '$' + $filters.money(instance.raised_so_far) : '—' }}</h5>
                          </div>
                          <div>
                            <label class="text-muted small">Pre-Money Valuation</label>
                            <h5 class="mb-0">{{ instance.pre_money_valuation ? '$' + $filters.money(instance.pre_money_valuation) : '—' }}</h5>
                          </div>
                          <div>
                            <label class="text-muted small">Funding Close Date</label>
                            <h5 class="mb-0">{{ instance.funding_close_date || '—' }}</h5>
                          </div>
                          <div>
                            <label class="text-muted small">Sales Valuation Multiple</label>
                            <h5 class="mb-0">{{ instance.sales_valuation_multiple || '—' }}</h5>
                          </div>
                          <div>
                            <label class="text-muted small">Current Round Raised</label>
                            <h5 class="mb-0">{{ instance.current_round_raised ? '$' + $filters.money(instance.current_round_raised) : '—' }}</h5>
                          </div>
                          <div>
                            <label class="text-muted small">Ticket Size From-To</label>
                            <h5 class="mb-0">
                              {{ instance.ticket_size_from ? '$' + $filters.money(instance.ticket_size_from) : '—' }} - 
                              {{ instance.ticket_size_to ? '$' + $filters.money(instance.ticket_size_to) : '—' }}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="border rounded p-3">
                        <h6 class="card-subtitle mb-3">One-Liner Description</h6>
                        <p class="mb-0">{{ instance.common_one_liner || '—' }}</p>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="border rounded p-3">
                        <h6 class="card-subtitle mb-3">Key Selling Points</h6>
                        <p class="mb-0">{{ instance.key_selling_points || '—' }}</p>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="border rounded p-3">
                        <h6 class="card-subtitle mb-3">Investment Opportunity</h6>
                        <p class="mb-0">{{ instance.investment_opportunity || '—' }}</p>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="border rounded p-3">
                        <h6 class="card-subtitle mb-3">Revenue Generating Streams</h6>
                        <p class="mb-0">{{ instance.revenue_generating_streams || '—' }}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Additional Info Tab -->
                <div class="tab-pane fade" id="additional-info-tab">
                  <div class="row g-4">
                    <div class="col-md-6">
                      <div class="border rounded p-3">
                        <h6 class="card-subtitle mb-3">Company Status</h6>
                        <div class="d-flex flex-column gap-3">
                          <div>
                            <label class="text-muted small">Revenue Generating</label>
                            <h5 class="mb-0">{{ instance.revenue_generating === 1 ? 'Yes' : instance.revenue_generating === 0 ? 'No' : '—' }}</h5>
                          </div>
                          <div>
                            <label class="text-muted small">Profitable</label>
                            <h5 class="mb-0">{{ instance.profitable === 1 ? 'Yes' : instance.profitable === 0 ? 'No' : '—' }}</h5>
                          </div>
                          <div>
                            <label class="text-muted small">Current Lead Investor</label>
                            <h5 class="mb-0">{{ instance.current_lead_investor === 1 ? 'Yes' : instance.current_lead_investor === 0 ? 'No' : '—' }}</h5>
                          </div>
                          <div>
                            <label class="text-muted small">Lead Investor Name</label>
                            <h5 class="mb-0">{{ instance.lead_investor_name || '—' }}</h5>
                          </div>
                          <div>
                            <label class="text-muted small">MVP Ready</label>
                            <h5 class="mb-0">{{ instance.mvp_ready === 1 ? 'Yes' : instance.mvp_ready === 0 ? 'No' : '—' }}</h5>
                          </div>
                          <div>
                            <label class="text-muted small">Product Fully Built</label>
                            <h5 class="mb-0">{{ instance.product_ready === 1 ? 'Yes' : instance.product_ready === 0 ? 'No' : '—' }}</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="border rounded p-3">
                        <h6 class="card-subtitle mb-3">Contact Information</h6>
                        <div class="d-flex flex-column gap-3">
                          <div>
                            <label class="text-muted small">Primary Contact</label>
                            <h5 class="mb-0">{{ instance.first_name }} {{ instance.last_name }}</h5>
                          </div>
                          <div>
                            <label class="text-muted small">Title</label>
                            <h5 class="mb-0">{{ instance.title || '—' }}</h5>
                          </div>
                          <div>
                            <label class="text-muted small">Email</label>
                            <h5 class="mb-0">{{ instance.email_address || '—' }}</h5>
                          </div>
                          <div>
                            <label class="text-muted small">Phone</label>
                            <h5 class="mb-0">{{ instance.phone || '—' }}</h5>
                          </div>
                          <div>
                            <label class="text-muted small">Astel Shared Email</label>
                            <h5 class="mb-0">{{ instance.astel_shared_email || '—' }}</h5>
                          </div>
                          <div>
                            <label class="text-muted small">Cofounder Email</label>
                            <h5 class="mb-0">{{ instance.cofounder_email || '—' }}</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="border rounded p-3">
                        <h6 class="card-subtitle mb-3">Smart Money & Strategy</h6>
                        <div class="d-flex flex-column gap-3">
                          <div>
                            <label class="text-muted small">Smart Money Tags</label>
                            <div class="d-flex flex-wrap gap-2">
                              <span v-if="instance.smart_money_tags" class="badge bg-soft-primary text-primary">
                                {{ instance.smart_money_tags }}
                              </span>
                              <span v-else>—</span>
                            </div>
                          </div>
                          <div>
                            <label class="text-muted small">Exit Strategy</label>
                            <h5 class="mb-0">{{ instance.exit_strategy || '—' }}</h5>
                          </div>
                          <div>
                            <label class="text-muted small">Runway Duration</label>
                            <h5 class="mb-0">{{ instance.runway_duration || '—' }}</h5>
                          </div>
                          <div>
                            <label class="text-muted small">Time to Breakeven</label>
                            <h5 class="mb-0">{{ instance.time_to_breakeven || '—' }}</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="border rounded p-3">
                        <h6 class="card-subtitle mb-3">Awards & Recognition</h6>
                        <p class="mb-0">{{ instance.awards || '—' }}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Team History Tab -->
                <div class="tab-pane fade" id="team-history-tab">
                  <div class="row g-4">
                    <div class="col-12">
                      <div class="border rounded p-3">
                        <h6 class="card-subtitle mb-3">Leadership Background</h6>
                        <div class="d-flex flex-column gap-3">
                          <div>
                            <label class="text-muted small">Founders Prior Exits</label>
                            <p class="mb-0">{{ instance.c_level_exits || '—' }}</p>
                          </div>
                          <div>
                            <label class="text-muted small">Prior Team Collaboration</label>
                            <p class="mb-0">{{ instance.c_level_work || '—' }}</p>
                          </div>
                          <div>
                            <label class="text-muted small">Board Members Traction</label>
                            <p class="mb-0">{{ instance.board_members_traction || '—' }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Market & Clients Tab -->
                <div class="tab-pane fade" id="market-clients-tab">
                  <div class="row g-4">
                    <div class="col-12">
                      <div class="border rounded p-3">
                        <h6 class="card-subtitle mb-3">Client Information</h6>
                        <div class="d-flex flex-column gap-3">
                          <div>
                            <label class="text-muted small">Major Institutional Clients</label>
                            <p class="mb-0">{{ instance.big_clients || '—' }}</p>
                          </div>
                          <div>
                            <label class="text-muted small">Pipeline of Institutional Clients</label>
                            <p class="mb-0">{{ instance.pipeline_big_clients || '—' }}</p>
                          </div>
                          <div>
                            <label class="text-muted small">Other Traction Points</label>
                            <p class="mb-0">{{ instance.other_traction_points || '—' }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Funding Tab -->
                <div class="tab-pane fade" id="funding-tab">
                  <div class="row g-4">
                    <div class="col-md-6 col-lg-4">
                      <div class="border rounded p-3">
                        <label class="text-muted small">Target Raise</label>
                        <h5 class="mb-0">{{ instance.target_raise ? '$' + $filters.money(instance.target_raise) : '—' }}</h5>
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-4">
                      <div class="border rounded p-3">
                        <label class="text-muted small">Current Valuation</label>
                        <h5 class="mb-0">{{ instance.current_valuation ? '$' + $filters.money(instance.current_valuation) : '—' }}</h5>
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-4">
                      <div class="border rounded p-3">
                        <label class="text-muted small">Minimum Ticket Size</label>
                        <h5 class="mb-0">{{ instance.minimum_ticket_size ? '$' + $filters.money(instance.minimum_ticket_size) : '—' }}</h5>
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-4">
                      <div class="border rounded p-3">
                        <label class="text-muted small">Raised So Far</label>
                        <h5 class="mb-0">{{ instance.raised_so_far ? '$' + $filters.money(instance.raised_so_far) : '—' }}</h5>
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-4">
                      <div class="border rounded p-3">
                        <label class="text-muted small">Total Funding to Date</label>
                        <h5 class="mb-0">{{ instance.total_funding_to_date ? '$' + $filters.money(instance.total_funding_to_date) : '—' }}</h5>
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-4">
                      <div class="border rounded p-3">
                        <label class="text-muted small">Last Funding Valuation</label>
                        <h5 class="mb-0">{{ instance.last_funding_valuation ? '$' + $filters.money(instance.last_funding_valuation) : '—' }}</h5>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="border rounded p-3">
                        <label class="text-muted small">Current Investment Stage</label>
                        <div class="d-flex flex-wrap gap-2 mt-2">
                          <span v-for="stage in instance.current_investment_stage" :key="stage.id" 
                                class="badge bg-soft-primary text-primary">
                            {{ stage.name }}
                          </span>
                          <span v-if="!instance.current_investment_stage?.length">—</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="border rounded p-3">
                        <label class="text-muted small">Preferred Investor Types</label>
                        <div class="d-flex flex-wrap gap-2 mt-2">
                          <span v-for="type in instance.preferred_investor_type" :key="type.id" 
                                class="badge bg-soft-primary text-primary">
                            {{ type.name }}
                          </span>
                          <span v-if="!instance.preferred_investor_type?.length">—</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="border rounded p-3">
                        <label class="text-muted small">Current Investor Types</label>
                        <div class="d-flex flex-wrap gap-2 mt-2">
                          <span v-for="type in instance.current_investor_type" :key="type.id" 
                                class="badge bg-soft-primary text-primary">
                            {{ type.name }}
                          </span>
                          <span v-if="!instance.current_investor_type?.length">—</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="border rounded p-3">
                        <label class="text-muted small">Use of Proceeds</label>
                        <p class="mb-0 mt-2">{{ instance.use_of_proceed || '—' }}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Team Tab -->
                <div class="tab-pane fade" id="team-tab">
                  <div class="row g-4">
                    <div class="col-12">
                      <div v-if="!instance.team || !instance.team.length" class="border rounded p-3 mb-2">
                        <h5 class="card-subtitle mb-3">How to add team members</h5>
                        <div class="border rounded p-3 border-danger mb-2 w-75" v-if="!showTeamMembers">
                          <p class="mb-0 text-danger">
                            Please watch this video before proceeding to add the team members
                          </p>
                        </div>
                        <video id="team-members-video" src="/static/team-members-video.mp4" controls class="w-75 border rounded"></video>
                      </div>
                      <div class="border rounded p-3" v-if="showTeamMembers">
                        <div class="d-flex justify-content-between align-items-center mb-4">
                          <h6 class="card-subtitle mb-0">Team Members</h6>
                          <router-link :to="{ name: 'contact_new_startup', params: { startup_id: instance.id }}" 
                                     class="btn btn-soft-primary btn-sm">
                            <i class="uil uil-plus me-1"></i>Add Member
                          </router-link>
                        </div>
                        <div class="table-responsive">
                          <table class="table table-centered mb-0" v-if="instance.team && instance.team.length">
                            <thead class="table-light">
                              <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="member in instance.team" :key="member.id">
                                <td>{{ member.first_name }} {{ member.last_name }}</td>
                                <td>{{ member.email }}</td>
                                <td>
                                  <router-link :to="{ name: 'contact_detail', params: { id: member.id }}" 
                                             class="btn btn-soft-info btn-sm">
                                    View Profile
                                  </router-link>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div v-else class="text-center py-4 text-muted">
                            No team members added yet
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="border rounded p-3">
                        <label class="text-muted small">Employee Headcount</label>
                        <h5 class="mb-0">{{ instance.employee_number || '—' }}</h5>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="border rounded p-3">
                        <label class="text-muted small">Employee Growth Rate</label>
                        <h5 class="mb-0">{{ instance.total_employee_growth_rate }}%</h5>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="border rounded p-3">
                        <label class="text-muted small">Founders Bio</label>
                        <p class="mb-0 mt-2">{{ instance.founders_bio || '—' }}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Coinvestors Tab -->
                <div class="tab-pane fade" id="coinvestors-tab">
                  <div class="row g-4">
                    <div class="col-12">
                      <div class="border rounded p-3">
                        <div class="d-flex justify-content-between align-items-center mb-4">
                          <h6 class="card-subtitle mb-0">Coinvestors</h6>
                          <button class="btn btn-soft-primary btn-sm" @click.prevent="addCoinvestor">
                            <i class="uil uil-plus me-1"></i>Add Coinvestor
                          </button>
                        </div>
                        <div class="table-responsive">
                          <div v-if="is_fetching_coinvestor" class="text-center py-4">
                            <div class="spinner-border text-primary" role="status">
                              <span class="visually-hidden">Loading coinvestors...</span>
                            </div>
                          </div>
                          <table class="table table-centered mb-0" v-else-if="instance.coinvestor_set && instance.coinvestor_set.length">
                            <thead class="table-light">
                              <tr>
                                <th>Company</th>
                                <th>Coinvested With</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="coinvestor in instance.coinvestor_set" :key="coinvestor.id">
                                <td>
                                  <router-link v-if="coinvestor.company" :to="{ path: `/investors/companies/${coinvestor.company}` }" target="_blank" class="investor-name-link">
                                    {{ coinvestor.name }}
                                    <i class="uil uil-external-link-alt"></i>
                                  </router-link>
                                  <span v-else>{{ coinvestor.name }}</span>
                                </td>
                                <td>{{ coinvestor.coinvested_with || '—' }}</td>
                              </tr>
                            </tbody>
                          </table>
                          <div v-else class="text-center py-4 text-muted">
                            No coinvestors added yet
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Cap Table Investors Tab -->
                <div class="tab-pane fade" id="cap-table-investors-tab">
                  <div class="row g-4">
                    <div class="col-12">
                      <div class="border rounded p-3">
                        <h6 class="card-subtitle mb-3">Cap Table Investors</h6>
                        <div class="table-responsive">
                          <table class="table table-centered mb-0" v-if="instance.cap_table_investors && instance.cap_table_investors.length">
                            <thead class="table-light">
                              <tr>
                                <th>Company</th>
                                <th>Website</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="investor in instance.cap_table_investors" :key="investor.id">
                                <td>
                                  <router-link v-if="investor.company" :to="{ path: `/investors/companies/${investor.company.id}` }" target="_blank" class="investor-name-link">
                                    {{ investor.company.name }}
                                    <i class="uil uil-external-link-alt"></i>
                                  </router-link>
                                  <span v-else>—</span>
                                </td>
                                <td>
                                  <a v-if="investor.website" :href="investor.website" target="_blank" class="text-primary">
                                    <i class="uil uil-external-link-alt me-1"></i>{{ investor.website }}
                                  </a>
                                  <span v-else>—</span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div v-else class="text-center py-4 text-muted">
                            No cap table investors found
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Product Tab -->
                <div class="tab-pane fade" id="product-tab">
                  <div class="row g-4">
                    <div class="col-12">
                      <div class="border rounded p-3">
                        <label class="text-muted small">Key Features</label>
                        <p class="mb-0 mt-2">{{ instance.key_features || '—' }}</p>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="border rounded p-3">
                        <label class="text-muted small">Competitive Advantages</label>
                        <p class="mb-0 mt-2">{{ instance.competitive_advantages || '—' }}</p>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="border rounded p-3">
                        <label class="text-muted small">In-House Technology</label>
                        <div class="mt-2">
                          <span class="badge" :class="instance.in_house_technology === 1 ? 'bg-success' : 'bg-danger'">
                            {{ instance.in_house_technology === 1 ? 'Yes' : 'No' }}
                          </span>
                          <p v-if="instance.in_house_technology_other" class="mt-2 mb-0">
                            {{ instance.in_house_technology_other }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="border rounded p-3">
                        <label class="text-muted small">Demo URL</label>
                        <div class="mt-2">
                          <a v-if="instance.demo" :href="instance.demo" target="_blank" class="text-primary">
                            <i class="uil uil-external-link-alt me-1"></i>{{ instance.demo }}
                          </a>
                          <span v-else>—</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Metrics Tab -->
                <div class="tab-pane fade" id="metrics-tab">
                  <div class="row g-4">
                    <div class="col-md-6 col-lg-4">
                      <div class="border rounded p-3">
                        <label class="text-muted small">Current MRR</label>
                        <h5 class="mb-0">{{ instance.current_MRR ? '$' + $filters.money(instance.current_MRR) : '—' }}</h5>
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-4">
                      <div class="border rounded p-3">
                        <label class="text-muted small">Active Users</label>
                        <h5 class="mb-0">{{ instance.active_users || '—' }}</h5>
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-4">
                      <div class="border rounded p-3">
                        <label class="text-muted small">Annual Growth Rate</label>
                        <h5 class="mb-0">{{ instance.annual_growth_rate }}%</h5>
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-4">
                      <div class="border rounded p-3">
                        <label class="text-muted small">Revenue Growth</label>
                        <h5 class="mb-0">{{ instance.revenue_growth }}%</h5>
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-4">
                      <div class="border rounded p-3">
                        <label class="text-muted small">Monthly Burn Rate</label>
                        <h5 class="mb-0">{{ instance.monthly_burn_rate ? '$' + $filters.money(instance.monthly_burn_rate) : '—' }}</h5>
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-4">
                      <div class="border rounded p-3">
                        <label class="text-muted small">Churn Rate</label>
                        <h5 class="mb-0">{{ instance.churn_rate }}%</h5>
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-4">
                      <div class="border rounded p-3">
                        <label class="text-muted small">Customer Acquisition Cost</label>
                        <h5 class="mb-0">{{ instance.customer_acquisition_cost ? '$' + $filters.money(instance.customer_acquisition_cost) : '—' }}</h5>
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-4">
                      <div class="border rounded p-3">
                        <label class="text-muted small">Customer Lifetime Value</label>
                        <h5 class="mb-0">{{ instance.customer_lifetime_value ? '$' + $filters.money(instance.customer_lifetime_value) : '—' }}</h5>
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-4">
                      <div class="border rounded p-3">
                        <label class="text-muted small">LTV/CAC Ratio</label>
                        <h5 class="mb-0">{{ instance.LTV_CAC || '—' }}</h5>
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-4">
                      <div class="border rounded p-3">
                        <label class="text-muted small">Retention Rate</label>
                        <h5 class="mb-0">{{ instance.retention_rate }}%</h5>
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-4">
                      <div class="border rounded p-3">
                        <label class="text-muted small">Gross Profit</label>
                        <h5 class="mb-0">{{ instance.gross_profit ? '$' + $filters.money(instance.gross_profit) : '—' }}</h5>
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-4">
                      <div class="border rounded p-3">
                        <label class="text-muted small">Gross Margin</label>
                        <h5 class="mb-0">{{ instance.gross_margin }}%</h5>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Expansion Tab -->
                <div class="tab-pane fade" id="expansion-tab">
                  <div class="row g-4">
                    <div class="col-12">
                      <div class="border rounded p-3">
                        <label class="text-muted small">Registration Country</label>
                        <div class="d-flex flex-wrap gap-2 mt-2">
                          <span v-for="country in instance.registered_country" :key="country" 
                                class="badge bg-soft-info text-info d-flex align-items-center country-badge">
                            <country-flag :country='country.code' size='small' />
                            {{ country.name }}
                          </span>
                          <span v-if="!instance.registered_country?.length">—</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="border rounded p-3">
                        <label class="text-muted small">Team Locations</label>
                        <div class="d-flex flex-wrap gap-2 mt-2">
                          <span v-for="country in instance.team_location" :key="country" 
                                class="badge bg-soft-info text-info d-flex align-items-center country-badge">
                            <country-flag :country='country.code' size='small' />
                            {{ country.name }}
                          </span>
                          <span v-if="!instance.team_location?.length">—</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="border rounded p-3">
                        <label class="text-muted small">Expansion Countries</label>
                        <div class="d-flex flex-wrap gap-2 mt-2">
                          <span v-for="country in instance.expansion_countries" :key="country" 
                                class="badge bg-soft-success text-success d-flex align-items-center country-badge">
                            <country-flag :country='country.code' size='small' />
                            {{ country.name }}
                          </span>
                          <span v-if="!instance.expansion_countries?.length">—</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="border rounded p-3">
                        <label class="text-muted small">Current Investors Locations</label>
                        <div class="d-flex flex-wrap gap-2 mt-2">
                          <span v-for="country in instance.current_investors_location" :key="country" 
                                class="badge bg-soft-warning text-warning d-flex align-items-center country-badge">
                            <country-flag :country='country.code' size='small' />
                            {{ country.name }}
                          </span>
                          <span v-if="!instance.current_investors_location?.length">—</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="border rounded p-3">
                        <label class="text-muted small">Preferred Investor Locations</label>
                        <div class="d-flex flex-wrap gap-2 mt-2">
                          <span v-for="country in instance.preferred_investor_location" :key="country" 
                                class="badge bg-soft-warning text-warning d-flex align-items-center country-badge">
                            <country-flag :country='country.code' size='small' />
                            {{ country.name }}
                          </span>
                          <span v-if="!instance.preferred_investor_location?.length">—</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="border rounded p-3">
                        <label class="text-muted small">Preferred Investor Regions</label>
                        <div class="d-flex flex-wrap gap-2 mt-2">
                          <span v-for="region in instance.preferred_investor_region" :key="region.id" 
                                class="badge bg-soft-warning text-warning">
                            {{ region.name }}
                          </span>
                          <span v-if="!instance.preferred_investor_region?.length">—</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Country One-Liners Tab -->
                <div class="tab-pane fade" id="country-oneliners-tab">
                  <div class="row g-4">
                    <div class="col-12">
                      <div class="border rounded p-3">
                        <div class="d-flex justify-content-between align-items-center mb-3">
                          <h6 class="card-subtitle mb-0">Country One-Liners</h6>
                          <button class="btn btn-soft-primary btn-sm" @click="showAddCountryOneLinerForm">
                            <i class="uil uil-plus me-1"></i>Add One-Liner
                          </button>
                        </div>

                        <!-- Add Country One-Liner Form -->
                        <div v-if="showCountryOneLinerForm" class="mb-4 p-3 border rounded bg-light">
                          <div class="mb-3">
                            <label class="form-label">Region</label>
                            <select class="form-select" v-model="selectedRegion">
                              <option value="">Select a region</option>
                              <option v-for="region in optionsRegions" :key="region.id" :value="region">
                                {{ region.name }}
                              </option>
                            </select>
                          </div>
                          <div class="mb-3">
                            <label class="form-label">One-Liner</label>
                            <textarea class="form-control" v-model="countryOneLiner" rows="3"></textarea>
                          </div>
                          <div v-if="errors.country_one_liner" class="alert alert-danger">
                            {{ errors.country_one_liner.join(', ') }}
                          </div>
                          <div class="d-flex gap-2">
                            <button class="btn btn-primary" @click="addCountryOneLiner">Add</button>
                            <button class="btn btn-secondary" @click="cancelAddCountryOneLiner">Cancel</button>
                          </div>
                        </div>

                        <!-- Country One-Liners Table -->
                        <div class="table-responsive">
                          <table class="table table-centered mb-0" v-if="instance.country_one_liner && instance.country_one_liner.length">
                            <thead class="table-light">
                              <tr>
                                <th>Region</th>
                                <th>One-Liner</th>
                                <th>Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              <template v-for="(group, region) in groupedCountryOneLiners" :key="region">
                                <tr v-for="(oneLiner, index) in group" :key="index">
                                  <td class="text-primary">{{ getRegionName(oneLiner.region) }}</td>
                                  <td>{{ oneLiner.country_one_liner }}</td>
                                  <td>
                                    <button class="btn btn-soft-danger btn-sm" @click="removeCountryOneLiner(oneLiner)">
                                      <i class="uil uil-trash-alt"></i>
                                    </button>
                                  </td>
                                </tr>
                              </template>
                            </tbody>
                          </table>
                          <div v-else class="text-center py-4 text-muted">
                            No country one-liners added yet
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Email Drafts Tab -->
                <div class="tab-pane fade" id="email-drafts-tab">
                  <div class="row g-4">
                    <div class="col-12">
                      <div class="border rounded p-3">
                        <div class="d-flex justify-content-between align-items-center mb-4">
                          <h6 class="card-subtitle mb-0">Email Drafts</h6>
                          <button class="btn btn-soft-primary btn-sm" @click.prevent="goNewEmailDraft">
                            <i class="uil uil-plus me-1"></i>New Draft
                          </button>
                        </div>
                        <div class="email-drafts-list">
                          <template v-if="instance.previous_email_drafts && instance.previous_email_drafts.length">
                            <div v-for="draft in instance.previous_email_drafts" :key="draft.id"
                                 class="email-draft-item p-3 border rounded mb-2">
                              <div class="d-flex justify-content-between align-items-center">
                                <div class="text-truncate me-3">{{ draft.subject }}</div>
                                <div class="btn-group">
                                  <button class="btn btn-soft-info btn-sm" @click="viewDraft(draft)">
                                    <i class="uil uil-eye"></i>
                                  </button>
                                  <button class="btn btn-soft-warning btn-sm" @click="editDraft(draft)">
                                    <i class="uil uil-edit"></i>
                                  </button>
                                </div>
                              </div>
                              <div class="text-muted small mt-2">{{ draft.content }}</div>
                            </div>
                          </template>
                          <div v-else class="text-center py-4 text-muted">
                            No email drafts available
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
    <div v-else class="text-center py-5">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>

    <!-- Modals -->
    <div class="modal fade" id="newEmailDraftModal" tabindex="-1">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="newEmailDraftModalLabel">New Email Draft</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="mb-3">
              <label for="emailSubject" class="form-label">Subject</label>
              <input type="text" class="form-control" id="emailSubject" v-model="newDraft.subject">
            </div>
            <div class="mb-3">
              <label for="emailContent" class="form-label">Content</label>
              <textarea class="form-control" id="emailContent" rows="6" v-model="newDraft.content"></textarea>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary" @click="saveEmailDraft" :disabled="isSaving">
              <span v-if="isSaving">Saving...</span>
              <span v-else>Save Draft</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="viewEmailDraftModal" tabindex="-1">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="viewEmailDraftModalLabel">View Email Draft</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="mb-3" v-if="selectedDraft">
              <label class="form-label fw-bold">Subject</label>
              <input type="text" class="form-control" v-model="selectedDraft.subject" :disabled="!isEditing">
            </div>
            <div class="mb-3" v-if="selectedDraft">
              <label class="form-label fw-bold">Content</label>
              <textarea class="form-control email-content-view" v-model="selectedDraft.content" :disabled="!isEditing"
                rows="10"></textarea>
            </div>
          </div>
          <div class="modal-footer">
            <template v-if="!isEditing">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button type="button" class="btn btn-warning" @click="isEditing = true">
                <i class="uil uil-edit"></i> Edit
              </button>
            </template>
            <template v-else>
              <button type="button" class="btn btn-secondary" @click="cancelEdit">Cancel</button>
              <button type="button" class="btn btn-primary" @click="updateDraft" :disabled="isSaving">
                <span v-if="isSaving">Updating...</span>
                <span v-else>Update Draft</span>
              </button>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchMixin from '@/mixins/search'
import { mapState } from 'vuex'
import { Modal } from 'bootstrap'
import Swal from 'sweetalert2'
import CheckKeysMixin from '@/mixins/checkKeys'

export default {
  name: 'startup',

  mixins: [SearchMixin, CheckKeysMixin],

  data() {
    return {
      instance: null,
      is_fetching_coinvestor: false,
      isTeamExpanded: false,
      isCoinvestorsExpanded: false,
      newDraft: {
        subject: '',
        content: ''
      },
      isSaving: false,
      emailDraftModal: null,
      selectedDraft: null,
      isEditing: false,
      draftBackup: null,
      selectedRegion: null,
      countryOneLiner: '',
      errors: {},
      showCountryOneLinerForm: false,
      watchedTeamVideo: false,
      isLoading: true
    }
  },

  computed: {
    ...mapState({
      user: state => state.auth.user,
      current: state => state.startups.current,
      siteCompany: state => state.auth.siteCompany,
      optionsRegions: state => state.investors.regions,
    }),
    showTeamMembers() {
      return !this.siteCompany.is_whitelabel || this.watchedTeamVideo || this.instance?.team?.length > 0
    },
    startup() {
      return this.current;
    },
    groupedCountryOneLiners() {
      if (!this.instance?.country_one_liner) return {};
      
      return this.instance.country_one_liner.reduce((acc, item) => {
        const region = item.region?.name || 'Unknown Region';
        if (!acc[region]) {
          acc[region] = [];
        }
        acc[region].push(item);
        return acc;
      }, {});
    }
  },

  async mounted() {
    window.scrollTo(0, 0)
    const startupId = this.$route?.params?.id || (this.current && this.current?.id)
    if (startupId) {
      try {
        const response = await this.$store.dispatch('LOAD_STARTUP', startupId)
        const res = response.data;
        
        if (res.notable_institutional_investors) {
          res.notable_institutional_investors = res.notable_institutional_investors
            .split(',')
            .map(item => item.trim());
        } else {
          res.notable_institutional_investors = '';
        }
        
        this.instance = res;
        
        // Check if country one-liners exist and show popup if not
        this.checkCountryOneLiners();
        
        // Check if email templates are missing and show prompt
        this.checkMissingEmailTemplates();
        
      } catch (error) {
        console.error('Error loading startup:', error)
        this.$store.commit('SET_CURRENT_STARTUP', null)
        this.$router.push({ name: 'startups_selector' })
      } finally {
        this.isLoading = false;
      }
    } else {
      this.$router.push({ name: 'startups_selector' })
    }

    setTimeout(() => {
      // track if team members video is watched - delayed by one second to allow to render
      document.getElementById('team-members-video').addEventListener('ended', () => {
        this.watchedTeamVideo = true;
      });
    }, 1000);
  },

  methods: {
    goEdit() {
      this.$router.push(`/startups/${this.instance.id}/edit`)
    },
    goDelete() {
      this.$router.push(`/startups/${this.instance.id}/delete`)
    },
    goMatch() {
      this.$store.commit('SET_MATCH_FIELDS', null);
      this.$store.commit('SET_MATCH_GROUP', null);
      if (this.siteCompany.is_whitelabel) {
        this.$router.push('/investors/astel?match=true')
      } else {
        this.$router.push('/investors/contacts?match=true')
      }
    },
    goRingfence() {
      this.$router.push(`/startups/${this.instance.id}/ringfence`)
    },
    goCareerMatches() {
      this.$router.push(`/startups/${this.instance.id}/career-matches`)
    },
    goWarmIntroductions() {
      this.$router.push(`/startups/${this.instance.id}/warm-introductions`)
    },
    goCoinvestorMatches() {
      this.$router.push(`/startups/${this.instance.id}/coinvestor-matches`)
    },
    goSwitch() {
      this.$router.push({ name: 'startups_selector' })
    },
    goEmailIntro() {
      this.$router.push(`/startups/${this.instance.id}/emailintro`)
    },
    
    // Check for missing email templates and redirect to email intro page if confirmed
    checkMissingEmailTemplates() {
      // Check if both initial and follow-up email templates are missing
      if (!this.instance.generated_email_intro && !this.instance.generated_follow_up_email) {
        Swal.fire({
          title: "Missing Email Templates",
          text: "You haven't generated initial and follow-up email templates yet. Would you like to create them now?",
          icon: "info",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Yes, create templates",
          cancelButtonColor: "#d33",
          cancelButtonText: "Not now"
        }).then((result) => {
          if (result.isConfirmed) {
            this.goEmailIntro()
          }
        })
      }
    },
    async checkTeamMemberAdditionStatus() {
      try {
        // First get the latest startup details
        const response = await this.$store.dispatch('LOAD_STARTUP', this.instance.id)

        const startup = response.data
        
        // Check if already marked as small team
        if (startup.flags && startup.flags.marked_as_small_team === true) {
          return true
        }

        const teamStats = await this.$store.dispatch('LOAD_TEAM_STATS', this.instance.id)
        
        // Check if minimum requirements are met
        const hasEnoughTeamMembers = teamStats.other_count >= 5
        const hasEnoughAdvisors = teamStats.advisor_count >= 2
        const hasEnoughInvestors = teamStats.investor_count >= 2

        if (!hasEnoughTeamMembers || !hasEnoughAdvisors || !hasEnoughInvestors) {
          const missingRequirements = []
          if (!hasEnoughTeamMembers) {
            missingRequirements.push(`${5 - teamStats.other_count} more team members`)
          }
          if (!hasEnoughAdvisors) {
            missingRequirements.push(`${2 - teamStats.advisor_count} more advisors`)
          }
          if (!hasEnoughInvestors) {
            missingRequirements.push(`${2 - teamStats.investor_count} more investors`)
          }

          const missingText = missingRequirements.join(', ')

          const swalInstance = Swal.fire({
            title: 'Strengthen Your Network Through Your Team',
            html: `
              <div class="text-left">
                <p>We recommend adding ${missingText} to maximize your mutual connection matches. Add key team members, investors, and advisors that have the strongest LinkedIn profiles in terms of Education and Career History in order for our platform to identify mutual career or educational history with investors. You are 3x more likely to find mutual connections and warm introductions.</p>
                <div class="mt-4 mb-3 text-left">
                  <label class="d-flex align-items-center">
                    <input type="checkbox" id="noMoreTeamMembers" class="me-2">
                    <span style="font-size: 0.8em;">I do not have any more team members, advisors, or investors.</span>
                  </label>
                </div>
                <div id="continueAnywayButton" style="display: none;">
                  <button class="swal2-confirm swal2-styled" type="button">Continue Anyway</button>
                </div>
              </div>
            `,
            showConfirmButton: true,
            confirmButtonText: 'Add a Team Member',
            showCancelButton: true,
            allowOutsideClick: true,
            allowEscapeKey: true,
            allowEnterKey: false,
            didOpen: () => {
              const checkbox = document.getElementById('noMoreTeamMembers')
              const continueButton = document.querySelector('#continueAnywayButton button')
              
              checkbox.addEventListener('change', () => {
                document.getElementById('continueAnywayButton').style.display = 
                  checkbox.checked ? 'block' : 'none'
              })

              continueButton.addEventListener('click', async () => {
                await this.$store.dispatch('UPDATE_STARTUP_FLAGS', {
                  id: this.instance.id,
                  flags: {
                    marked_as_small_team: true
                  }
                })
                Swal.close()
              })
            },
            preConfirm: () => {
              return {
                noMoreTeamMembers: document.getElementById('noMoreTeamMembers').checked
              }
            }
          }).then((result) => {
            if (result.isConfirmed && !result.value.noMoreTeamMembers) {
              this.$router.push({
                name: 'contact_new_startup',
                params: { startup_id: this.instance.id }
              })
              return false
            }
            return result.value?.noMoreTeamMembers || false
          })
          return false
        }
        return true
      } catch (error) {
        console.error('Error checking team member status:', error)
        return false
      }
    },
    showAddCountryOneLinerForm() {
      this.showCountryOneLinerForm = true;
      this.selectedRegion = null;
      this.countryOneLiner = '';
      this.errors.country_one_liner = [];
    },
    cancelAddCountryOneLiner() {
      this.showCountryOneLinerForm = false;
      this.selectedRegion = null;
      this.countryOneLiner = '';
      this.errors.country_one_liner = [];
    },
    addCountryOneLiner() {
      if (this.selectedRegion && this.countryOneLiner) {
        const updatedOneLiners = [...(this.instance.country_one_liner || []), {
          region: this.selectedRegion.id,
          country_one_liner: this.countryOneLiner,
        }];

        this.$store.dispatch('UPDATE_STARTUP', {
          id: this.instance.id,
          country_one_liner: updatedOneLiners
        })
        .then(response => {
          this.instance = response.data;
          this.cancelAddCountryOneLiner();
          this.$toast.success('Country one-liner added successfully');
        })
        .catch(error => {
          console.error('Failed to add country one-liner:', error);
          this.errors.country_one_liner = ['Failed to add country one-liner'];
        });
      } else {
        this.errors.country_one_liner = ['Please select a region and enter a one-liner'];
      }
    },
    removeCountryOneLiner(oneLiner) {
      Swal.fire({
        title: 'Remove Country One-Liner',
        text: 'Are you sure you want to remove this country one-liner?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, remove it',
        cancelButtonText: 'Cancel'
      }).then((result) => {
        if (result.isConfirmed) {
          const updatedOneLiners = this.instance.country_one_liner.filter(
            item => item.country_one_liner !== oneLiner.country_one_liner || 
                   item.region.id !== oneLiner.region.id
          );

          this.$store.dispatch('UPDATE_STARTUP', {
            id: this.instance.id,
            country_one_liner: updatedOneLiners
          })
          .then(response => {
            this.instance = response.data;
            this.$toast.success('Country one-liner removed successfully');
          })
          .catch(error => {
            console.error('Failed to remove country one-liner:', error);
            this.$toast.error('Failed to remove country one-liner');
          });
        }
      });
    },
    async goGenerateMatchScore(useOldMethod = false) {
      let teamMemberAdded = await this.checkTeamMemberAdditionStatus();
      if (teamMemberAdded) {
        Swal.fire({
          title: "Generate Match Scores?",
          html: `Are you sure you want to trigger generation of match scores for ${this.instance.name}?`,
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, generate matches",
          cancelButtonText: "Cancel"
        }).then((result) => {
          if (result.isConfirmed) {
            this.$store.dispatch('GENERATE_MATCH_SCORE', { startupId: this.instance.id, useOldMethod: useOldMethod })
              .then(() => {
                Swal.fire({
                  title: "Job Triggered!",
                  text: "A background job has been triggered to generate match scores. Please check back later.",
                  icon: "success"
                });
              })
              .catch((error) => {
                console.error(error);
                Swal.fire({
                  title: "Error",
                  text: error.response?.data?.error || "Failed to generate match scores.",
                  icon: "error"
                });
              });
          }
        });
      }
    },
    async generateCareerOpenerOneLiner() {
      let teamMemberAdded = await this.checkTeamMemberAdditionStatus();
      if (teamMemberAdded) {
        Swal.fire({
          title: "Generate Career Opener One Liner?",
          html: `Are you sure you want to trigger generation of career opener one liners for ${this.instance.name}?`,
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel"
        }).then((result) => {
          if (result.isConfirmed) {
            this.$store.dispatch('GENERATE_CAREER_OPENER_ONE_LINER_FOR_STARTUP', this.instance.id)
              .then((response) => {
                Swal.fire({
                  title: "Job Triggered!",
                  text: response.data.message || "A background job has been triggered to generate the career opener one liner. Please check back later.",
                  icon: "success"
                });
              })
              .catch((error) => {
                console.error(error);
                Swal.fire({
                  title: "Error",
                  text: error.response?.data?.error || "Failed to generate career opener one liner.",
                  icon: "error"
                });
              });
          }
        });
      }
    },
    goNewEmailDraft() {
      // Reset form
      this.newDraft = {
        subject: '',
        content: ''
      }
      // Show modal
      this.emailDraftModal = new Modal(document.getElementById('newEmailDraftModal'))
      this.emailDraftModal.show()
    },
    async saveEmailDraft() {
      if (!this.newDraft.subject || !this.newDraft.content) {
        // You might want to show an error message here
        return
      }

      this.isSaving = true
      try {
        const response = await this.$store.dispatch('SAVE_EMAIL_DRAFT', {
          startup_id: this.instance.id,
          draft: this.newDraft
        })

        // Assuming the API returns the updated startup data
        this.instance = response.data

        // Close modal
        this.emailDraftModal.hide()

        // Show success message
        this.$toast.success('Email draft saved successfully')
      } catch (error) {
        console.error('Failed to save email draft:', error)
        this.$toast.error('Failed to save email draft')
      } finally {
        this.isSaving = false
      }
    },
    viewDraft(draft) {
      this.selectedDraft = JSON.parse(JSON.stringify(draft)); // Create a deep copy
      this.draftBackup = JSON.parse(JSON.stringify(draft)); // Backup for canceling edits
      this.isEditing = false;
      if (!this.viewDraftModal) {
        this.viewDraftModal = new Modal(document.getElementById('viewEmailDraftModal'));
      }
      this.viewDraftModal.show();
    },
    editDraft(draft) {
      this.viewDraft(draft);
      this.isEditing = true;
    },
    cancelEdit() {
      this.selectedDraft = JSON.parse(JSON.stringify(this.draftBackup));
      this.isEditing = false;
    },
    async updateDraft() {
      if (!this.selectedDraft.subject || !this.selectedDraft.content) {
        Swal.fire({
          title: 'Error',
          text: 'Subject and content are required',
          icon: 'error'
        });
        return;
      }

      this.isSaving = true;
      try {
        // Update the previous_email_drafts in the instance
        const updatedDrafts = this.instance.previous_email_drafts.map(draft =>
          draft.id === this.selectedDraft.id ? this.selectedDraft : draft
        );

        // Send just the previous_email_drafts array
        const response = await this.$store.dispatch('UPDATE_STARTUP', {
          id: this.instance.id,
          previous_email_drafts: updatedDrafts
        });

        this.instance = response.data;

        // Close modal and reset state
        this.viewDraftModal.hide();
        this.isEditing = false;
        this.selectedDraft = null;
        this.draftBackup = null;

        Swal.fire({
          title: 'Success',
          text: 'Email draft updated successfully',
          icon: 'success'
        });
      } catch (error) {
        console.error('Failed to update email draft:', error);
        const errorMessage = error?.response?.data?.message || 'Failed to update email draft';
        Swal.fire({
          title: 'Error',
          text: errorMessage,
          icon: 'error'
        });
      } finally {
        this.isSaving = false;
      }
    },
    addCoinvestor() {
      Swal.fire({
        title: 'Add Coinvestor',
        html: `
          <div class="mb-3">
            <label class="form-label">Name</label>
            <input id="coinvestorName" class="form-control" type="text">
          </div>
          <div class="mb-3">
            <label class="form-label">Coinvested With</label>
            <input id="coinvestedWith" class="form-control" type="text">
          </div>
          <div class="mb-3">
            <label class="form-label">Crunchbase URL (optional)</label>
            <input id="crunchbaseUrl" class="form-control" type="url">
          </div>
        `,
        showCancelButton: true,
        confirmButtonText: 'Add',
        preConfirm: () => {
          const name = document.getElementById('coinvestorName').value;
          const coinvestedWith = document.getElementById('coinvestedWith').value;
          const crunchbaseUrl = document.getElementById('crunchbaseUrl').value;
          
          if (!name) {
            Swal.showValidationMessage('Please enter a name');
            return false;
          }
          
          return { name, coinvested_with: coinvestedWith, crunchbase_url: crunchbaseUrl };
        }
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch('ADD_COINVESTOR', {
            startup_id: this.instance.id,
            ...result.value
          })
          .then(response => {
            this.instance = response.data;
            this.$toast.success('Coinvestor added successfully');
          })
          .catch(error => {
            console.error('Failed to add coinvestor:', error);
            this.$toast.error('Failed to add coinvestor');
          });
        }
      });
    },
    getRegionName(regionId) {
      const region = this.optionsRegions.find(r => r.id === regionId);
      return region ? region.name : 'Unknown Region';
    },
    checkCountryOneLiners() {
      // Check if country one-liners array exists and is not empty
      if (!this.instance.country_one_liner || this.instance.country_one_liner.length === 0) {
        // Show popup to add country one-liner
        this.showCountryOneLinerPopup();
      }
    },
    showCountryOneLinerPopup() {
      Swal.fire({
        title: 'Country One-Liner Required',
        html: `
          <p>Please add at least one country one-liner for this startup. This information helps potential investors understand regional market fit.</p>
          <div class="mb-3 mt-4">
            <label class="form-label text-start d-block">Select Region</label>
            <select id="regionSelect" class="form-select">
              <option value="">Select a region</option>
              ${this.optionsRegions.map(region => `<option value="${region.id}">${region.name}</option>`).join('')}
            </select>
          </div>
          <div class="mb-3">
            <label class="form-label text-start d-block">Enter One-Liner</label>
            <textarea id="oneLinerText" class="form-control" rows="3" placeholder="Enter a brief description of the company's market fit for this region..."></textarea>
          </div>
        `,
        showCancelButton: true,
        confirmButtonText: 'Add One-Liner',
        cancelButtonText: 'Later',
        focusConfirm: false,
        preConfirm: () => {
          const regionId = document.getElementById('regionSelect').value;
          const oneLinerText = document.getElementById('oneLinerText').value;
          
          if (!regionId) {
            Swal.showValidationMessage('Please select a region');
            return false;
          }
          
          if (!oneLinerText) {
            Swal.showValidationMessage('Please enter a one-liner');
            return false;
          }
          
          return { regionId, oneLinerText };
        }
      }).then((result) => {
        if (result.isConfirmed) {
          const selectedRegion = this.optionsRegions.find(r => r.id == result.value.regionId);
          
          // Add the country one-liner
          const updatedOneLiners = [...(this.instance.country_one_liner || []), {
            region: parseInt(result.value.regionId),
            country_one_liner: result.value.oneLinerText,
          }];

          this.$store.dispatch('UPDATE_STARTUP', {
            id: this.instance.id,
            country_one_liner: updatedOneLiners
          })
          .then(response => {
            this.instance = response.data;
            this.$toast.success('Country one-liner added successfully');
            
            // Switch to the country one-liners tab
            setTimeout(() => {
              const tabElement = document.querySelector('a[href="#country-oneliners-tab"]');
              if (tabElement) {
                tabElement.click();
              }
            }, 300);
          })
          .catch(error => {
            console.error('Failed to add country one-liner:', error);
            this.$toast.error('Failed to add country one-liner');
          });
        }
      });
    },
  }
}
</script>

<style scoped>
.startup-view {
  background-color: #f8f9fa;
  min-height: 100vh;
}

.header-section {
  background-color: #fff;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.header-section .card {
  background-color: #f8f9fa;
  border: 1px solid rgba(0,0,0,0.1);
}

.header-section .card-title {
  color: #344767;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.header-section h2 {
  color: #344767;
  font-weight: 600;
}

.btn-soft-primary {
  background-color: rgba(85,110,230,0.1);
  color: #556ee6;
  border: none;
}

.btn-soft-primary:hover {
  background-color: #556ee6;
  color: #fff;
}

.btn-soft-danger {
  background-color: rgba(244,106,106,0.1);
  color: #f46a6a;
  border: none;
}

.btn-soft-danger:hover {
  background-color: #f46a6a;
  color: #fff;
}

.btn-soft-secondary {
  background-color: rgba(74,84,104,0.1);
  color: #4a5468;
  border: none;
}

.btn-soft-secondary:hover {
  background-color: #4a5468;
  color: #fff;
}

.btn-soft-info {
  background-color: rgba(80,165,241,0.1);
  color: #50a5f1;
  border: none;
}

.btn-soft-info:hover {
  background-color: #50a5f1;
  color: #fff;
}

.btn-soft-warning {
  background-color: rgba(241,180,76,0.1);
  color: #f1b44c;
  border: none;
}

.btn-soft-warning:hover {
  background-color: #f1b44c;
  color: #fff;
}

.bg-soft-primary {
  background-color: rgba(85,110,230,0.1) !important;
}

.text-primary {
  color: #556ee6 !important;
}

.nav-tabs .nav-link {
  color: #495057;
  font-weight: 500;
}

.nav-tabs .nav-link.active {
  color: #556ee6;
  font-weight: 600;
}

.email-draft-item {
  transition: all 0.3s ease;
}

.email-draft-item:hover {
  background-color: #f8f9fa;
}

.table-light {
  background-color: #f8f9fa;
}

.table > :not(caption) > * > * {
  padding: 1rem;
}

.border-bottom {
  border-color: #e9ecef !important;
}

.text-muted {
  color: #6c757d !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.investor-name-link {
  color: #2c3e50;
  text-decoration: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.2;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  transition: all 0.2s ease;
}

.investor-name-link:hover {
  color: #556ee6;
  text-decoration: none;
}

.investor-name-link i {
  font-size: 12px;
  opacity: 0;
  transform: translateX(-4px);
  transition: all 0.2s ease;
}

.investor-name-link:hover i {
  opacity: 1;
  transform: translateX(0);
}

.badge {
  padding: 0.4rem 0.65rem;
  font-weight: 500;
  font-size: 0.75rem;
  display: inline-flex;
  align-items: center;
}

.country-badge {
  padding: 0.25rem 0.5rem;
  gap: 4px;
}

.country-badge img {
  margin-right: 0;
}
</style>