import { createApp } from 'vue'

import App from './App.vue'
import router from './router'

import store from '@/store'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap'

import './assets/sass/main.scss'

import VueApexCharts from "vue3-apexcharts"

import GlobalComponents from './globalComponents'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import filters from './filters'

import VMdEditor from '@kangc/v-md-editor';
import '@kangc/v-md-editor/lib/style/base-editor.css';
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js';
import '@kangc/v-md-editor/lib/theme/style/vuepress.css';

import enUS from '@kangc/v-md-editor/lib/lang/en-US';
import * as Sentry from "@sentry/vue";

VMdEditor.lang.use('en-US', enUS);

// Prism
import Prism from 'prismjs';
// highlight code
import 'prismjs/components/prism-json';

VMdEditor.use(vuepressTheme, {
  Prism,
});

const app = createApp(App)

Sentry.init({
  app,
  dsn: "https://46dce33e3399cd3107842bfc7c739f67@o4504972590252032.ingest.us.sentry.io/4507883986681857",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0,
  tracePropagationTargets: [".*"], // This will match all URLs
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

// Initialize Intercom
const initIntercom = () => {
  window.intercomSettings = {
    app_id: 'o2mx4w6j',
  };

  // Load Intercom script
  (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/YOUR_APP_ID';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
}

// Initialize Intercom before mounting the app
initIntercom()

app.use(router)
app.use(store)

app.use(VueApexCharts)
app.use(GlobalComponents)
app.use(ElementPlus)

app.use(VMdEditor);
app.config.globalProperties.$filters = filters

app.mount('#app')
