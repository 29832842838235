<template>
  <div>
    <app-header></app-header>
    <div v-if="instance">
      <div class="col-12">
        <ul class="nav nav-tabs" v-if="EngagementForms">
          <li v-for="item in EngagementForms" class="nav-item">
            <a href="#" class="nav-link" :class="{ active: item.id == EngagementForm.id }"
              @click.prevent="setEngagementForm(item)">
              {{ item.name }}
            </a>
          </li>
        </ul>
      </div>

      <div class="row">
        <div class="col-12">

          <div class="card">
            <div class="card-body">


              <h3 class="mb-4">
                {{ instance.contact_first_name }} {{ instance.contact_last_name }}

              </h3>


              <h4>
                <span v-if="!siteCompany.is_whitelabel">Astelios Contact Information</span>
                <span v-else>Your Information</span>
              </h4><br>

              <p class="mb-2">
                <span class="fw-bold me-2">
                  <span v-if="!siteCompany.is_whitelabel">Astel Account Manager Name:</span>
                  <span v-else>Your Name:</span>
                </span>
                {{ instance.astel_account_manager_name }}
              </p>

              <p class="mb-2">
                <span class="fw-bold me-2">
                  <span v-if="!siteCompany.is_whitelabel">Astel Account Manager Email:</span>
                  <span v-else>Your Email:</span>
                </span>
                {{ instance.astel_account_manager_email }}
              </p>
              <p class="mb-2">
                <span class="fw-bold me-2">Date of Call:</span>
                {{ instance.date_of_call }}
              </p>


            </div>
          </div>
        </div>

        <div class="col-md-6 col-sm-12">

          <div class="card">
            <div class="card-body">
              <h4 class="header-title">
                Investor Information
              </h4><br>
              <p class="mb-2">

                <span class="fw-bold me-2">Fund Name:</span>
                <router-link :to="`/investors/companies/${instance.company.id}/`">
                  {{ instance.company.name }}
                </router-link>
              </p>

              <p class="mb-2">
                <span class="fw-bold me-2">Fund Website:</span>
                <a :href="getFullUrl(instance.company_website)" target="_blank">{{ instance.company_website }}</a>
              </p>

              <p class="mb-2">
                <span class="fw-bold me-2">Country of Investor:</span>
                <template v-if="instance.investor_location && instance.investor_location.length">
                  <span v-for="item in instance.investor_location" :key="item" class="d-block pre">
                    <template v-if="item">
                      <country-flag :country='item.code' size='small' />
                      {{ item.name }}
                    </template>
                  </span>
                </template>
                <template v-else>
                  —
                </template>
              </p>
              <p class="mb-2">
                <span class="fw-bold me-2">Investor Type:</span>
                <template v-if="instance.investor_type && instance.investor_type.length">
                  <span v-for="item in instance.investor_type" :key="item" class="d-block pre">
                    {{ item.name }}
                  </span>
                </template>
                <template v-else>
                  —
                </template>
              </p>
              <p class="mb-2">
                <span class="fw-bold me-2">Contact Name:</span>
                {{ instance.contact_first_name }} {{ instance.contact_last_name }}
              </p>

              <p class="mb-2">
                <span class="fw-bold me-2">Contact Email:</span>
                {{ instance.contact_email }}
              </p>
              <p class="mb-2">
                <span class="fw-bold me-2">Contact Title:</span>
                <template v-if="instance.contact_title && instance.contact_title.length">
                  <span v-for="item in instance.contact_title" :key="item" class="d-block pre">
                    {{ item.name }}
                  </span>
                </template>
                <template v-else>
                  —
                </template>
              </p>




            </div>
          </div>


          <div class="card">
            <div class="card-body">
              <h4 class="header-title">
                Questions On The Fund
              </h4><br>
              <p class="mb-2">
                <span class="fw-bold me-2">Past Funds:</span>
                {{ instance.past_funds }}
              </p>
              <p class="mb-2">
                <span class="fw-bold me-2">Current Fundraise:</span>
                {{ instance.current_fundraise }}
              </p>
              <p class="mb-2" v-if="instance.assets_under_management">
                <span class="fw-bold me-2">Assets Under Management:</span>
                ${{ $filters.money(instance.assets_under_management) }}
              </p>

              <p class="mb-2">
                <span class="fw-bold me-2">US major fund:</span>
                {{ instance.US_major_fund }}

              </p>
              <p class="mb-2">
                <span class="fw-bold me-2">Other Investment Funds:</span>
                {{ instance.other_investment_funds }}

              </p>
              <p class="mb-2">
                <span class="fw-bold me-2">Top 3 Investment Funds:</span>
                {{ instance.top_invested_funds }}
              </p>
              <p class="mb-2">
                <span class="fw-bold me-2">LP Invested in Fund:</span>
                {{ instance.lp_invested_in_fund }}
              </p>

            </div>
          </div>
        </div>

        <div class="col-md-6 col-sm-12">

          <div class="card">
            <div class="card-body">
              <h4 class="header-title">
                Fund's Investment Thesis
              </h4><br>
              <p class="mb-2">
                <span class="fw-bold me-2">Investment Stages:</span>
                <template v-if="instance.investment_stages && instance.investment_stages.length">
                  <span v-for="item in instance.investment_stages" :key="item" class="d-block pre">
                    {{ item.name }}
                  </span>
                </template>
                <template v-else>
                  —
                </template>
              </p>
              <p class="mb-2">
                <span class="fw-bold me-2">Sector Focus:</span>
                <template v-if="instance.industry_sector_tags && instance.industry_sector_tags.length">
                  <span v-for="item in instance.industry_sector_tags" :key="item" class="d-block pre">
                    {{ item }}
                  </span>
                </template>
                <template v-else>
                  —
                </template>
              </p>
              <p class="mb-2">
                <span class="fw-bold me-2">Geo Focus:</span>
                <template v-if="instance.investment_countries && instance.investment_countries.length">
                  <span v-for="item in instance.investment_countries" :key="item" class="d-block pre">
                    <template v-if="item">
                      <country-flag :country='item.code' size='small' />
                      {{ item.name }}
                    </template>
                  </span>
                </template>
                <template v-else>
                  —
                </template>
              </p>
              <p class="mb-2">
                <span class="fw-bold me-2">Region Focus:</span>
                <template v-if="instance.preferred_investor_region && instance.preferred_investor_region.length">
                  <span v-for="item in instance.preferred_investor_region" :key="item" class="d-block pre">
                    {{ item.name }}
                  </span>
                </template>
                <template v-else>
                  —
                </template>
              </p>
              <p class="mb-2" v-if="instance.ticket_size_from || instance.ticket_size_to">
                <span class="fw-bold me-2">Ticket size:</span>
                ${{ $filters.money(instance.ticket_size_from) }} - {{ $filters.money(instance.ticket_size_to) }}
              </p>
              <p class="mb-2" v-if="instance.EBITDA_requirements_from || instance.EBITDA_requirements_to">
                <span class="fw-bold me-2">EBITDA Requirements:</span>
                ${{ $filters.money(instance.EBITDA_requirements_from) }} - {{
                  $filters.money(instance.EBITDA_requirements_to) }}
              </p>
              <p class="mb-2" v-if="instance.ARR_requirement_from || instance.ARR_requirement_to">
                <span class="fw-bold me-2">ARR Requirement:</span>
                ${{ $filters.money(instance.ARR_requirement_from) }} - {{ $filters.money(instance.ARR_requirement_to) }}
              </p>
              <p class="mb-2" v-if="instance.valuation_from || instance.valuation_to">
                <span class="fw-bold me-2">Valuation Bracket:</span>
                ${{ $filters.money(instance.valuation_from) }} - {{ $filters.money(instance.valuation_to) }}
              </p>
              <p class="mb-2">
                <span class="fw-bold me-2">Ownership Target:</span>
                {{ instance.ownership_target }}
              </p>
              <p class="mb-2">
                <span class="fw-bold me-2">Other Company Metrics:</span>
                {{ instance.other_company_metrics }}
              </p>



            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <h4 class="header-title">
                Other Questions
              </h4>

              <p class="mb-2">
                <span class="fw-bold me-2">Other Information:</span>
                {{ instance.other_information }}
              </p>
              <p class="mb-2">
                <span class="fw-bold me-2">Video Interview:</span>
                {{ instance.video_interview }}
              </p>
              <p class="mb-2">
                <span class="fw-bold me-2">Re Engagement Call:</span>

                {{ instance.re_engagement_call }}
              </p>
              <p class="mb-2">
                <span class="fw-bold me-2">KYC Partner:</span>
                {{ instance.kyc_partner }}
              </p>
              <p class="mb-2">
                <span class="fw-bold me-2">Startups:</span>
                {{ instance.startups }}
              </p>
              <p class="mb-2">
                <span class="fw-bold me-2">Muboriz Linkedin Oneliner:</span>
                {{ instance.muboriz_linkedin_oneliner }}
              </p>


            </div>
          </div>


        </div>
      </div>


    </div>

    <!-- end row -->


    <div v-else class="text-center">
      <loader></loader>
    </div>

  </div>
</template>

<script>
import SearchMixin from '@/mixins/search'
import { mapState } from 'vuex'
import Header from '@/components/Layout/Header.vue'
import Footer from '@/components/Layout/Footer.vue'
import { update } from '@intercom/messenger-js-sdk'

export default {
  name: 'engagementcallinfo',

  components: {
    'app-header': Header,
    'app-footer': Footer
  },

  mixins: [SearchMixin],

  data() {
    return {
      instance: null,
      EngagementForm: { id: 'questions', name: 'Questions' },
      EngagementForms: [
        { id: 'questions', name: 'Questions' },
        { id: 'responses', name: 'Responses' },
      ],
    }
  },

  computed: {
    ...mapState({
      current: state => state.engagementcallinfo.engagementcallinfo,
      siteCompany: state => state.auth.siteCompany,
    }),
    engagementCallId() {
      return this.$route.params.id || (this.current && this.current.id)
    },

  },


  mounted() {
    if (this.user) {
      update({
        name: this.user.first_name + ' ' + this.user.last_name,
        email: this.user.email,
        created_at: this.user.date_joined,
        user_id: this.user.id,
        company: this.siteCompany ? {
          company_id: this.siteCompany.id,
          name: this.siteCompany.name
        } : null,
        current_page: 'engagement_call_view',
        engagement_call_id: this.engagementCallId
      });
    }

    window.scrollTo(0, 0)
    console.log(this.current)

    if (this.engagementCallId) {
      this.$store.dispatch('LOAD_ENGAGEMENT_CALL_INFO', this.engagementCallId)
        .then((response) => {
          this.instance = response.data
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('SET_CURRENT_ENGAGEMENT_CALL_INFO', null)
          this.$store.commit('SET_STARTUPS', null)

        })
    } else {

      this.$router.push({ name: 'startups_selector' })
    }
  },

  methods: {
    load() {
      return this.$store.dispatch('LOAD_ENGAGEMENT_CALL_INFO', this.engagementCallId)
    },
    getFullUrl(url) {
      if (!url) return ''
      if (url.indexOf('http') === 0) return url
      return `http://${url}`
    },
    getResponsesList() {
      this.$store.dispatch('LIST_ENGAGEMENT_CALL_INFO')
      this.$router.push({ name: 'engagement-call-info' })
    },
    setEngagementForm(item) {
      this.EngagementForm = item;
      if (item.id === 'questions') {
        this.getResponsesList()
      }
    }


  }
}
</script>
