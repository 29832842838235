<template>
  <div>
    <div v-if="instance">
      <div class="row">
        <div class="col pe-0">

          <div class="card">
            <div class="card-body">
              
              <div class="d-grid gap-2 d-md-flex justify-content-md-end mb-2 float-end">
                <a class="btn btn-outline-primary" @click.prevent="goEditPrompt">
                  <i class="uil uil-pen me-1"></i>
                  <span>Edit GPT Prompt</span>
                </a>

                <a href="#" class="btn btn-outline-primary" @click.prevent="goEdit">
                  <i class="uil uil-pen me-1"></i>
                  <span>Edit</span>
                </a>
                <a href="#" class="btn btn-outline-danger" @click.prevent="goDelete">
                  <i class="uil uil-trash me-1"></i>
                  <span>Delete</span>
                </a>
                <a href="#" class="btn btn-outline-info" @click.prevent="goRingfence">
                  <i class="uil uil-bag-slash me-1"></i>
                  <span>Manage Ringfence</span>
                </a>
                <a href="#" class="btn btn-outline-primary" @click.prevent="goSwitch">
                  <i class="uil uil-exchange me-1"></i>
                  <span>Switch Company</span>
                </a>
              </div>
              
              <h3>{{ instance.name }} Email Draft</h3>

              <p>Create outreach email introduction using AI.</p>

              <div v-if="isLoading">
                <loader></loader>
                <p class="my-4">Working...</p>
              </div>

              <div v-else>
                <div class="row mt-2">
                  <div class="col-12 mb-3">
                    <div class="alert alert-info">
                      <div class="d-flex">
                        <div class="me-3">
                          <i class="uil uil-info-circle h4 mb-0 text-info"></i>
                        </div>
                        <div>
                          <h5 class="text-info">Improve Your Email Templates</h5>
                          <p class="mb-2">
                            Our AI considers various factors to create personalized email templates, including:
                          </p>
                          <ul class="mb-2">
                            <li>Company details (description, valuation, raised funds)</li>
                            <li>Team information and experience</li>
                            <li>Previous successful email drafts</li>
                            <li>Investment preferences and target markets</li>
                            <li>Awards and key selling points</li>
                            <li>Pitch deck content (when available)</li>
                          </ul>
                          <p class="mb-2" v-if="!hasPreviousEmails">
                            You haven't added any previous successful email drafts yet. Adding these can help generate more effective templates.
                          </p>
                          <button class="btn btn-outline-info btn-sm" @click="goToProfile">
                            <i class="uil uil-plus-circle me-1"></i>
                            <span>Add Previous Email Drafts</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="border border-primary rounded p-3 h-100">
                      <!-- <div class="d-flex align-items-center mb-3" style="min-height: 32px;">
                        <div class="d-flex align-items-center justify-content-center" style="width: 32px; height: 32px;">
                          <i class="uil uil-envelope-alt text-primary" style="font-size: 24px;"></i>
                        </div>
                        <h5 class="mb-0 fw-bold text-primary" style="font-size: 18px; line-height: 32px;">Initial Email Template</h5>
                      </div> -->
                      <div class="d-flex align-items-center mb-3" style="min-height: 32px;">
                        <div class="d-flex align-items-center justify-content-center" style="width: 32px; height: 32px;">
                          <i class="uil uil-envelope-alt text-primary" style="font-size: 24px;"></i>
                        </div>
                        <h5 class="mb-0 fw-bold text-primary" style="font-size: 18px; line-height: 32px;">Initial Email Template</h5>
                      </div>
                      <div class="d-flex justify-content-between mb-3">
                        <button class="btn btn-primary" @click.prevent="goGenerateInitial">
                          <i class="uil uil-comment-alt-info me-1"></i>
                          <span>Generate Initial Email</span>
                        </button>
                        <button class="btn btn-outline-primary" @click="editInitialEmail" v-if="intro">
                          <i class="uil uil-pen me-1"></i>
                          <span>Edit</span>
                        </button>
                      </div>
                      <p class="pre" v-html="intro" v-if="intro"></p>
                      <p v-else>No intro generated yet.</p>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="border border-info rounded p-3 h-100">
                      <div class="d-flex align-items-center mb-3" style="min-height: 32px;">
                        <div class="d-flex align-items-center justify-content-center" style="width: 32px; height: 32px;">
                          <i class="uil uil-envelope-redo text-info" style="font-size: 24px;"></i>
                        </div>
                        <h5 class="mb-0 fw-bold text-info" style="font-size: 18px; line-height: 32px;">Follow-up Email Template</h5>
                      </div>
                      <div class="d-flex justify-content-between mb-3">
                        <button class="btn btn-info" @click.prevent="goGenerateFollowUp">
                          <i class="uil uil-comment-alt-info me-1"></i>
                          <span>Generate Follow-up Email</span>
                        </button>
                        <button class="btn btn-outline-info" @click="editFollowUpEmail" v-if="follow_up_email">
                          <i class="uil uil-pen me-1"></i>
                          <span>Edit</span>
                        </button>
                      </div>
                      <p class="pre" v-html="follow_up_email" v-if="follow_up_email"></p>
                      <p v-else>No follow-up email generated yet.</p>
                    </div>
                  </div>
                </div>

              </div>
              
            </div>
          </div>
        </div> <!-- end col -->
      </div>

    </div>
    <div v-else class="text-center">
      <loader></loader>
    </div>

    <div class="action-footer">
      <div class="container-fluid">
        <div class="card mb-0">
          <div class="card-body">
            <div class="float-end">
              <a href="#" class="btn btn-outline-primary me-2" @click.prevent="goGenerate">
                <i class="uil uil-comment-alt-info me-1"></i>
                <span>Generate Email Intro</span>
              </a>
              <a href="#" class="btn btn-primary" @click.prevent="goConnect">
                <i class="uil uil-adjust-circle me-1"></i>
                <span>Connect with Investors</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="initialEmailModal" tabindex="-1" aria-labelledby="initialEmailModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="initialEmailModalLabel">Edit Initial Email</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <textarea class="form-control" v-model="editedInitialEmail" rows="20" style="min-height: 400px;"></textarea>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
            <button type="button" class="btn btn-primary" @click="saveInitialEmail" :disabled="isUpdating">
              <span v-if="isUpdating">Saving...</span>
              <span v-else>Save Changes</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="followUpEmailModal" tabindex="-1" aria-labelledby="followUpEmailModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="followUpEmailModalLabel">Edit Follow-up Email</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <textarea class="form-control" v-model="editedFollowUpEmail" rows="10"></textarea>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
            <button type="button" class="btn btn-info" @click="saveFollowUpEmail" :disabled="isUpdating">
              <span v-if="isUpdating">Saving...</span>
              <span v-else>Save Changes</span>
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import SearchMixin from '@/mixins/search'
import { mapState } from 'vuex'
import Swal from 'sweetalert2'
import CheckKeysMixin from '@/mixins/checkKeys'
import { Modal } from 'bootstrap'

export default {
  name: 'startup',

  mixins: [SearchMixin, CheckKeysMixin],

  data() {
    return {
      instance: null,
      intro: null,
      follow_up_email: null,
      isLoading: true,
      editedInitialEmail: '',
      editedFollowUpEmail: '',
      initialEmailModal: null,
      followUpEmailModal: null,
      isUpdating: false,
    }
  },

  computed: {
    ...mapState({
      current: state => state.startups.current,
      siteCompany: state => state.auth.siteCompany,
    }),
    
    hasPreviousEmails() {
      return this.instance?.previous_email_drafts?.length > 0
    }
  },
  
  mounted() {
    window.scrollTo(0, 0)
    this.isLoading = true
    const startupId = this.$route.params.id || (this.current && this.current.id)
    if (startupId) {
      this.$store.dispatch('LOAD_STARTUP', startupId)
        .then((response) => {
          this.instance = response.data
          this.intro = this.instance.generated_email_intro || this.intro
          this.follow_up_email = this.instance.generated_follow_up_email || this.follow_up_email
          
          // Remove this check as it should only be in StartupView.vue and Outreach.vue
          // this.checkMissingEmails()
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('SET_CURRENT_STARTUP', null)
          this.$router.push({ name: 'startups_selector' })
        })
        .finally(() => {
          this.isLoading = false
        })
    } else {
      this.$router.push({ name: 'startups_selector' })
    }
    this.initialEmailModal = new Modal(document.getElementById('initialEmailModal'))
    this.followUpEmailModal = new Modal(document.getElementById('followUpEmailModal'))
  },

  methods: {
    // Remove this method as it should only be in StartupView.vue and Outreach.vue
    // checkMissingEmails() {
    //   if (this.bothEmailsMissing) {
    //     Swal.fire({
    //       title: "Missing Email Templates",
    //       text: "You haven't generated initial and follow-up email templates yet. Would you like to create them now?",
    //       icon: "info",
    //       showCancelButton: true,
    //       confirmButtonColor: "#3085d6",
    //       confirmButtonText: "Yes, generate templates",
    //       cancelButtonColor: "#d33",
    //       cancelButtonText: "Not now"
    //     }).then((result) => {
    //       if (result.isConfirmed) {
    //         // Ask which one to generate first
    //         Swal.fire({
    //           title: "Which template first?",
    //           text: "Which email template would you like to generate first?",
    //           icon: "question",
    //           showDenyButton: true,
    //           confirmButtonText: "Initial Email",
    //           denyButtonText: "Follow-up Email",
    //           confirmButtonColor: "#3085d6",
    //           denyButtonColor: "#6c757d"
    //         }).then((result) => {
    //           if (result.isConfirmed) {
    //             this.goGenerateInitial()
    //           } else if (result.isDenied) {
    //             this.goGenerateFollowUp()
    //           }
    //         })
    //       }
    //     })
    //   }
    // },
    
    goEdit() {
      this.$router.push(`/startups/${this.instance.id}/edit`)
    },
    goDelete() {
      this.$router.push(`/startups/${this.instance.id}/delete`)
    },
    goMatch() {
      this.$router.push('/investors/contacts?match=true')
    },
    goConnect() {
      this.$router.push(`/investors/outreach`)
    },
    goRingfence() {
      this.$router.push(`/startups/${this.instance.id}/ringfence`)
    },
    goSwitch() {
      this.$router.push({ name: 'startups_selector' })
    },
    async goGenerateInitial() {
      console.log(this.instance)
      if (this.instance && this.instance.country_one_liner.length == 0) {
        Swal.fire({
          title: "Country One Liner Missing",
          text: "You need to add a country one liner to generate an email intro. This will help the AI generate a more personalized email intro.",
          icon: "info",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Update Profile",
          cancelButtonColor: "#d33",
          cancelButtonText: "Cancel"
        }).then((result) => {
          if (result.isConfirmed) {
            this.goEdit()
          }
        })
        return
      }

      if (!this.siteCompany.is_whitelabel) {
        this.triggerEmailIntro('initial')
        return
      }

      // whitelabel accounts need to check for keys
      let shouldConclude = await this.checkKeys()
      if (shouldConclude) {
        return
      }
      this.triggerEmailIntro('initial')      
    },
    async goGenerateFollowUp() {
      if (this.instance && this.instance.country_one_liner.length == 0) {
        Swal.fire({
          title: "Country One Liner Missing",
          text: "You need to add a country one liner to generate an email intro. This will help the AI generate a more personalized email intro.",
          icon: "info",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Update Profile",
          cancelButtonColor: "#d33",
          cancelButtonText: "Cancel"
        }).then((result) => {
          if (result.isConfirmed) {
            this.goEdit()
          }
        })
        return
      }

      if (!this.siteCompany.is_whitelabel) {
        this.triggerEmailIntro('follow_up')
        return
      }

      // Non-trial whitelabel accounts need to check for keys
      let shouldConclude = await this.checkKeys()
      if (shouldConclude) {
        return
      }
      this.triggerEmailIntro('follow_up')
    },
    triggerEmailIntro(type){
      const typeText = type === 'initial' ? 'Initial' : 'Follow-up'
      Swal.fire({
        title: `Generate ${typeText} Email?`,
        text: "This will create a new email using AI. Please ensure you have a sufficient balance in your AI Account.",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, I have enough credits!"
      }).then((result) => {
        if (result.isConfirmed) {
          this.isLoading = true
          this.generateEmailIntro(type)
        }
      })
    },
    generateEmailIntro(type) {
      const payload = {
        id: this.instance.id,
        save: true,
        type: type
      }
      
      const action = type === 'initial' ? 'GENERATE_EMAIL_INTRO' : 'GENERATE_FOLLOW_UP_EMAIL'
      
      this.$store.dispatch(action, payload)
        .then((response) => {
          if(type === 'initial') {
            this.intro = response.data
          } else {
            this.follow_up_email = response.data
          }
          Swal.fire({
            title: "Generated!",
            text: `Your ${type === 'initial' ? 'initial' : 'follow-up'} email has been created.`,
            icon: "success"
          });
        })
        .catch((error) => {
          console.error(error)
          Swal.fire({
            title: "Error",
            text: error.response?.data?.error || "Failed to generate email.",
            icon: "error"
          });
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    async goGenerate() {
      if(this.siteCompany.is_whitelabel){
        let task = 'generate_email_intro'
        let freeLimit = await this.checkFreeLimits(task)
        if(freeLimit && freeLimit !== undefined && freeLimit !== null){
          Swal.fire({
            title: "Generate Email Intro?",
            html: `This feature requires AI usage. You have ${freeLimit} free credit/s remaining.<br><br>
                  Using this feature will consume one credit.`,
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, generate openers",
            cancelButtonText: "Cancel"
          }).then((result) => {
            if (result.isConfirmed){
              this.isLoading = true
              this.generateEmailIntro()
              let limits = this.siteCompany.free_limits;
              if (task in limits && limits[task] > 0){
                limits[task] -= 1
              }
              this.updateFreeLimit(limits)
            }
          });
        } else {
          let shouldConclude = await this.checkKeys()
          if (shouldConclude) {
            return
          }
          this.triggerEmailIntro()
        }
      } else {
        this.triggerEmailIntro()
      }
    },
    goEditPrompt() {
      this.$router.push(`/me/gpt-prompts`)
    },
    goToProfile() {
      this.$router.push(`/startup/profile`)
    },
    editInitialEmail() {
      this.editedInitialEmail = this.intro
      this.initialEmailModal.show()
    },
    editFollowUpEmail() {
      this.editedFollowUpEmail = this.follow_up_email
      this.followUpEmailModal.show()
    },
    async saveInitialEmail() {
      // Check for required placeholders
      const requiredPlaceholders = [
        '{{FirstName}}',
        '{{combined_one_liner}}',
        '{{career_one_liner}}',
        '{{country_one_liner}}'
      ]
      
      const missingPlaceholders = requiredPlaceholders.filter(
        placeholder => !this.editedInitialEmail.includes(placeholder)
      )
      
      if (missingPlaceholders.length > 0) {
        Swal.fire({
          title: 'Missing Placeholders',
          html: `The following required placeholders are missing:<br><br>
                ${missingPlaceholders.map(placeholder => {
                  const explanations = {
                    '{{FirstName}}': 'Personalizes the email with the investor\'s first name',
                    '{{combined_one_liner}}': 'Highlights a connection angle between the investor and your startup, based on their investment focus, sectors, and interests',
                    '{{career_one_liner}}': 'Adds a personal touch by highlighting any shared professional connections, education, or work history between your team and the investor',
                    '{{country_one_liner}}': 'Demonstrates your market understanding and regional focus by highlighting your startup\'s relevance to the investor\'s geographic focus'
                  }
                  return `${placeholder} - ${explanations[placeholder]}`
                }).join('<br>')}<br><br>
                These placeholders are essential for creating personalized and effective outreach emails. Please add them to your template.`,
          icon: 'warning',
          confirmButtonColor: '#3085d6'
        })
        return
      }

      this.isUpdating = true
      try {
        const response = await this.$store.dispatch('UPDATE_STARTUP', {
          id: this.instance.id,
          generated_email_intro: this.editedInitialEmail
        })
        
        this.intro = this.editedInitialEmail
        this.instance = response.data
        this.initialEmailModal.hide()
        
        Swal.fire({
          title: 'Success!',
          text: 'Initial email template updated successfully',
          icon: 'success',
          confirmButtonColor: '#3085d6'
        })
      } catch (error) {
        console.error('Failed to update email:', error)
        Swal.fire({
          title: 'Error',
          text: error.response?.data?.error || 'Failed to update email template',
          icon: 'error',
          confirmButtonColor: '#d33'
        })
      } finally {
        this.isUpdating = false
      }
    },
    async saveFollowUpEmail() {
      this.isUpdating = true
      try {
        const response = await this.$store.dispatch('UPDATE_STARTUP', {
          id: this.instance.id,
          generated_follow_up_email: this.editedFollowUpEmail
        })
        
        this.follow_up_email = this.editedFollowUpEmail
        this.instance = response.data
        this.followUpEmailModal.hide()
        
        Swal.fire({
          title: 'Success!',
          text: 'Follow-up email template updated successfully',
          icon: 'success',
          confirmButtonColor: '#3085d6'
        })
      } catch (error) {
        console.error('Failed to update email:', error)
        Swal.fire({
          title: 'Error',
          text: error.response?.data?.error || 'Failed to update email template',
          icon: 'error',
          confirmButtonColor: '#d33'
        })
      } finally {
        this.isUpdating = false
      }
    },
  }
}
</script>
