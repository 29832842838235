<template>
  <div class="wrapper">
    <div class="leftside-menu mt-0">

      <div class="p-3">
        <button v-if="current" class="float-end btn btn-sm btn-light rounded-pill" @click="setCurrent(null)">
          <i class="uil uil-times"></i>
        </button>
        <h5 class="mb-3">Selected list:</h5>
        <div v-if="current" class="text-break">
          <h3>
            {{ current.saved_search_name }}
          </h3>
          <div class="muted fw-bold">
            #{{ current.id }}
          </div>
          <div>
            by {{ current.account_manager_display }}
          </div>
          <div>
            Hide Ringfence: {{ current.hide_ringfence }}
          </div>
          <div class="pt-2">
            {{ $filters.datetimeformat(current.datetime) }}
          </div>

          <loader size="32px" v-if="current.generatingCsv"></loader>
          <div class="mt-4" v-if="current.file_downloaded">
            <a href="#" @click.prevent="downloadCsv" class="pre fw-bold">
              <i class="uil uil-arrow-circle-down"></i> Download CSV
            </a>
          </div>
          <!-- Action Buttons -->
          <div class="mt-4 d-flex flex-column align-items-start" v-if="current.pushed_to_replyio">
            <a href="#" class="btn btn-outline-secondary" @click.prevent="">
              <i class="uil uil-pause"></i>
              Active Campaign
            </a>
          </div>
        </div>
        <div v-else>
          <p class="muted">No list selected</p>
        </div>

        <div class="clearfix"></div>

        <div class="float-end mt-4">

          <button v-if="user.can_generate_openers && current && !siteCompany.is_whitelabel"
            class="btn btn-outline-secondary me-2 custom-button"
            :class="{ disabled: isWarmIntroSubmitting || current.loading_warm_intros }"
            @click.prevent="openWarmIntroModal">
            <loader v-if="isWarmIntroSubmitting || current.loading_warm_intros" size="20px" class="float-start">
            </loader>
            <i v-else class="uil uil-comment-alt-info me-1"></i>
            Make Warm Intros
          </button>

          <button v-if="user.can_generate_openers && current" class="btn btn-outline-secondary me-2 custom-button"
            :class="{ disabled: isSubmitting || current.loading_email_openers }" @click.prevent="goGenerateContacts">
            <loader v-if="isSubmitting || current.loading_email_openers" size="20px" class="float-start"></loader>
            <i v-else class="uil uil-comment-alt-info me-1"></i>
            <span v-if="siteCompany.is_whitelabel">
              Angle to connect
            </span>
            <span v-else>
              Make Combined Openers
            </span>
          </button>

          <button v-if="user.can_generate_openers && current" class="btn btn-outline-secondary me-2 custom-button"
            :class="{ disabled: isCareerSubmitting || current.loading_career_openers }"
            @click.prevent="goGenerateCareers">
            <loader v-if="isCareerSubmitting || current.loading_career_openers" size="20px" class="float-start">
            </loader>
            <i v-else class="uil uil-comment-alt-info me-1"></i>
            <span v-if="siteCompany.is_whitelabel">
              Identify Career Matches
            </span>
            <span v-else>
              Make Career Openers
            </span>
          </button>

          <button v-if="!siteCompany.is_whitelabel && user.can_generate_openers && current"
            class="btn btn-outline-secondary me-2 custom-button"
            :class="{ disabled: isManagerSubmitting || current.loading_manager_email_openers }"
            @click.prevent="goGenerateManager">
            <loader v-if="isManagerSubmitting || current.loading_manager_email_openers" size="20px" class="float-start">
            </loader>
            <i v-else class="uil uil-comment-alt-info me-1"></i>
            Make Manager Openers
          </button>

          <hr v-if="!siteCompany.is_whitelabel">

          <button v-if="!siteCompany.is_whitelabel && user.can_generate_openers && current"
            class="btn btn-outline-secondary me-2 custom-button"
            :class="{ disabled: isCareerSubmitting || current.loading_career_openers }"
            @click.prevent="goSendWarmIntros">
            <loader v-if="isSending || current.sending_warm_intros" size="20px" class="float-start"></loader>
            <i v-else class="uil uil-envelope me-1"></i>
            Send Warm Intros
          </button>

          <hr v-if="!siteCompany.is_whitelabel">

          <!-- <button v-if="current" class="btn btn-outline-secondary me-2 custom-button" :class="{ disabled: isPushingContacts || current.push_in_progress }" @click.prevent="goCreateContactsApollo">
              <loader v-if="isPushingContacts|| current.push_in_progress" size="20px" class="float-start"></loader>
              <i v-else class="uil uil-envelope me-1"></i>
              <span v-if="siteCompany.is_whitelabel">Enrich with Apollo</span>
              <span v-else>Push to Apollo</span>
            </button>

            <button v-if="current" class="btn btn-outline-secondary me-2 custom-button" :class="{ disabled: isPushingContacts || current.push_in_progress }" @click.prevent="goEnrichContactsApollo">
              <loader v-if="isPushingContacts|| current.push_in_progress" size="20px" class="float-start"></loader>
              <i v-else class="uil uil-envelope me-1"></i>
               Enrich with Apollo
            </button> -->

        </div>

        <!-- Sidebar -left -->
      </div>

    </div>
    <!-- Left Sidebar End -->

    <div class="content-page">
      <div class="content">
        <div class="row">

          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12" v-if="current">

                  <div class="loader-overlay" v-if="isLoading">
                    <loader></loader>
                  </div>

                  <div class="table-data">
                    <div class="table-container">
                      <table class="table table-hover">
                        <thead>
                          <tr>
                            <th class="orderable">
                              Full name
                            </th>
                            <th class="orderable">
                              Company
                            </th>
                            <th style="width: 25%" v-if="!siteCompany.is_whitelabel">
                              Warm Intro ({{ getWarmIntroCount() }})
                            </th>
                            <th class="orderable" style="width: 25%">
                              <span v-if="siteCompany.is_whitelabel">
                                Combined Opener
                              </span>
                              <span v-else>
                                Combined Email Opener
                              </span>
                              ({{ getOpenerCount('contact_email_opener') }})
                            </th>
                            <th class="orderable" style="width: 25%">
                              <span v-if="siteCompany.is_whitelabel">
                                Career Opener
                              </span>
                              <span v-else>
                                Career Email Opener
                              </span>
                              ({{ getOpenerCount('career_email_opener') }})
                            </th>
                            <th class="orderable" style="width: 25%" v-if="!siteCompany.is_whitelabel">
                              <span v-if="siteCompany.is_whitelabel">
                                IR Opener
                              </span>
                              <span v-else>
                                Manager Email Opener
                              </span>
                              ({{ getOpenerCount('manager_email_opener') }})
                            </th>

                          </tr>
                        </thead>

                        <tbody v-if="contacts && contacts.results">
                          <tr v-for="contact in contacts.results" :key="contact.id">
                            <td>
                              <router-link :to="`/contacts/${contact.id}/`">{{ contact.first_name }} {{
                                contact.last_name }}</router-link>
                              <span class="mx-2">
                                <span class="badge text-white text-bg-dark my-2"
                                  v-if="contact.is_good_relation">GR</span>
                                <span class="badge text-green" style="background-color: green; color: white;"
                                  v-if="contact.is_deal_dashboard">DD</span>
                                <i class="uil uil-user text-green" v-if="contact.met_in_person"></i>
                                <i class="uil uil-phone text-green" v-if="contact.over_call"></i>
                                <i class="uil uil-envelope text-green" v-if="contact.over_email"></i>
                              </span>
                              <div class="small text-muted" v-if="!siteCompany.is_whitelabel">{{ contact.email || '—' }}
                              </div>
                            </td>
                            <td>
                              {{ contact.company.name || '—' }}
                            </td>

                            <td v-if="!siteCompany.is_whitelabel">
                              <loader v-if="current.loading_warm_intros" size="28px"></loader>
                              <template v-if="contact.warm_intro">
                                {{ $filters.truncate(contact.warm_intro.subject, 200) }}
                                <a href="#" @click.prevent="openWarmIntroDetailModal(contact)" class="text-muted small">
                                  View more
                                </a>
                              </template>
                              <template v-else>
                                ---
                              </template>
                            </td>
                            <td>
                              <loader v-if="current.loading_email_openers" size="28px"></loader>
                              <div class="cursor-pointer"
                                :class="{ 'text-red': contact.contact_email_opener_content_flag }"
                                @click.prevent="setEditContactOpener(contact)">
                                <template v-if="editContactOpenerId === contact.id">
                                  <textarea v-if="editContactOpenerId === contact.id" rows="10" class="form-control"
                                    v-model="editContactOpener" :disabled="savingContactOpener"
                                    @keydown.enter.prevent="saveContactOpener(contact)"
                                    @keydown.esc="setEditContactOpener(null)"></textarea>
                                  <div v-if="savingContactOpener" class="text-muted small">
                                    Saving...
                                  </div>
                                </template>
                                <template v-else>
                                  {{ contact.contact_email_opener || "---" }}
                                </template>
                              </div>
                            </td>
                            <td>
                              <loader v-if="current.loading_career_openers" size="28px"></loader>
                              {{ contact.career_email_opener || "---" }}
                            </td>
                            <td v-if="!siteCompany.is_whitelabel">
                              <loader v-if="current.loading_manager_email_openers" size="28px"></loader>
                              {{ contact.manager_email_opener || "---" }}
                            </td>
                            <td>
                              <a href="#" class="btn btn-sm btn-outline-danger custom-btn"
                                :class="{ disabled: isDeleting }" @click.prevent="goDeleteContact(contact.id)">
                                <i class="uil uil-trash me-1"></i>
                                <span>Remove</span>
                              </a>
                            </td>

                          </tr>
                        </tbody>
                      </table>

                      <pagination :items="contacts" :currentPage="page" :perPage="perPage" @pagechanged="onPageChanged">
                      </pagination>

                    </div>
                  </div>

                </div>
                <div class="col-12" v-else>
                  <p>Please select a Saved Search first.</p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="action-footer">
      <div class="container-fluid">
        <div class="card mb-0">
          <div class="card-body">
            <div class="float-end">

              <button class="btn btn-primary" :class="{ disabled: !current }" @click.prevent="goNext">
                <i class="uil uil-envelope-add"></i>
                Setup outreach campaign
              </button>

            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal-overlay custom-modal" v-if="showWarmIntroModal">
      <div class="modal" tabindex="-1" role="dialog" style="display: block; border: 1px solid #eee;">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Make Warm Intros</h5>
            </div>
            <div class="modal-body">
              <div class="form-group mb-2">
                <label for="intro_template_id">Please select a template:</label>
                <v-select name="intro_template_id" v-model="warmIntroForm.template_id" :options="warmIntroTemplates"
                  :reduce="x => x.id" @option:selected="setWarmIntroTemplate" label="name">
                </v-select>
              </div>
              <div class="form-group mb-2">
                <label for="intro_subject">Subject:</label>
                <input id="intro_subject" type="text" name="intro_subject" class="form-control" required
                  v-model="warmIntroForm.subject">
              </div>

              <div class="form-group mb-2">
                <label for="intro_region">Select Region For Country One Liner:</label>
                <v-select name="region" v-model="warmIntroForm.region" :options="optionsRegions" :reduce="x => x.id"
                  label="name" required>
                </v-select>
              </div>
              <div class="form-group mb-2">
                <label for="intro_body">Body:</label>

                <ckeditor :editor="editor" v-model="warmIntroForm.body" :config="editorConfig"></ckeditor>

              </div>
              <!-- Check box to send follow up -->
              <div class="form-check mb-2">
                <input type="checkbox" class="form-check-input" id="send_follow_up"
                  v-model="warmIntroForm.send_followup">
                <label class="form-check ps-0" for="send_follow_up">Send follow up after 7 days</label>
              </div>
              <template v-if="warmIntroForm.send_followup">
                <div class="form-group mb-2">
                  <label for="followup_body">Follow up Body:</label>

                  <ckeditor :editor="editor" v-model="warmIntroForm.followup_body" :config="editorConfig"></ckeditor>


                </div>
              </template>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary" @click.prevent="goGenerateWarmIntros"><i
                  class="uil uil-comment-alt-info me-1"></i> Make Warm Intros</button>
              <button type="button" class="btn btn-secondary" @click.prevent="closeWarmIntroModal">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal-overlay" v-if="showWarmIntroDetailModal">
      <div class="modal" tabindex="-1" role="dialog" style="display: block; border: 1px solid #eee;">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Warm Intro for {{ warmIntro.contact.first_name }}</h5>
            </div>
            <div class="modal-body">
              <div class="pre" v-html="warmIntro.body"></div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary"
                @click.prevent="showWarmIntroDetailModal = false">Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import DOMPurify from 'dompurify'
import MarkdownIt from 'markdown-it'
import CKEditor from '@ckeditor/ckeditor5-vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import Swal from 'sweetalert2'
import CheckKeysMixin from '@/mixins/checkKeys'

export default {
  name: 'personalise',
  components: {
    ckeditor: CKEditor.component
  },

  mixins: [CheckKeysMixin],

  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {

        toolbar: {
          items: [
            'undo', 'redo',
            '|',
            'heading',
            '|',
            'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor',
            '|',
            'bold', 'italic', 'strikethrough', 'subscript', 'superscript', 'code',
            '|',
            'blockQuote', 'codeBlock',
            '|',
            'bulletedList', 'numberedList', 'todoList', 'outdent', 'indent'
          ],
          shouldNotGroupWhenFull: false
        },

      },
      text: '',
      page: 1,
      perPage: 20,
      pollTimer: null,
      error: null,
      showWarmIntroDetailModal: false,
      warmIntro: {
        contact: null,
        subject: null,
        body: null,
        region: null
      },
      showWarmIntroModal: false,
      warmIntroForm: {
        template_id: null,
        name: null,
        subject: null,
        body: null,
        region: null,
        send_followup: false,
        followup_body: null,
      },
      isLoading: false,
      isSubmitting: false,
      isDeleting: false,
      isWarmIntroSubmitting: false,
      isSending: false,
      isCareerSubmitting: false,
      isManagerSubmitting: false,
      isPushingContacts: false,
      editOpenerId: null,
      editOpener: null,
      savingOpener: false,
      editContactOpenerId: null,
      editContactOpener: null,
      savingContactOpener: false,
    }
  },

  computed: {
    ...mapState({
      user: state => state.auth.user,
      startup: state => state.startups.current,
      contacts: state => state.personalise.contacts,
      warmIntroTemplates: state => state.personalise.warmIntroTemplates,
      current: state => state.investors.currentSearch,
      campaigns: state => state.outreach.campaigns,
      searches: state => state.investors.searches,
      optionsRegions: state => state.investors.regions,
      siteCompany: state => state.auth.siteCompany,
    }),
    isDisabled() {
      return !this.form.campaign || this.isSubmitting
    }
  },

  mounted() {
    this.$store.dispatch('LOAD_WARM_INTRO_TEMPLATES', { startupId: this.startup.id })

    if (this.current && this.current.id) {
      this.load()
        .then(() => {
          this.isLoading = true
          this.loadContacts()
            .finally(() => {
              this.isLoading = false
              // Show the confirmation popup after contacts are loaded if it hasn't been shown before
              if (this.current && !this.current.loading_email_openers && !this.current.showed_popup_for_connection_angles) {
                Swal.fire({
                  title: "Generate Connection Angles?",
                  text: "Now identify angles of connections by generating openers for each of the individuals you are targeting via a personalized one liners using our AI researcher.",
                  icon: "question",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Yes, proceed",
                  cancelButtonText: "Cancel"
                }).then((result) => {
                  if (result.isConfirmed) {
                    this.goGenerateContacts()
                    this.updateConnectionAnglesPopupFlag()
                  }
                })
              }

              if (this.current && (this.current.loading_career_openers || this.current.loading_email_openers || this.current.loading_warm_intros)) {
                this.poll()
              }
            })
        })
    }
  },

  beforeUnmount() {
    if (this.pollTimer) {
      clearTimeout(this.pollTimer)
    }
  },

  methods: {

    load() {
      return this.$store.dispatch('LOAD_SEARCH', { startupId: this.startup.id, searchId: this.current.id })
    },

    poll() {
      this.pollTimer = setTimeout(() => {
        this.loadContacts()
        this.load()
          .then(() => {
            if (this.current.loading_email_openers || this.current.loading_career_openers || this.current.loading_warm_intros) {
              this.poll()
            }
          })
      }, 5000)
    },

    loadContacts(pageNumber) {
      if (!pageNumber) {
        pageNumber = this.page
      }
      const offset = (pageNumber - 1) * this.perPage
      const params = { offset }
      return this.$store.dispatch('LOAD_PERSONALISE_CONTACTS', { startupId: this.startup.id, searchId: this.current.id, params })
    },

    onPageChanged(pageNumber) {
      this.loadContacts(pageNumber)
        .then(() => {
          this.page = pageNumber
        })
    },

    openWarmIntroModal() {
      this.showWarmIntroModal = true
    },

    closeWarmIntroModal() {
      this.showWarmIntroModal = false
    },

    goGenerateWarmIntros() {
      this.showWarmIntroModal = false
      this.isWarmIntroSubmitting = true
      const template = {
        template_id: this.warmIntroForm.template_id,
        startup: this.startup.id,
        region: this.warmIntroForm.region,
        name: this.warmIntroForm.name,
        subject: this.warmIntroForm.subject,
        body: this.warmIntroForm.body,
        send_followup: this.warmIntroForm.send_followup,
        followup_body: this.warmIntroForm.followup_body,
      }
      this.$store.dispatch('SAVE_WARM_INTRO_TEMPLATE', template)
        .then((response) => {
          this.$store.dispatch('GENERATE_WARM_INTROS', { startupId: this.startup.id, searchId: this.current.id, templateId: response.data.id })
            .then(() => {
              this.load()
                .then(() => {
                  this.isWarmIntroSubmitting = false
                })
              this.poll()
            })
        })
    },

    async goGenerateContacts() {
      if (!this.siteCompany.is_whitelabel) {
        Swal.fire({
          title: "Generate Email Openers?",
          html: `This will use AI to generate email openers for all contacts in this list.<br><br>
               <strong>Please ensure you have sufficient funds in your AI account.</strong>`,
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, I have enough credits!",
          cancelButtonText: "Cancel"
        }).then((result) => {
          if (result.isConfirmed) {
            this.isSubmitting = true
            
            // Update the connection angles popup flag when generating openers
            this.updateConnectionAnglesPopupFlag()
            
            this.$store.dispatch('GENERATE_CONTACT_EMAIL_OPENERS', { startupId: this.startup.id, searchId: this.current.id })
              .then(() => {
                Swal.fire({
                  title: "Job Triggered!",
                  text: "A background job has been triggered to generate email openers. Please check back later.",
                  icon: "success"
                })
                this.load()
                  .then(() => {
                    this.isSubmitting = false
                  })
                this.poll()
              })
              .catch((error) => {
                console.error(error)
                Swal.fire({
                  title: "Error",
                  text: error.response?.data?.error || "Failed to trigger email opener generation.",
                  icon: "error"
                })
                this.isSubmitting = false
              })
          }
        })
        return
      }
      
      // whitelabel accounts need to check for keys
      let shouldConclude = await this.checkKeys()
      if (shouldConclude) {
        return
      }
      
      Swal.fire({
        title: "Generate Email Openers?",
        html: `This will use AI to generate email openers for all contacts in this list.<br><br>
              <strong>Please ensure you have sufficient funds in your AI account.</strong>`,
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, I have enough credits!",
        cancelButtonText: "Cancel"
      }).then((result) => {
        if (result.isConfirmed) {
          this.isSubmitting = true
          
          // Update the connection angles popup flag when generating openers
          this.updateConnectionAnglesPopupFlag()
          
          this.$store.dispatch('GENERATE_CONTACT_EMAIL_OPENERS', { startupId: this.startup.id, searchId: this.current.id })
            .then(() => {
              Swal.fire({
                title: "Job Triggered!",
                text: "A background job has been triggered to generate email openers. Please check back later.",
                icon: "success"
              })
              this.load()
                .then(() => {
                  this.isSubmitting = false
                })
            })
            .catch((error) => {
              console.error(error)
              Swal.fire({
                title: "Error",
                text: error.response?.data?.error || "Failed to trigger email opener generation.",
                icon: "error"
              })
              this.isSubmitting = false
            })
        }
      })
    },

    goGenerateCareers() {
      this.isCareerSubmitting = true
      this.$store.dispatch('GENERATE_CAREER_EMAIL_OPENERS', { startupId: this.startup.id, searchId: this.current.id })
        .then(() => {
          this.load()
            .then(() => {
              this.isCareerSubmitting = false
            })
          this.poll()
        })
    },

    goGenerateManager() {
      Swal.fire({
        title: "Generate Manager Openers?",
        html: `This will use AI to generate manager openers for all contacts in this list.<br><br>
             <strong>Please ensure you have sufficient funds in your AI account.</strong>`,
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, I have enough credits!",
        cancelButtonText: "Cancel"
      }).then((result) => {
        if (result.isConfirmed) {
          this.isManagerSubmitting = true
          this.$store.dispatch('GENERATE_MANAGER_EMAIL_OPENERS', { startupId: this.startup.id, searchId: this.current.id })
            .then(() => {
              Swal.fire({
                title: "Job Triggered!",
                text: "A background job has been triggered to generate manager openers. Please check back later.",
                icon: "success"
              })
              this.load()
                .then(() => {
                  this.isManagerSubmitting = false
                })
              this.poll()
            })
            .catch((error) => {
              console.error(error)
              Swal.fire({
                title: "Error",
                text: error.response?.data?.error || "Failed to trigger manager opener generation.",
                icon: "error"
              })
              this.isManagerSubmitting = false
            })
        }
      })
    },

    goSendWarmIntros() {
      // confirm first
      if (!confirm('Are you sure you want to send warm intros?\n\nThis will send warm intro emails to all contacts in the list.')) {
        return
      }
      this.isSending = true
      this.$store.dispatch('SEND_WARM_INTROS', { startupId: this.startup.id, searchId: this.current.id })
        .then(() => {
          this.load()
            .then(() => {
              this.isSending = false
            })
        })
    },

    goCreateContactsApollo() {
      // confirm first
      if (!confirm('Are you sure you want to push contacts to Apollo.io?\n\nThis will push all contacts to the apollo.io.')) {
        return
      }
      this.isPushingContacts = true
      this.$store.dispatch('CREATE_CONTACTS_TO_APOLLO', this.current.id)
        .then(() => {
          this.load()
            .then(() => {
              this.isPushingContacts = false
            })
        })
    },

    goEnrichContactsApollo() {
      // confirm first
      if (!confirm('Are you sure you want to enrich contacts with Apollo.io?\n\nThis will enrich all contacts with apollo.io.')) {
        return
      }
      this.isPushingContacts = true
      this.$store.dispatch('ENRICH_CONTACTS_WITH_APOLLO', this.current.id)
        .then(() => {
          this.load()
            .then(() => {
              this.isPushingContacts = false
            })
        })
    },

    setEditOpener(contact) {
      if (contact) {
        this.editOpener = contact.email_opener
        this.editOpenerId = contact.id
      } else {
        this.editOpener = null
        this.editOpenerId = null
      }
    },

    saveOpener(contact) {
      const payload = {
        startup: this.startup.id,
        company: contact.company.id,
        content: this.editOpener,
        false_content: false,
      }
      this.savingOpener = true
      this.$store.dispatch('UPDATE_EMAIL_OPENER', payload)
        .then(() => {
          this.loadContacts()
            .then(() => {
              this.setEditOpener(null)
              this.savingOpener = false
            })
        })
    },

    setEditContactOpener(contact) {
      if (contact) {
        this.editContactOpener = contact.contact_email_opener
        this.editContactOpenerId = contact.id
      } else {
        this.editContactOpener = null
        this.editContactOpenerId = null
      }
    },

    saveContactOpener(contact) {
      const payload = {
        startup: this.startup.id,
        contact: contact.id,
        content: this.editContactOpener,
        false_content: false,
      }
      this.savingContactOpener = true
      this.$store.dispatch('UPDATE_CONTACT_EMAIL_OPENER', payload)
        .then(() => {
          this.loadContacts()
            .then(() => {
              this.setEditContactOpener(null)
              this.savingContactOpener = false
            })
        })
    },

    goNext() {
      this.$router.push({ name: 'investors_outreach' })
    },

    setWarmIntroTemplate() {
      if (!this.warmIntroForm.template_id) {
        return
      }
      this.warmIntroForm.name = this.warmIntroTemplates.find(x => x.id === this.warmIntroForm.template_id).name
      this.warmIntroForm.subject = this.warmIntroTemplates.find(x => x.id === this.warmIntroForm.template_id).subject
      this.warmIntroForm.body = this.warmIntroTemplates.find(x => x.id === this.warmIntroForm.template_id).body
      this.warmIntroForm.region = this.warmIntroTemplates.find(x => x.id === this.warmIntroForm.template_id).region
      this.warmIntroForm.send_followup = this.warmIntroTemplates.find(x => x.id === this.warmIntroForm.template_id).send_followup
      this.warmIntroForm.followup_body = this.warmIntroTemplates.find(x => x.id === this.warmIntroForm.template_id).followup_body
    },

    openWarmIntroDetailModal(contact) {
      this.warmIntro.contact = contact
      this.warmIntro.subject = contact.warm_intro.subject
      this.warmIntro.region = contact.warm_intro.region
      // const md = MarkdownIt()
      // const body = md.render(contact.warm_intro.body)
      this.warmIntro.body = DOMPurify.sanitize(contact.warm_intro.body)
      this.showWarmIntroDetailModal = true
    },

    getOpenerCount(openerType) {
      if (this.contacts && this.contacts.results) {
        return this.contacts.results.filter(contact => contact[openerType]).length
      }
      return 0
    },

    getWarmIntroCount() {
      if (this.contacts && this.contacts.results) {
        return this.contacts.results.filter(contact => contact.warm_intro).length
      }
      return 0
    },

    async downloadCsv() {
      // Indicate the generation process has started
      this.current.generatingCsv = true
      await this.generateCsv()

      setTimeout(async () => {
        await this.generateCsv()
          .then((res_url) => {
            //  After the CSV is generated, initiate the download

            window.open(this.current.file_downloaded, '_blank')
            this.current.generatingCsv = false

          })

      }, 2000)
    },

    generateCsv() {
      return new Promise((resolve, reject) => {
        // First call to generate CSV
        this.$store.dispatch('GENERATE_SEARCH_CSV', { startupId: this.startup.id, searchId: this.current.id })
          .then(() => {
            // Second call to load the updated search
            return this.$store.dispatch('LOAD_SEARCH', { startupId: this.startup.id, searchId: this.current.id })
          })
          .then((response) => {
            resolve(response.data.file_downloaded)
          })
          .catch((error) => {
            this.errors.set(this.current.id, error.response ? error.response.data : error.message)
            reject(error); // Reject the promise if there is an error
          })
      })
    },

    goDeleteContact(contact) {
      if (confirm('Are you sure you want to delete this contact from saved list?')) {
        this.isDeleting = true
        this.$store.dispatch('REMOVE_SAVED_SEARCH_CONTACT', { searchId: this.current.id, contactId: contact })
          .then(() => {
            this.loadContacts()
              .then(() => {
                this.isDeleting = false
              })
          })
      }
    },

    updateConnectionAnglesPopupFlag() {
      // Update the showed_popup_for_connection_angles timestamp
      if (this.current && this.current.id) {
        this.$store.dispatch('UPDATE_SEARCH_CONNECTION_ANGLES_POPUP_FLAG', { 
          startupId: this.startup.id, 
          searchId: this.current.id 
        })
          .then((response) => {
            // Update local state to reflect the change
            if (this.current) {
              this.current.showed_popup_for_connection_angles = response.data.showed_popup_for_connection_angles || new Date().toISOString();
            }
          })
          .catch((error) => {
            console.error("Error updating connection angles popup flag:", error)
          })
      }
    },

  },

}
</script>
