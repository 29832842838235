<template>
    <div class="mt-4 ml-2 mr-2">
      <div class="card w-100">
        <div class="card-header d-flex justify-content-between align-items-center">
          <h1 class="mb-0">Pitch Deck Analysis Reports</h1>
          <button class="btn btn-outline-primary" @click="loadReports" :disabled="isRefreshing">
            <span v-if="isRefreshing" class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
            <i v-else class="uil uil-sync me-1"></i>
            Refresh
          </button>
        </div>
        <div class="card-body">
          <div v-if="isLoading" class="text-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          <div v-else-if="error" class="alert alert-danger">Error: {{ error }}</div>
          <div v-else>
            <table class="table table-hover table-fixed w-100">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Pitch Deck</th>
                  <th>Score</th>
                  <th>Status</th>
                  <th>Created</th>
                  <th>Last Updated</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="report in reports?.results" :key="report.id">
                  <td>{{ report.id }}</td>
                  <td>{{ report.name }}</td>
                  <td>
                    <a :href="`${report.pitchdeck}`" class="" target="_blank">View Pitch Deck</a>
                  </td>
                  <td>
                    <span class="badge bg-primary" v-if="report?.score">{{ report?.score }}%</span>
                  </td>
                  <td>
                    <span :class="getStatusBadgeClass(report.status)">
                      {{ formatStatus(report.status) }}
                    </span>
                  </td>
                  <td>{{ $filters.datetimeformat(report.created_at) }}</td>
                  <td>{{ $filters.datetimeformat(report.updated_at) }}</td>
                  <td>
                    <router-link 
                      :to="`/pitch-deck/reports/${report.id}`" 
                      class="btn btn-primary me-2 m-1"
                      :class="{ 'disabled': !isReportViewable(report.status) }"
                      :tabindex="!isReportViewable(report.status) ? -1 : undefined"
                      :aria-disabled="!isReportViewable(report.status)"
                    >View Report</router-link>
                    <router-link 
                      :to="`/pitch-deck/reports/${report.id}/summary`" 
                      class="btn btn-info me-2 m-1"
                      :class="{ 'disabled': !isReportViewable(report.status) }"
                      :tabindex="!isReportViewable(report.status) ? -1 : undefined"
                      :aria-disabled="!isReportViewable(report.status)"
                    >Report Summary</router-link>
                    <button 
                      class="btn btn-outline-primary me-2 m-1" 
                      @click="reanalyze(report.id)"
                      :disabled="!isReanalyzable(report.status)"
                    >Re-Analyze</button>
                  </td>
                </tr>
              </tbody>
            </table>

            <pagination
              :items="reports"
              :currentPage="page"
              :perPage="perPage"
              @pagechanged="onPageChanged"
            >
            </pagination>
          </div>
        </div>
      </div>
    </div>
  </template>
  
<script>
import { mapState } from 'vuex'

export default {
  name: 'DealAnalysisReports',
  data() {
    return {
      refreshInterval: null,
      page: 1,
      perPage: 15,
      isLoading: false,
      isRefreshing: false,
      error: null,
      selectedReport: null,
      modal: null,
      statusMap: {
        'not_started': { class: 'bg-secondary', text: 'Not Started' },
        'initiated': { class: 'bg-info', text: 'Initiated' },
        'researching': { class: 'bg-info', text: 'Researching' },
        'initial_due_diligence': { class: 'bg-primary', text: 'Initial Due Diligence' },
        'verification_sources': { class: 'bg-warning', text: 'Verification Sources' },
        'decision_stage': { class: 'bg-info', text: 'Decision Stage' },
        'generating_conclusion': { class: 'bg-primary', text: 'Generating Conclusion' },
        'completed': { class: 'bg-success', text: 'Completed' },
        'failed': { class: 'bg-danger', text: 'Failed' },
        'cancelled': { class: 'bg-danger', text: 'Cancelled' }
      }
    }
  },
  computed: {
    ...mapState({
      reports: state => state.dealAnalyser.analysisHistory,
    }),
  },
  mounted() {
    this.$store.dispatch('LOAD_DEAL_ANALYSIS_HISTORY')
    this.startRefreshInterval()
  },
  beforeUnmount() {
    this.clearRefreshInterval()
  },
  methods:{
    loadReports(){
      if (!this.reports?.results) {
        this.isLoading = true;
        this.$store.dispatch('LOAD_DEAL_ANALYSIS_HISTORY')
          .then(() => {
            this.isLoading = false;
            this.isRefreshing = false;
          })
          .catch(error => {
            this.isLoading = false;
            this.isRefreshing = false;
            this.error = error.message || 'An error occurred while loading reports';
          });
      } else {
        this.isRefreshing = true;
        this.$store.dispatch('LOAD_DEAL_ANALYSIS_HISTORY')
          .then((response) => {
            if (response?.results) {
              this.reports.results = this.reports.results.map(existingReport => {
                const updatedReport = response.results.find(r => r.id === existingReport.id);
                if (updatedReport) {
                  return {
                    ...existingReport,
                    status: updatedReport.status,
                    updated_at: updatedReport.updated_at
                  };
                }
                return existingReport;
              });
            }
            this.isRefreshing = false;
          })
          .catch(error => {
            this.isRefreshing = false;
            this.error = error.message || 'An error occurred while refreshing reports';
          });
      }
    },
    onPageChanged(pageNumber) {
      this.page = pageNumber
      this.loadReports()
      this.clearRefreshInterval()
      this.startRefreshInterval()
    },
    getStatusBadgeClass(status) {
      return `badge ${this.statusMap[status]?.class || 'bg-secondary'}`
    },
    formatStatus(status) {
      return this.statusMap[status]?.text || status
    },
    isReportViewable(status) {
      return status === 'completed';
    },
    isReanalyzable(status) {
      return status === 'completed' || status === 'failed' || status === 'cancelled';
    },
    reanalyze(id) {
      this.$store.dispatch('RERUN_DEAL_ANALYSIS', id);
    },
    startRefreshInterval() {
      this.clearRefreshInterval()
      console.log('starting refresh interval for reports')
      this.refreshInterval = setInterval(() => {
        this.loadReports();
      }, 5000);
      console.log('started refresh interval for reports')
    },
    clearRefreshInterval() {
      if (this.refreshInterval) {
        clearInterval(this.refreshInterval)
        this.refreshInterval = null
      }
    },
  }
}
</script>
  
  <style scoped>
  .nav-tabs .nav-link {
    color: #495057;
  }
  
  .nav-tabs .nav-link.active {
    font-weight: bold;
  }

  .container-fluid {
    padding: 0 2rem;
  }

  .table {
    width: 100%;
    table-layout: fixed;
  }

  .table th:nth-child(1) {  /* ID column */
    width: 5%;
  }

  .table th:nth-child(2) {  /* Name column */
    width: 10%;
  }

  .table th:nth-child(7) {  /* Actions column */
    width: 20%;
  }

  .badge {
    font-size: 0.875rem;
    padding: 0.5em 0.75em;
  }

</style>
  