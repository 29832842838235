<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-12">

            <template v-if="hasReplyIoKey">
              <template v-if="campaign">
                <a href="#" @click.prevent="selectCampaign(null)" class="btn btn-outline-primary mb-3">
                  <i class="uil uil-arrow-left me-1"></i>
                  Back to Campaigns
                </a>
                <div v-if="current?.push_started">
                  <div class="card">
                    <div class="card-body">
                      <h3>Setting up campaign</h3>
                      <p>
                        Please wait while we push the contacts to your campaign.
                      </p>
                      <div class="mb-3">
                        <loader size="32px"></loader>
                      </div>
                      <div v-if="!current.push_in_progress">
                        Starting...
                      </div>
                      <div v-else>
                        Talking to API...
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="campaign">
                  <div class="card-body">
                    <!-- Campaign Details Table -->
                    <div>
                      <h5 class="d-flex justify-content-between align-items-center">
                        Campaign Details
                        <div>
                          <button 
                            v-if="['paused', 'new'].includes(campaign.status?.toLowerCase())"
                            class="btn btn-success btn-sm"
                            @click="toggleCampaignStatus('active')"
                          >
                            <i class="uil uil-play me-1"></i>Activate Campaign
                          </button>
                          <button 
                            v-if="['paused', 'new'].includes(campaign.status?.toLowerCase())"
                            class="btn btn-outline-danger btn-sm ms-2"
                            @click="archiveCampaign"
                          >
                            <i class="uil uil-archive me-1"></i>Archive Campaign
                          </button>
                          <button 
                            v-else-if="campaign.status?.toLowerCase() === 'active'"
                            class="btn btn-warning btn-sm"
                            @click="toggleCampaignStatus('paused')"
                          >
                            <i class="uil uil-pause me-1"></i>Stop Campaign
                          </button>
                        </div>
                      </h5>
                      <div class="table-responsive">
                        <table class="table table-sm">
                          <tbody>
                            <tr>
                              <th width="150">Name:</th>
                              <td>{{ campaign.name }}</td>
                            </tr>
                            <tr>
                              <th>Status:</th>
                              <td><span class="badge bg-secondary">{{ campaign.status }}</span></td>
                            </tr>
                            <tr>
                              <th>Email Account:</th>
                              <td>{{ campaign.emailAccount }}</td>
                            </tr>
                            <tr>
                              <th colspan="2" class="table-light">Settings</th>
                            </tr>
                            <tr>
                              <th>Emails per day:</th>
                              <td>{{ campaign.settings.emailsCountPerDay }}</td>
                            </tr>
                            <tr>
                              <th>Days to finish:</th>
                              <td>{{ campaign.settings.daysToFinishProspect }}</td>
                            </tr>
                            <tr>
                              <th>Sending delay:</th>
                              <td>{{ campaign.settings.emailSendingDelaySeconds }}s</td>
                            </tr>
                            <tr>
                              <th>Daily throttling:</th>
                              <td>{{ campaign.settings.dailyThrottling }}</td>
                            </tr>
                            <tr>
                              <th>Replies handling:</th>
                              <td>{{ campaign.settings.repliesHandlingType }}</td>
                            </tr>
                            <tr>
                              <th>Tracking:</th>
                              <td>
                                <div>Opens: {{ campaign.settings.disableOpensTracking ? 'Disabled' : 'Enabled' }}
                                </div>
                                <div>Links: {{ campaign.settings.enableLinksTracking ? 'Enabled' : 'Disabled' }}
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <!-- Campaign Steps -->
                    <div class="mt-4">
                      <h5>Campaign Steps</h5>
                      <div class="campaign-steps">
                        <div v-for="step in campaign.steps" :key="step.id"
                          class="step-card mb-3 p-3 border rounded">
                          <div class="d-flex align-items-center mb-2">
                            <div class="me-2">
                              <i class="uil uil-envelope-alt text-primary fs-4"></i>
                            </div>
                            <div class="flex-grow-1">
                              <h6 class="mb-0">Step {{ step.displayName }}</h6>
                              <small class="text-muted">
                                {{ formatWaitTime(step.inMinutesCount) }}
                              </small>
                            </div>
                          </div>

                          <div v-for="template in step.templates" :key="template.id"
                            class="email-template p-2 border rounded bg-light cursor-pointer" 
                            @click="openTemplateModal(step, template)"
                          >
                            <div class="d-flex align-items-center">
                              <div class="me-2">
                                <i class="uil uil-envelope"></i>
                              </div>
                              <div class="text-truncate">{{ template.subject }}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div v-if="contacts && contacts.results && contacts.results.length" class="mt-4">
                      <h5>Contacts</h5>
                      <div class="table-data">
                        <div class="table-container">
                          <table class="table table-hover">
                            <thead>
                              <tr>
                                <th class="orderable">
                                  Name
                                </th>
                                <th class="orderable">
                                  Email
                                </th>
                                <th class="orderable">
                                  Timestamp
                                </th>
                                <th>
                                  Status
                                </th>
                              </tr>
                            </thead>

                            <tbody>
                              <tr v-for="contact in contacts.results" :key="contact.id">
                                <td>
                                  {{ contact.investors_contact.first_name }} {{ contact.investors_contact.last_name }}
                                </td>
                                <td>
                                  {{ contact.investors_contact.email }}
                                </td>
                                <td>
                                  {{ $filters.datetimeformat(contact.created_date) }}
                                </td>
                                <td>
                                  <td>
                                    <span class="badge badge-secondary-lighten me-2" v-if="contact.pushed_to_reply_io">Pushed</span>
                                    <span class="badge badge-secondary-lighten me-2" v-if="contact.email_sent">Email sent</span>
                                    <span class="badge badge-primary-lighten me-2" v-if="contact.email_opened">Email opened</span>
                                    <span class="badge badge-success-lighten me-2" v-if="contact.email_replied">Replied</span>
                                    <template v-if="contact.error_message">
                                      <span class="badge badge-danger-lighten me-2">Error</span>
                                      <br>
                                      <span class="text-red small" v-if="contact.error_message">
                                        {{ contact.error_message }}
                                      </span>
                                    </template>
                                  </td>
                                </td>
                              </tr>
                            </tbody>
                          </table>

                          <pagination :items="contacts" :currentPage="page" :perPage="perPage"
                            @pagechanged="onPageChanged">
                          </pagination>

                        </div>
                      </div>
                    </div>

                    <!-- Email Template Modal -->
                    <div class="modal fade" id="emailTemplateModal" tabindex="-1">
                      <div class="modal-dialog modal-lg">
                        <div class="modal-content" v-if="selectedTemplate">
                          <div class="modal-header">
                            <h5 class="modal-title">Email Template - Step {{ selectedStep?.displayName }}</h5>
                            <div class="ms-auto">
                              <button 
                                v-if="!isEditing" 
                                class="btn btn-sm btn-primary me-2" 
                                @click="startEditing"
                              >
                                <i class="uil uil-edit"></i> Edit
                              </button>
                              <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                            </div>
                          </div>
                          <div class="modal-body">
                            <div class="mb-3">
                              <label class="form-label fw-bold">Subject:</label>
                              <div v-if="!isEditing">{{ selectedTemplate.subject }}</div>
                              <input 
                                v-else 
                                type="text" 
                                class="form-control" 
                                v-model="editedTemplate.subject"
                                placeholder="Enter email subject"
                              >
                            </div>
                            <div class="mb-3">
                              <label class="form-label fw-bold">Wait Time:</label>
                              <div v-if="!isEditing">{{ formatWaitTime(selectedStep?.inMinutesCount) }}</div>
                              <div v-else>
                                <div class="row g-2">
                                  <div class="col-auto">
                                    <div class="input-group">
                                      <input 
                                        type="number" 
                                        class="form-control" 
                                        v-model.number="editedTemplate.days"
                                        min="0"
                                        @input="updateMinutesFromDays"
                                      >
                                      <span class="input-group-text">days</span>
                                    </div>
                                  </div>
                                  <div class="col-auto">
                                    <div class="input-group">
                                      <input 
                                        type="number" 
                                        class="form-control" 
                                        v-model.number="editedTemplate.hours"
                                        min="0"
                                        max="23"
                                        @input="updateMinutesFromHours"
                                      >
                                      <span class="input-group-text">hours</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <label class="form-label fw-bold">Body:</label>
                              <div v-if="!isEditing" class="border rounded p-3 bg-light">
                                <pre class="mb-0" style="white-space: pre-wrap;">{{ selectedTemplate.body }}</pre>
                              </div>
                              <textarea 
                                v-else 
                                class="form-control" 
                                rows="10" 
                                v-model="editedTemplate.body"
                                placeholder="Enter email body"
                              ></textarea>
                            </div>
                          </div>
                          <div class="modal-footer" v-if="isEditing">
                            <button 
                              class="btn btn-secondary" 
                              @click="cancelEditing"
                            >
                              Cancel
                            </button>
                            <button 
                              class="btn btn-primary" 
                              @click="updateTemplate"
                              :disabled="isSubmitting"
                            >
                              <span v-if="!isSubmitting">Update Template</span>
                              <loader v-else size="20px"></loader>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>

              <template v-else>
                <template v-if="campaignsLoaded">
                  <h3>Campaigns</h3>
                  <p>
                    Select a campaign to send emails to your contacts.
                  </p>
                  <div class="card">
                    <div class="card-body">
                      <table class="table table-sm table-hover" v-if="campaigns.length">
                        <thead>
                          <tr>
                            <th>Status</th>
                            <th>Name</th>
                            <th>People</th>
                            <th>Open Rate</th>
                            <th>Reply Rate</th>
                            <th>Progress</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in campaigns" :key="item.id" @click="selectCampaign(item)" class="cursor-pointer">
                            <td>
                              <button 
                                v-if="[0, 4].includes(item.status)"
                                class="btn btn-success btn-sm"
                                @click.stop="toggleCampaignStatus('active')"
                              >
                                <i class="uil uil-play me-1"></i>Start
                              </button>
                              <button 
                                v-if="item.status === 2"
                                class="btn btn-warning btn-sm"
                                @click.stop="toggleCampaignStatus('paused')"
                              >
                                <i class="uil uil-pause me-1"></i>Stop
                              </button>
                            </td>
                            <td>
                              {{ item.name }}
                            </td>
                            <td>{{ item.peopleCount }}</td>
                            <td>{{ $filters.round(item.opensCount / item.peopleCount, 0) }}</td>
                            <td>{{ $filters.round(item.repliesCount / item.peopleCount, 0) }}</td>
                            <td>{{ $filters.round(item.peopleFinished * 100 / item.peopleCount, 0) }}%</td>
                            <td>
                              <button class="btn btn-outline-primary btn-sm" @click.stop="selectCampaign(item)">View</button>
                              <button 
                                class="btn btn-outline-info btn-sm ms-2" 
                                @click.stop="viewCampaignStats(item)"
                              >
                                <i class="uil uil-chart me-1"></i>Report
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div v-else>
                        <p>No campaigns yet.</p>
                        <p class="text-muted">Create a campaign to send emails to your contacts.</p>
                      </div>
                    
                      <a href="#" @click.prevent="showCreateCampaign = true" v-if="!showCreateCampaign" class="fw-bold">+ Create a New Campaign</a>
                      <div class="mt-3 col-6" v-if="showCreateCampaign || !campaigns.length">
                        <div class="form-group mb-2">
                          <h5>Create New Campaign</h5>
                          <label for="id_search">Select a Saved Search:</label>

                          <v-select
                            name="id_search"
                            v-model="form.search"
                            :options="searches.results"
                            :reduce="x => x.id"
                            label="saved_search_name"
                          ></v-select>
                        </div>
                        <a v-if="!isSubmitting" href="#" class="btn btn-outline-primary" @click.prevent="doCreateReplyIoCampaign">
                          <i class="uil uil-envelope-add"></i>
                          Create New Campaign
                        </a>
                        <loader v-else size="32px"></loader>
                        <p v-if="error && error.detail" class="text-danger">
                          {{ error.detail }}
                        </p>
                      </div>
                    </div>
                  </div>
                </template>
                <div v-else>
                  <loader></loader>
                </div>
              </template>
            </template>

            <div v-else>
              <div class="card mb-3">
                <div class="card-body">
                  <div v-if="startup.generated_email_intro">
                    <h3>Example Outreach Email</h3>
                    <pre>
{{ startup.generated_email_intro }}
                    </pre>
                  </div>
                  <div v-else>
                    <h3>Example Outreach Email</h3>
                    <p>
                      No email intro has been created yet. Please create one in the <a href="#" @click.prevent="goEmailIntro">Email Intro</a> section.
                    </p>
                    <a href="#" @click.prevent="goEmailIntro" class="btn btn-primary">
                      <i class="uil uil-comment-alt-info me-1"></i>
                      Generate Email Intro
                    </a>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-body">
                  <h3>Connect</h3>
                  <p>
                    Connect with your contacts and send them introductions.
                  </p>
                  <a href="#" class="btn btn-primary" @click.prevent="doCreateReplyIoAccount" v-if="!isSubmitting">
                    <i class="uil uil-envelope-add"></i>
                    Connect with the contacts
                  </a>
                <loader v-else size="32px"></loader>
              </div>
            </div>
          </div>

          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { Modal } from 'bootstrap'
import { mapState } from 'vuex'
import Swal from 'sweetalert2'

export default {
  name: 'outreach',

  data() {
    return {
      page: 1,
      perPage: 20,
      form: {
        search: null,
      },
      campaignsLoaded: false,
      pollTimer: null,
      error: null,
      isSubmitting: false,
      selectedStep: null,
      selectedTemplate: null,
      isEditing: false,
      showCreateCampaign: false,
      editedTemplate: {
        subject: '',
        body: '',
        days: 0,
        hours: 0,
        inMinutesCount: 0
      },
      modal: null
    }
  },

  computed: {
    ...mapState({
      searches: state => state.investors.searches,
      startup: state => state.startups.current,
      contacts: state => state.outreach.contacts,
      current: state => state.investors.currentSearch,
      campaigns: state => state.outreach.campaigns,
      campaign: state => state.outreach.selectedCampaign,
      replyIoKey: state => state.auth.replyIoKey,
      searches: state => state.investors.searches,
      siteCompany: state => state.auth.siteCompany,
    }),
    hasReplyIoKey() {
      return !!this.replyIoKey.reply_io_api_key
    }
  },

  mounted() {
    this.loadStartup()
    this.loadCampaigns()
    this.initialize()
    
    // Check for missing email templates after loading startup
    this.checkMissingEmailTemplates()
  },

  beforeUnmount() {
    if (this.pollTimer) {
      clearTimeout(this.pollTimer)
    }
    if (this.modal) {
      this.modal.dispose()
    }
  },

  methods: {

    initialize() {
      this.$store.dispatch('LOAD_SEARCHES', { startupId: this.startup.id })
        .then(() => {
          if (this.current && this.current.id && !this.current.replyio_campaign_id) {
            this.form.search = this.current.id
          }
        })
      if (this.current && this.current.id) {
        this.load()
          .then(() => {
            if (this.current && this.current.push_started) {
              this.poll()
            }
            if (this.current && this.current.pushed_to_replyio) {
              this.loadContacts()
            }
          })
      }
    },

    load() {
      return this.$store.dispatch('LOAD_SEARCH', { startupId: this.startup.id, searchId: this.current.id })
    },

    poll() {
      this.pollTimer = setTimeout(() => {
        this.load()
          .then(() => {
            if (this.current.push_started) {
              this.poll()
            }
            if (this.current.pushed_to_replyio) {
              this.loadContacts()
            }
          })
      }, 5000)
    },

    loadStartup() {
      this.$store.dispatch('LOAD_STARTUP', this.startup.id)
    },

    loadCurrentCampaign() {
      this.$store.dispatch('LOAD_REPLY_IO_CAMPAIGN', { startupId: this.startup.id, searchId: this.current.id })
    },

    loadCampaigns() {
      this.$store.dispatch('LOAD_REPLY_IO_KEY', this.startup.id)
        .then(() => {
          if (this.hasReplyIoKey) {
            this.$store.dispatch('LOAD_REPLY_IO_CAMPAIGNS', this.startup.id)
              .then(() => {
                this.load()
              })
              .catch((error) => {
                this.error = error.response?.data || error
                this.$store.commit('SET_SELECTED_CAMPAIGN', null)
              })
              .finally(() => {
                this.campaignsLoaded = true
              })
          }
        })
    },

    loadContacts(pageNumber = 1) {
      const offset = (pageNumber - 1) * this.perPage
      const params = { offset }
      return this.$store.dispatch('LOAD_OUTREACH_CONTACTS', { startupId: this.startup.id, searchId: this.current.id, params })
    },

    selectCampaign(item) {
      if (!item) {
        this.$store.commit('SET_SELECTED_CAMPAIGN', null)
        return
      }
      const search = this.searches.results.find(search => parseInt(search.replyio_campaign_id) === item.id)
      if (!search) {
        return
      }
      this.campaignsLoaded = false
      const searchId = search.id
      this.setCurrent(search)
      this.$store.dispatch('LOAD_REPLY_IO_CAMPAIGN', { startupId: this.startup.id, searchId: searchId })
        .finally(() => {
          this.campaignsLoaded = true
        })
    },

    setCurrent(search) {
      if (search) {
        this.$store.commit('SET_PERSONALISE_CONTACTS', null)
        this.$store.commit('SET_CURRENT_SEARCH', search)
        this.loadContacts()
        if(search?.is_astel_search){
          this.$store.dispatch('LOAD_ASTEL_CURRENT_SEARCH_AND_FETCH_FIELDS', { startupId: this.startup.id, search });
        } else{
          this.$store.dispatch('LOAD_CURRENT_SEARCH_AND_FETCH_FIELDS', { startupId: this.startup.id, search });
        }
      } else {
        this.$store.commit('SET_PERSONALISE_CONTACTS', null)
        this.$store.commit('SET_CURRENT_SEARCH', null)
      }
    },

    onPageChanged(pageNumber) {
      this.loadContacts(pageNumber)
        .then(() => {
          this.page = pageNumber
        })
    },

    goReplyIoKey() {
      this.$router.push({ name: 'reply-io-key' })
    },

    goEmailIntro() {
      this.$router.push(`/startups/${this.startup.id}/emailintro`)
    },

    doCreateReplyIoAccount() {
      // Check if whitelabel and trial status
      if (this.siteCompany.is_whitelabel && this.siteCompany.status === 'TRIAL') {
        Swal.fire({
          title: "Paid Feature",
          html: `This is a premium feature only available to paid users.<br><br>
                Please contact Muboriz at <a href="mailto:muboriz.m@astelventures.com">muboriz.m@astelventures.com</a> to upgrade your account.`,
          icon: "info",
          confirmButtonText: "OK",
          confirmButtonColor: "#3085d6"
        });
        return;
      }

      this.isSubmitting = true;
      this.$store.dispatch('CREATE_REPLY_IO_USER', this.startup.id)
        .then(() => {
          this.loadCampaigns();
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },

    doCreateReplyIoCampaign() {
      const searchId = this.form.search
      if (!searchId) {
        return
      }
      this.isSubmitting = true
      this.$store.dispatch('CREATE_REPLY_IO_CAMPAIGN', { startupId: this.startup.id, searchId })
        .then(() => {
          this.campaignsLoaded = false
          this.loadCampaigns()
          this.initialize()
        })
        .catch((error) => {
          if (error.response.data?.email_connect_url?.url) {
            var text = "Please connect your email account so we can send emails to your contacts."
            Swal.fire({
                title: "Connect your email account",
                html: text,
                loaderHtml: text,
                icon: "question",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Connect Email Account"
              }).then((result) => {
                if (result.isConfirmed) {
                  // open connect URL
                  window.open(error.response.data.email_connect_url.url, '_blank')
                }
              });
          } else {
            this.error = error.response.data
          }
        })
        .finally(() => {
          this.isSubmitting = false
        })
    },

    start() {
      const payload = {
        startupId: this.startup.id,
        searchId: this.current.id,
        campaignId: this.campaign?.id,
        campaignName: this.campaign?.name,
        customFields: null
      }
      this.error = null
      this.isSubmitting = true

      this.$store.dispatch('START_OUTREACH', payload)
        .then(() => {
          this.load()
          this.poll()
        })
        .catch((error) => {
          this.error = error.response.data
        })
        .finally(() => {
          this.isSubmitting = false
        })
    },

    archiveCampaign() {
      this.isSubmitting = true
      this.$store.dispatch('ARCHIVE_CAMPAIGN', { startupId: this.startup.id, searchId: this.current.id })
        .then(() => {
          this.selectCampaign(null)
          this.loadCampaigns()
        })
        .catch((error) => {
          this.error = error.response.data
        })
        .finally(() => {
          this.isSubmitting = false
        })
    },

    toggleCampaignStatus(newStatus) {
      const payload = {
        startupId: this.startup.id,
        searchId: this.current.id
      };

      const action = newStatus === 'active' ? 'START_CAMPAIGN' : 'PAUSE_CAMPAIGN';
      
      this.$store.dispatch(action, payload)
        .then(() => {
          Swal.fire({
            title: 'Success!',
            text: `Campaign ${newStatus === 'active' ? 'activated' : 'paused'} successfully`,
            icon: 'success',
            confirmButtonColor: '#3085d6'
          });
        })
        .catch((error) => {
          Swal.fire({
            title: 'Error',
            text: 'Failed to update campaign status',
            icon: 'error',
            confirmButtonColor: '#d33'
          });
          console.error('Failed to update campaign status:', error);
        });
    },

    openTemplateModal(step, template) {
      this.selectedStep = step
      this.selectedTemplate = template
      if (!this.modal) {
        this.modal = new Modal(document.getElementById('emailTemplateModal'))
      }
      this.modal.show()
    },

    closeTemplateModal() {
      if (this.modal) {
        this.modal.hide()
      }
      // Reset editing state
      this.isEditing = false
      this.editedTemplate = {
        subject: '',
        body: '',
        days: 0,
        hours: 0,
        inMinutesCount: 0
      }
    },

    formatWaitTime(minutes) {
      if (!minutes) return 'Send immediately'
      
      const days = Math.floor(minutes / 1440)
      const hours = Math.floor((minutes % 1440) / 60)
      
      let parts = []
      if (days > 0) parts.push(`${days} day${days !== 1 ? 's' : ''}`)
      if (hours > 0) parts.push(`${hours} hour${hours !== 1 ? 's' : ''}`)
      
      return parts.length ? `Wait ${parts.join(' and ')}` : 'Send immediately'
    },

    startEditing() {
      const minutes = this.selectedStep?.inMinutesCount || 0
      this.editedTemplate = {
        subject: this.selectedTemplate.subject,
        body: this.selectedTemplate.body,
        days: Math.floor(minutes / 1440),
        hours: Math.floor((minutes % 1440) / 60),
        inMinutesCount: minutes
      }
      this.isEditing = true
    },

    cancelEditing() {
      this.isEditing = false
      this.editedTemplate = {
        subject: '',
        body: '',
        days: 0,
        hours: 0,
        inMinutesCount: 0
      }
    },

    updateMinutesFromDays() {
      const days = this.editedTemplate.days || 0
      const hours = this.editedTemplate.hours || 0
      this.editedTemplate.inMinutesCount = (days * 1440) + (hours * 60)
    },

    updateMinutesFromHours() {
      const days = this.editedTemplate.days || 0
      const hours = this.editedTemplate.hours || 0
      this.editedTemplate.inMinutesCount = (days * 1440) + (hours * 60)
    },

    updateTemplate() {
      this.isSubmitting = true
      
      const payload = {
        id: this.selectedStep.id,
        number: this.selectedStep.number,
        displayName: this.selectedStep.displayName,
        inMinutesCount: this.editedTemplate.inMinutesCount,
        templates: [{
          id: this.selectedTemplate.id,
          emailTemplateId: this.selectedTemplate.emailTemplateId,
          emailTemplateName: this.selectedTemplate.emailTemplateName,
          subject: this.editedTemplate.subject,
          body: this.editedTemplate.body,
          ccList: this.selectedTemplate.ccList,
          attachmentsIds: this.selectedTemplate.attachmentsIds || []
        }]
      }

      this.$store.dispatch('UPDATE_CAMPAIGN_STEP', {
        startupId: this.startup.id,
        searchId: this.current.id,
        stepId: this.selectedStep.id,
        payload
      })
        .then(() => {
          this.isEditing = false
          this.closeTemplateModal()
          Swal.fire({
            title: 'Success!',
            text: 'Template and wait time updated successfully',
            icon: 'success',
            confirmButtonColor: '#3085d6'
          })
        })
        .catch((error) => {
          Swal.fire({
            title: 'Error',
            text: error.response?.data?.error || 'Failed to update template',
            icon: 'error',
            confirmButtonColor: '#d33'
          })
        })
        .finally(() => {
          this.isSubmitting = false
        })
    },

    viewCampaignStats(campaign) {
      this.$router.push({
        name: 'replyio_campaign_stats',
        params: { 
          campaignId: campaign.id,
        }
      })
    },

    // Check for missing email templates and prompt user to create them
    checkMissingEmailTemplates() {
      // Wait for startup data to be loaded
      setTimeout(() => {
        if (this.startup && !this.startup.generated_email_intro && !this.startup.generated_follow_up_email) {
          Swal.fire({
            title: "Missing Email Templates",
            text: "You haven't generated initial and follow-up email templates yet. Would you like to create them now?",
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Yes, create templates",
            cancelButtonColor: "#d33",
            cancelButtonText: "Not now"
          }).then((result) => {
            if (result.isConfirmed) {
              // Navigate to email intro page
              this.goEmailIntro()
            }
          })
        }
      }, 1000) // Give a short delay to ensure startup data is loaded
    },

  }

}
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}

.cursor-pointer:hover {
  background-color: #f8f9fa;
}

.modal-dialog {
  max-width: 800px;
}

pre {
  font-family: inherit;
}

.input-group {
  max-width: 150px;
}

.btn-outline-info {
  color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}
</style>
