import { mapState } from 'vuex'
import Swal from 'sweetalert2'
import { resolve } from '@kangc/v-md-editor'

export default {
  name: 'checkKeysMixin',
  computed: {
    ...mapState({
      keys: state => state.auth.keys,
      siteCompany: state => state.auth.siteCompany,
    }),
  },

  methods: {
    updateFreeLimit(freeLimits) {
      this.$store.dispatch('UPDATE_FREE_LIMIT_SITE_COMPANY', {
        id: this.siteCompany.id,
        freeLimits: freeLimits
      })
    },

    checkFreeLimits(task) {
      return this.$store.dispatch("LOAD_SITE_COMPANY")
        .then((response) => {
          const siteCompany = response.data;
          const freeLimits = siteCompany.free_limits;
          
          // Check if task property exists and has remaining usage
          if (freeLimits.hasOwnProperty(task) && freeLimits[task] > 0) {
            return freeLimits[task];
          }
          return null;
        });
    },

    checkKeys() {
      this.$store.dispatch('LOAD_KEYS').then(() => {
        if (!this.keys?.llm_vendor || !this.keys.keys[this.keys.llm_vendor]) {
          let timerInterval
          Swal.fire({
            title: 'AI Configuration Required',
            html: 'This feature requires AI configuration. You will be redirected to add your API keys in <b id="timer">5</b> seconds.',
            icon: 'info',
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            didOpen: () => {
              const timerElement = Swal.getHtmlContainer().querySelector('#timer')
              timerInterval = setInterval(() => {
                timerElement.textContent = Math.ceil(Swal.getTimerLeft() / 1000)
              }, 1000)
            },
            willClose: () => {
              clearInterval(timerInterval)
            }
          }).then(async () => {
            await this.$router.push({ name: 'configure_keys' })
            return true
          })
        } else {
          return false
        }
      })
    }
  }
}