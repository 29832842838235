<template>
  <div class="container">
    <div class="card-body pt-0">
      <p>Share your company's story and vision through your pitch deck. Please ensure your pitch deck is up-to-date and includes key metrics along with the LinkedIn profiles of the team members.</p>
      
      <div class="alert alert-info">
        <i class="fas fa-robot me-2"></i>
        Our AI-powered platform will analyze your pitch deck and provide:
        <ul class="mb-0 mt-2">
          <li>Comprehensive Investment Recommendation</li>
          <li>Detailed Risk and Opportunity Analysis</li>
          <li>Market Need and Size Assessment</li>
          <li>Innovation Analysis using Clayton Christensen's Framework</li>
          <li>Team Profile and Background Verification</li>
          <li>Defensibility and Competitive Advantage Analysis</li>
          <li>Scalability and Growth Potential Evaluation</li>
          <li>Traction and Revenue Analysis</li>
          <li>Overall Investment Confidence Score</li>
        </ul>
      </div>

      <div class="alert alert-success">
        <i class="fas fa-chart-line me-2"></i>
        You'll receive a detailed 10-section report with:
        <ul class="mb-0 mt-2">
          <li>Granular scoring across multiple metrics</li>
          <li>Evidence-based evaluation of $100M revenue potential</li>
          <li>Specific insights on scalability challenges</li>
          <li>Actionable recommendations for improvement</li>
        </ul>
      </div>

      <div>
        <div class="row mb-3">
          <div class="mt-3">
            <h4>Pitchdeck</h4>
          </div>
          <div class="col-md-4">
            <label for="pitchDeckName" class="form-label">Pitch Deck Name</label>
            <input
              type="text"
              class="form-control"
              id="pitchDeckName"
              v-model="name"
              placeholder="Enter a name"
              required
            />
            <small class="text-muted">Enter a name for your pitch deck</small>
          </div>
          <div class="col-md-8">
            <label for="pitchDeck" class="form-label">Pitch Deck File</label>
            <input
              type="file"
              class="form-control"
              id="pitchDeck"
              @change="fileChange"
              accept=".pdf"
              required
            />
            <small class="text-muted">Upload your pitch deck (PDF)</small>
          </div>
        </div>
        <div class="mt-3 mb-2">
          <h4>Dealroom Files</h4>
          <span class="text-muted">Upload your Cap Table, Financials, Business Plan, Team CVs or any other relevant files.</span>
        </div>
        <div v-for="(file, index) in dealroomFiles" :key="index" class="row pe-0">
          <div class="col-md-4">
            <input
              type="text"
              class="form-control"
              :id="`dealroomFile${index}`"
              v-model="dealroomFiles[index].name"
              placeholder="Enter a name"
              required
            />
          </div>
          <div class="col-md-8">
            <p class="p-1 border rounded">
              <i class="uil uil-file-alt"></i>
              {{ file.name }}
              <div class="float-end">
                <a href="#" class="ui uil-trash-alt me-1" @click.prevent="deleteDealroomFile(index)" title="Delete this field"></a>
              </div>
            </p>
          </div>
        </div>
        <!-- <div class="my-2">
          <a href="#" @click.prevent="addDealroomFile">+ Add Dealroom File</a>
        </div> -->
        <div class="my-2">
          <drop-zone @files-dropped="handleFilesDropped">
            <template #default="{ dropZoneActive }">
              <div class="card">
                <div class="card-body alert alert-info py-4 cursor-pointer hover-opacity" @click="$refs.dealroomFilesInput.click()">
                  <h5 class="card-title">Upload Dealroom Files</h5>
                  <p class="card-text">Drag and drop your files here or click to upload.</p>
                  <div v-if="dropZoneActive" class="fw-bold">
                    Drop files here!
                  </div>
                  <div v-else>
                    Upload your files (PDF, TXT, HTML, CSV)
                  </div>
                  <input
                    ref="dealroomFilesInput"
                    type="file"
                    class="visually-hidden"
                    @change="dealroomFilesAdded"
                    accept=".pdf,.txt,.html,.csv"
                    multiple
                  />
                </div>
              </div>
            </template>
          </drop-zone>
        </div>
        <hr>
        <div class="d-flex justify-content-center">
          <button
            v-if="!isLoading"
            class="btn btn-primary mb-2" 
            @click.prevent="submitPitchDeck"
            :disabled="!isFormValid"
          >
            <i class="fas fa-upload"></i>
            Upload and Get Report
          </button>
          <div v-else>
            <div class="d-flex align-items-center">
              <loader size="64px"></loader>
              <span class="ms-2 large">Uploading, please wait...</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Swal from 'sweetalert2'
import CheckKeysMixin from '@/mixins/checkKeys'

export default {

  name: 'DealAnalysisUpload',

  mixins: [CheckKeysMixin],

  data() {
    return {
      isLoading: false,
      name: '',
      pitchdeck: null,
      dealroomFiles: []
    }
  },

  computed: {
    ...mapState({
      keys: state => state.auth.keys,
    }),
    isFormValid() {
      return this.name.trim() !== '' && this.pitchdeck !== null
    }
  },

  methods: {
    handleFilesDropped(files) {
      // filter files to only include pdf, txt, html, csv
      const filteredFiles = files.filter(file => file.type === 'application/pdf' || file.type === 'text/plain' || file.type === 'text/html' || file.type === 'text/csv')
      this.dealroomFiles.push(...filteredFiles.map(file => ({
        name: file.name,
        file: file
      })))
    },

    fileChange(event) {
      const files = event.target.files || event.dataTransfer.files
      if (!files.length) {
        return
      }
      this.pitchdeck = files[0]
      if (!this.name){
        this.name = files[0].name
      }
    },

    dealroomFilesAdded(event) {
      const files = Array.from(event.target.files || event.dataTransfer.files || [])
      this.dealroomFiles.push(...files.map(file => ({
        name: file.name,
        file: file
      })))
    },

    dealroomFileChange(event) {
      const files = event.target.files || event.dataTransfer.files
      const index = event.target.dataset.index
      if (!files.length) {
        return
      }
      this.dealroomFiles[index].file = files[0]
      if (!this.dealroomFiles[index].name) {
        this.dealroomFiles[index].name = files[0].name
      }
    },

    addDealroomFile() {
      this.dealroomFiles.push({
        name: '',
        file: null
      })
    },

    deleteDealroomFile(index) {
      this.dealroomFiles.splice(index, 1)
    },

    triggerPitchDeck() {
      this.isLoading = true
      this.$store.dispatch(
        'ADD_DEAL_ANALYSIS',
        { name: this.name, pitchdeck: this.pitchdeck, dealroom_files: this.dealroomFiles }
      ).then(() => {
        this.$router.push({ name: 'pitch_deck_reports' })
      }).finally(() => {
        this.isLoading = false
      })
    },

    AICreditsRequiredPrompt() {
      Swal.fire({
        title: "AI Credits Required",
        html: "This feature requires AI usage. Please ensure you have sufficient funds in your AI account to proceed.",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Proceed with Analysis",
        cancelButtonText: "Cancel"
      }).then((result) => {
        if (result.isConfirmed) {
          this.triggerPitchDeck()
        }
      });
    },

    async submitPitchDeck() {
      if (!this.siteCompany.is_whitelabel) {
        Swal.fire({
          title: "Generating Pitch Deck Analysis?",
          html: "This feature requires AI usage. Please ensure you have sufficient funds in your AI account to proceed.",
          icon: "info",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Proceed with Analysis",
          cancelButtonText: "Cancel"
        }).then((result) => {
          if (result.isConfirmed) {
            this.triggerPitchDeck()
          }
        });
        return;
      }

      // whitelabel accounts need to check for keys
      let shouldConclude = await this.checkKeys()
      if (shouldConclude) {
        return
      }
      
      Swal.fire({
        title: "Generating Pitch Deck Analysis?",
        html: "This feature requires AI usage. Please ensure you have sufficient funds in your AI account to proceed.",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Proceed with Analysis",
        cancelButtonText: "Cancel"
      }).then((result) => {
        if (result.isConfirmed) {
          this.triggerPitchDeck()
        }
      });
    
    },
  }
}
</script>